import React, { useRef, useEffect, forwardRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Calendar from "./Calendar";
import BusinessGoal from "./BusinessGoal";
import BusinessLeader from "./BusinessLeader";

const ScrollSection = () => {
	const [loaded, setLoaded] = useState(false);
	const scrollSectionRef = useRef(null);
	gsap.registerPlugin(ScrollTrigger);

	useEffect(() => {
		setLoaded(true);
	}, []);


	useEffect(() => {
		if (loaded) {
			const element = scrollSectionRef.current;
			const cards = element.querySelectorAll("div");
			const textElements = element.querySelectorAll("h3");

			// Show cards
			cards.forEach((card, i) =>
				gsap.fromTo(card,
					{ opacity: 0, y: -50 },
					{
						opacity: 1, y: 0, delay: i * .2, duration: 1,
						scrollTrigger: {
							trigger: element,
							start: "top center",
							end: "bottom top",
							scrub: false,
						},
					},
				));

			// Text boxes
			textElements.forEach(textElement =>
				gsap.to(textElement,
					{
						y: -80,
						scrollTrigger: {
							trigger: element,
							start: "top center",
							end: "bottom top",
							scrub: true,
						},
					},
				),
			);
		}
	}, [loaded]);

	return (
		<section id="scroll-section" ref={scrollSectionRef}>
			<span id="scroll-section-anchor"/>
			{loaded &&
				<>
					<div>
						<Calendar element={scrollSectionRef.current}/>
						<h3>S strankami uspešno poslujemo vse od leta <span className="important-text">2018</span></h3>
					</div>
					<div>
						<BusinessGoal element={scrollSectionRef.current}/>
						<h3>Smo 100% <span className="important-text">predani</span> svojemu delu</h3>
					</div>
					<div>
						<BusinessLeader element={scrollSectionRef.current}/>
						<h3>Smo 100% <span className="important-text">neodvisni</span> že od samega začetka</h3>
					</div>
				</>
			}
		</section>
	);
};

export default ScrollSection;
