import React, { useState, useRef, useEffect } from "react";
// import "./Header.scss";
import { Link } from "react-router-dom";
import { gsap } from "gsap";

const Header = () => {
	const [scroll, setScroll] = useState(0);
	const headerRef = useRef(null);

	// Header
	useEffect(() => {
		const element = headerRef.current;
		const listElements = element.querySelectorAll("li");
		listElements.forEach((element, i) => gsap.fromTo(element,
			{ opacity: 0 },
			{ opacity: 1, delay: 0.8 - i * .2 },
		));

		gsap.fromTo(element.querySelector(".logo"),
			{ opacity: 0 },
			{ opacity: 1, delay: .3, duration: 1.5 },
		);
	}, []);

	const onScroll = () => {
		const Scrolled = document.documentElement.scrollTop;
		const MaxHeight =
			document.documentElement.scrollHeight -
			document.documentElement.clientHeight;
		const ScrollPercent = (Scrolled / MaxHeight) * 100;
		setScroll(ScrollPercent);
		console.log(ScrollPercent);
	};

	window.addEventListener("scroll", onScroll);

	return (
		<header ref={headerRef}>
			<div className="logo">
				<h3>Zavarovalno posredovanje in svetovanje</h3>
				<h5>Jožef Kranjec s.p.</h5>
			</div>
			<ul>
				<li><a href="#scroll-section-anchor">O podjetju</a></li>
				<li><a href="#jokra-cloud-section-anchor">Jokra Cloud</a></li>
				<li><a href="#contact-section-anchor">Kontakt</a></li>
				<li>
					<Link to={"/login"} className="primary-button">
						Prijava
					</Link>
				</li>
			</ul>
			<span id="progress-bar" style={{ width: `${scroll}%` }}/>
			<span id="progress-bar-placeholder"/>
		</header>
	);
};

export default Header;
