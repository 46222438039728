import React, { useEffect, useState, useRef } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useStore } from '../../contexts/StoreFilesContext';
import { useMenu } from '../../contexts/MenuContext';
import { useAuth } from '../../contexts/AuthContext';
import getFileType from '../../utilities/pureFunctions/getFileType';
import { deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';
import { collections, filesRef, firestore, storage, usersRef } from '../../firebase';
import { deleteObject, ref } from 'firebase/storage';
import getDateFromTimestamp from '../../utilities/pureFunctions/getDateFromTimestamp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCloudArrowDown, faPlus, faLocationArrow, faMinus } from '@fortawesome/free-solid-svg-icons';
import '../../style/sass/Drive/ListView/ListFile.css';
import { useNavigate } from 'react-router-dom';

const ListFile = ({ file = null, folder }) => {
	const [error, setError] = useState('');
	const [author, setAuthor] = useState('');
	const [buttonStyle, setButtonStyle] = useState({ transform: 'translateX(200px)' });

	const { storedFiles, storeFile, removeFile } = useStore();
	const { handleContextMenu, types } = useMenu();
	const { firebaseUser } = useAuth();

	const fileRef = useRef();
	const linkRef = useRef();

	const navigate = useNavigate();

	const fileType = getFileType(file.name);

	// useEffect to get files author
	const getAuthor = async () => {
		const queryData = await query(usersRef, where('uid', '==', file?.userId));
		const user = await getDocs(queryData);
		// user.forEach(user => file.author = user.data().name + user.data().lastName);
		user.forEach((user) => setAuthor(user.data().name + ' ' + user.data().lastName));
	};

	useEffect(() => {
		getAuthor().catch((err) => console.error(err));
	}, []);

	async function handleDelete(e) {
		setError('');
		try {
			// const fileRef = folder.name === "Root" ?
			//     `files/${file.userId}/${folder.path.map((x) => x.name).join("/")}/${file.name}` :
			//     `files/${file.userId}/${folder.path.map((x) => x.name).join("/")}/${folder.name}/${file.name}`;

			const fileRef =
				folder.name === 'Root'
					? `files/${firebaseUser.companyUid}/${folder.path.map((x) => x.id).join('/')}/${file.name}`
					: `files/${firebaseUser.companyUid}/${folder.path.map((x) => x.id).join('/')}/${folder.id}/${file.name}`;

			await deleteObject(ref(storage, fileRef));
			await deleteDoc(doc(firestore, collections.FILES, firebaseUser.companyUid, collections.FILES, file.id));
		} catch (err) {
			console.error('Unable to delete file: ', err);
			setError(err.message);
		}
	}

	const handleDoubleClick = (event) => {
		if (event.detail === 2) linkRef.current.click();
	};

	const handleSelectFile = () => {
		fileRef.current.classList.add('selected');
		storeFile(file);
	};

	const handleDeselectFile = () => {
		fileRef.current.classList.remove('selected');
		removeFile(file);
	};

	const handleQuickMail = () => {
		navigate('../quickMail');
	};

	// File info
	file.creationDate = getDateFromTimestamp(file.createdAt);
	file.lastChangeDate = getDateFromTimestamp(file.lastChange);

	const isSelected = () => {
		for (const storedFile of storedFiles) {
			if (storedFile.name === file.name) return true;
		}
		return false;
	};

	return (
		<div
			className='file-row px-4 py-2 w-100 d-flex justify-content-start align-items-center mb-2'
			ref={fileRef}
			onClick={handleDoubleClick}
			onMouseEnter={() => setButtonStyle({ transform: 'translateX(0)' })}
			onMouseLeave={() => setButtonStyle({ transform: 'translateX(200px)' })}
		>
			<img src={`/img/files/64/${fileType}.png`} alt='file icon' style={{ width: '32px', marginRight: '1rem' }} />
			<h5>{file.name}</h5>
			<h5>{author}</h5>
			<OverlayTrigger key={'creationDateTrigger'} placement={'top'} overlay={<Tooltip>Datum ustvaritve</Tooltip>}>
				<h5>{file.creationDate}</h5>
			</OverlayTrigger>

			<OverlayTrigger key={'lastChangeTrigger'} placement={'top'} overlay={<Tooltip>Zadnja sprememba</Tooltip>}>
				<h5>{file.lastChangeDate}</h5>
			</OverlayTrigger>
			<div className='button-container d-flex justify-content-between gap-2' style={buttonStyle}>
				<OverlayTrigger key={'downloadButton'} placement={'top'} overlay={<Tooltip id={`downloadButtonTooltip`}>Prenesi datoteko</Tooltip>}>
					<Button variant='outline-success'>
						<a href={file.url} target='_blank' style={{ color: 'inherit' }} ref={linkRef}>
							<FontAwesomeIcon icon={faCloudArrowDown} />
						</a>
					</Button>
				</OverlayTrigger>
				<OverlayTrigger key={'addButton'} placement={'top'} overlay={<Tooltip id={`addButtonTooltip`}>Dodaj v QuickMail</Tooltip>}>
					{isSelected() ? (
						<Button variant='outline-dark' onClick={handleDeselectFile}>
							<FontAwesomeIcon icon={faMinus} />
						</Button>
					) : (
						<Button variant='outline-dark' onClick={handleSelectFile}>
							<FontAwesomeIcon icon={faPlus} />
						</Button>
					)}
				</OverlayTrigger>
				<OverlayTrigger key={'sendButton'} placement={'top'} overlay={<Tooltip id={`sendButtonTooltip`}>Pošlji s QuickMailom</Tooltip>}>
					<Button variant='outline-dark' onClick={handleQuickMail} disabled={storedFiles.length === 0}>
						<FontAwesomeIcon icon={faLocationArrow} />
					</Button>
				</OverlayTrigger>
				<OverlayTrigger key={'deleteButton'} placement={'top'} overlay={<Tooltip id={`deleteButtonTooltip`}>Izbriši datoteko</Tooltip>}>
					<Button variant='outline-danger' onClick={handleDelete}>
						<FontAwesomeIcon icon={faXmark} />
					</Button>
				</OverlayTrigger>
			</div>
		</div>
	);
};

export default ListFile;
