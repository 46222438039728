import React, {useEffect, useState} from "react";
import {Outlet, Navigate} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";
import Sidebar from "../Sidebar/Sidebar";

export default function ProtectedRoute() {
    const {currentUser, firebaseUser} = useAuth();
    const [loading, setLoading] = useState(false);
    let currentUserFire;

    currentUserFire = JSON.parse(sessionStorage.getItem("currentUserFire"));
    // console.log(currentUser);
    // console.log(firebaseUser);

    /*if (!loading) */
    return currentUser ? <Outlet/> : <Navigate to="/login"/>;
    // else {
    //     currentUserFire = JSON.parse(sessionStorage.getItem("currentUserFire"));
    //     setLoading(false);
    // }
}
// Naredi backend preverjanje pogoja
