import React, { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAlignCenter,
    faAlignLeft,
    faAlignRight,
    faBold,
    faCheck,
    faClockRotateLeft,
    faEraser,
    faItalic,
    faListOl,
    faListUl,
    faPaperclip,
    faPaperPlane,
    faStrikethrough,
    faTextHeight,
    faUnderline,
} from "@fortawesome/free-solid-svg-icons";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import QuickMailPreviewModal from "./QuickMailPreviewModal";
import { useAuth } from "../../contexts/AuthContext";
import { addDoc, doc, getDocs, serverTimestamp } from "firebase/firestore";
import { collections, companiesRef, companyCollectionNew, getFirebaseDoc, quickMailRef } from "../../firebase";
import QuickMailHistory from "./QuickMailHistory";
import QuickMailAttachments from "./QuickMailAttachments";
import { useStore } from "../../contexts/StoreFilesContext";
import { useSearch } from "../../contexts/SearchFilesContext";
import Select from "react-select";
import parser from "html-react-parser";

import "../../style/sass/QuickMail/QuickMailEditor/QuickMailEditor.css";

const QuickMailEditor = () => {
    const [loading, setLoading] = useState(false);

    // Modal states
    const [show, setShow] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);

    // User feedback states
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    // Contents states
    const [content, setContent] = useState({ sender: {}, subject: "", body: "" });
    const [contactCompany, setContactCompany] = useState("");
    const [mails, setMails] = useState([]);
    const [files, setFiles] = useState([]);
    const [clerks, setClerks] = useState([]);

    // Input fields references
    const emailBody = useRef();
    const subjectRef = useRef();
    const targetEmail = useRef();

    const { currentUser, firebaseUser } = useAuth();
    const { storedFiles, removeFile } = useStore();
    const { resetSearchContext } = useSearch();

    // Get all users if admin is logged in
    const getAllMails = async () => {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ role: firebaseUser.role }),
        };

        const response = await fetch("https://us-central1-jokra-doo.cloudfunctions.net/app/admin/mails", options);
        const mailsData = await response.json();
        sessionStorage.setItem("adminMails", JSON.stringify(mailsData));
        return setMails(mailsData);
    };

    // If admin is signed in, fetch all users emails
    useEffect(() => {
        if (firebaseUser.role === "admin") getAllMails();
    }, []);

    // Get currentUser company's name
    const getCompanies = async () => {
        const companyRef = await getFirebaseDoc(collections.COMPANIES, firebaseUser.companyUid);
        if (companyRef) {
            setContactCompany(companyRef.name);
            setClerks([...companyRef.clerks]);
        }
    };

    // Get user company
    useEffect(() => {
        if (firebaseUser) {
            getCompanies().catch((err) => {
                console.error(err);
            });
        }
    }, []);

    // Set up email preview content
    const handlePreview = () => {
        content.sender = {
            email: currentUser.email,
            name: firebaseUser.name + " " + firebaseUser.lastName,
            company: contactCompany,
        };
        content.subject = subjectRef.current.value;
        content.body = emailBody.current.value;

        setContent({ ...content });
        setShow(true);
    };

    // Modify email text
    const handleTextStyle = (event) => {
        event.stopPropagation();

        let textAnchors = {
            start: emailBody.current.selectionStart,
            end: emailBody.current.selectionEnd,
        };

        const tag = event.currentTarget.dataset.tags;

        if (tag) {
            const text = emailBody.current.value.substring(textAnchors.start, textAnchors.end);
            const modifiedText = `<${tag}>${text}</${tag}>`;

            const prefix = emailBody.current.value.substring(0, textAnchors.start);
            const suffix = emailBody.current.value.substring(textAnchors.end);
            emailBody.current.value = prefix + modifiedText + suffix;

            emailBody.current.textContent = parser(emailBody.current.value);
        }
    };

    // Send mail function
    const sendMail = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!targetEmail.current.value) {
            setError("Naslovnik je prazen");
            return setLoading(false);
        }

        if (!subjectRef.current.value) {
            setError("Zadeva je prazna");
            return setLoading(false);
        }

        if (!emailBody.current.value) {
            setError("Besedilo je prazno");
            return setLoading(false);
        }

        // const emailBodyObject = {
        //     email: currentUser.email,
        //     name: firebaseUser.name + " " + firebaseUser.lastName,
        //     company: contactCompany,
        //     subject: subjectRef.current.value,
        //     text: emailBody.current.value,
        //     target: targetEmail.current.value,
        // }

        const options = {
            crossDomain: true,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: currentUser.email,
                name: firebaseUser.name + " " + firebaseUser.lastName,
                company: contactCompany,
                subject: subjectRef.current.value,
                text: emailBody.current.value,
                target: targetEmail.current.value,
                type: "message",
            }),
        };

        try {
            // Testing URL
            // const response = await fetch("http://localhost:5001/jokra-doo/us-central1/app/quickMail", options);

            const response = await fetch(" https://us-central1-jokra-doo.cloudfunctions.net/app/quickMail", options);
            if (response.ok) {
                setSuccess("Email poslan");

                // save to Firestore
                const newQuickMailDoc = await addDoc(companyCollectionNew(collections.QUICK_MAIL, currentUser.uid), {
                    uid: currentUser.uid,
                    sender: currentUser.email,
                    name: firebaseUser.name + " " + firebaseUser.lastName,
                    company: contactCompany,
                    subject: subjectRef.current.value,
                    text: emailBody.current.value,
                    target: targetEmail.current.value,
                    timestamp: serverTimestamp(),
                });
            }
        } catch (err) {
            console.error(err);
            setError(err.message);
            setLoading(false);
        }

        setLoading(false);
    };

    // Clear function
    const handleClear = () => {
        subjectRef.current.value = "";
        emailBody.current.value = "";
    };

    // Create list in email body
    const handleList = (event) => {
        event.stopPropagation();
        // console.log(event);

        let textAnchors = {
            start: emailBody.current.selectionStart,
            end: emailBody.current.selectionEnd,
        };

        const listType = event.currentTarget.dataset.listType;
        console.log(listType);
        emailBody.current.value += `<${listType}><br/></${listType}>`;
    };

    const handleKeyDown = (event) => {
        console.log(emailBody.current.value);

        let textAnchors = {
            start: emailBody.current.selectionStart,
            end: emailBody.current.selectionEnd,
        };

        const prefix = emailBody.current.value.substring(0, textAnchors.start);
        const suffix = emailBody.current.value.substring(textAnchors.end);

        if (event.key === "Enter") {
            emailBody.current.value = prefix + "<br/>" + suffix;
        }
    };

    const execCommand = (e) => {
    };

    // Change attachments on files state changes
    useEffect(() => {
        if (storedFiles.length > 0) {
            setFiles((prevState) => [...prevState, ...storedFiles]);
            storedFiles.forEach((storedFile) => removeFile(storedFile));
        }

        // Add files to email body
        if (files.length > 0) {
            emailBody.current.value += "<br/>";
            emailBody.current.value += "<h5>Priloge:</h5>";
            emailBody.current.value += "<ul>";
            files.map((file, index) => {
                emailBody.current.value += `<li><a href="${file.url}">${file.name}</a></li>`;
            });
            emailBody.current.value += "</ul>";
        }

        // If body is not empty (search for <ul> tag with attachments class or id) and replace it
    }, [files]);

    // Clear success message after 5 sec
    useEffect(() => {
        if (success.length > 0)
            setTimeout(() => {
                setSuccess("");
            }, 5000);
    }, [success]);

    return (
        <>
            <main className="d-flex flex-column p-4" id="quick-mail-editor">
                {/*<header className="w-100 d-flex align-items-center my-4 gap-3">*/}
                <header className="w-100 d-flex justify-content-between align-items-center my-4">
                    <div>
                        <h6 className="text-black-50">Zgodovina</h6>
                        <Button variant="success" onClick={() => setShowHistory(true)}>
                            Zgodovina
                            <FontAwesomeIcon icon={faClockRotateLeft} className="ms-2"/>
                        </Button>
                    </div>
                    <div>
                        <h6 className="text-black-50">Stil besedila</h6>
                        <Button variant="outline-dark" data-tags="strong" onClick={handleTextStyle}>
                            <FontAwesomeIcon icon={faBold}/>
                        </Button>
                        <Button variant="outline-dark" data-tags="i" onClick={handleTextStyle}>
                            <FontAwesomeIcon icon={faItalic}/>
                        </Button>
                        <Button variant="outline-dark" data-tags="u" onClick={handleTextStyle}>
                            <FontAwesomeIcon icon={faUnderline}/>
                        </Button>
                        <Button variant="outline-dark" data-tags="strike" onClick={handleTextStyle}>
                            <FontAwesomeIcon icon={faStrikethrough}/>
                        </Button>
                    </div>
                    <div>
                        <h6 className="text-black-50">Priponke</h6>
                        <Button variant="outline-dark" onClick={() => setShowAttachmentsModal(true)}>
                            <FontAwesomeIcon icon={faPaperclip}/>
                        </Button>
                    </div>
                    <div style={{ cursor: "not-allowed" }}>
                        <h6 className="text-black-50">Velikost</h6>
                        <Button variant="outline-dark" disabled>
                            <FontAwesomeIcon icon={faTextHeight}/>
                        </Button>
                    </div>
                    <div style={{ cursor: "not-allowed" }}>
                        <h6 className="text-black-50">Poravnava</h6>
                        <Button variant="outline-dark" disabled>
                            <FontAwesomeIcon icon={faAlignLeft}/>
                        </Button>
                        <Button variant="outline-dark" disabled>
                            <FontAwesomeIcon icon={faAlignCenter}/>
                        </Button>
                        <Button variant="outline-dark" disabled>
                            <FontAwesomeIcon icon={faAlignRight}/>
                        </Button>
                    </div>
                    <div style={{ cursor: "not-allowed" }}>
                        <h6 className="text-black-50">Seznami</h6>
                        <Button variant="outline-dark" data-list-type="ul" onClick={handleList} disabled>
                            <FontAwesomeIcon icon={faListUl}/>
                        </Button>
                        <Button variant="outline-dark" data-list-type="ol" onClick={handleList} disabled>
                            <FontAwesomeIcon icon={faListOl}/>
                        </Button>
                    </div>
                    <div>
                        <h6 className="text-black-50">Počisti</h6>
                        <Button variant="danger" onClick={handleClear}>
                            Počisti
                            <FontAwesomeIcon icon={faEraser} className="ms-2"/>
                        </Button>
                    </div>
                    <div>
                        <h6 className="text-black-50">Zadnji korak</h6>
                        <Button variant="warning" onClick={handlePreview}>
                            Predogled
                            <FontAwesomeIcon icon={faCheck} className="ms-2"/>
                        </Button>
                        <Button variant="primary" onClick={sendMail} disabled={loading}>
                            Pošlji
                            {loading ? (
                                <Spinner animation="border" variant="light" size="sm" className="ms-2"/>
                            ) : (
                                <FontAwesomeIcon icon={faPaperPlane} className="ms-2"/>
                            )}
                        </Button>
                    </div>
                </header>
                {error && (
                    <Alert key={"errorAlert"} variant={"danger"} dismissible onClose={() => setError("")}>
                        {error}
                    </Alert>
                )}
                {success && (
                    <Alert key={"successAlert"} variant={"success"} dismissible onClose={() => setSuccess("")}>
                        {success}
                    </Alert>
                )}
                <div id="editor-form-container">
                    <Form className="d-flex flex-column justify-content-start gap-1">
                        <Form.Group>
                            <Form.Label>Ciljni email</Form.Label>
                            <Form.Select id="target-email" ref={targetEmail} style={{ borderRadius: "20px" }}>
                                <option value="default">Jožef Kranjec ( jkranjec@t-2.net )</option>
                                <option/>
                                {firebaseUser.role === "admin" &&
                                    mails.map((user, index) => {
                                        return (
                                            <option key={index} value={user.email}>
                                                {user.email}
                                            </option>
                                        );
                                    })}
                                <option/>
                                {clerks.length > 0 &&
                                    clerks.map((clerk, index) => {
                                        return (
                                            <option key={index} value={clerk.email}>
                                                {clerk.name + " " + clerk.lastName}&nbsp;({clerk.email})
                                            </option>
                                        );
                                    })}
                                <option/>
                                <option value="technicalSupport">Tehnična podpora</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Zadeva</Form.Label>
                            <Form.Control type="text" ref={subjectRef}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Besedilo</Form.Label>
                            <Form.Control as="textarea" ref={emailBody} onKeyPress={handleKeyDown} className="p-4"
                                          id="content-container"/>
                            {/* <div ref={emailBody} onKeyPress={handleKeyDown} className='p-4' contentEditable='true' id='content-container' /> */}
                        </Form.Group>
                    </Form>
                </div>
            </main>

            {show && <QuickMailPreviewModal show={show} hide={() => setShow(false)} content={content}/>}
            {showHistory &&
                <QuickMailHistory show={showHistory} hide={() => setShowHistory(false)} userUid={currentUser.uid}/>}
            {showAttachmentsModal && (
                <QuickMailAttachments show={showAttachmentsModal} hide={() => setShowAttachmentsModal(false)}
                                      setAttachments={setFiles}/>
            )}
        </>
    );
};

export default QuickMailEditor;
