import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import HelpData from './HelpData.json';
import HelpDataTech from './HelpDataTech.json';
import { Accordion, Tabs, Tab } from 'react-bootstrap';
import '../../style/sass/Help/Help.css';

export default function Help() {
	return (
		<>
			{/*<Sidebar/>*/}
			<main id='help-container' className='w-100 d-flex flex-column justify-content-center align-items-center'>
				<Accordion flush className='pb-5'>
					<h1 className='text-center pt-4 fw-bold'>Pomoč</h1>
					<h5 className='text-center pb-2 text-muted'>V spodnjih razdelkih najdete vse potrebno za uporabo aplikacije</h5>
					{/*{HelpData.map((data, key) => {*/}
					{/*    return (*/}
					{/*        <Accordion.Item key={key} eventKey={key}>*/}
					{/*            <Accordion.Header>*/}
					{/*                <h4 className="fw-bold">{(key + 1) + ". " + data.title}</h4>*/}
					{/*            </Accordion.Header>*/}
					{/*            <Accordion.Body>*/}
					{/*                <h5>{data.text}</h5>*/}
					{/*            </Accordion.Body>*/}
					{/*        </Accordion.Item>*/}
					{/*    );*/}
					{/*})}*/}

					<Tabs defaultActiveKey='working-with-app' className='mb-2'>
						<Tab eventKey='working-with-app' title='Splošno' className='help-tab'>
							{HelpDataTech.map((group, key) => {
								return (
									<Accordion.Item key={key} eventKey={key}>
										<Accordion.Header className='px-2'>
											<h4 className='fw-bold'>{key + 1 + '. ' + group.group}</h4>
										</Accordion.Header>
										{/*<Accordion.Body className="p-0">*/}
										<Accordion.Body>
											<Accordion flush id='sub-accordion'>
												{group.content.map((content, contentKey) => {
													return (
														<Accordion.Item key={contentKey} eventKey={contentKey}>
															<Accordion.Header>
																<h5 className='fw-bold'>{key + 1 + '.' + (contentKey + 1) + '. ' + content.title}</h5>
															</Accordion.Header>
															<Accordion.Body>
																<h5>{content.text}</h5>
															</Accordion.Body>
														</Accordion.Item>
													);
												})}
											</Accordion>
										</Accordion.Body>
									</Accordion.Item>
								);
							})}
						</Tab>
						<Tab eventKey='policy' title='Dogovori'></Tab>
					</Tabs>
				</Accordion>
			</main>
		</>
	);
}
