import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../style/sass/NotFound/NotFound.css";

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div id="not-found-container">
            <div>404</div>
            <div>Ups... Na tem naslovu ni ničesar</div>
            <Button onClick={() => navigate(-1)}>Pojdi nazaj</Button>
        </div>
    );
};

export default NotFound;
