import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import CenteredContainer from "./CenteredContainer";
import Sidebar from "../Sidebar/Sidebar";
import "../../style/sass/Authentication/UpdateProfile/UpdateProfile.css";

export default function UpdateProfile() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();

    const { currentUser, updateUserEmail, updateUserPassword } = useAuth();

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Gesli se ne ujemata");
        }

        const promises = [];
        setError("");
        setSuccess("");
        setLoading(true);

        if (emailRef.current.value !== currentUser.email) {
            promises.push(updateUserEmail(emailRef.current.value));
        }

        if (passwordRef.current.value) {
            promises.push(updateUserPassword(passwordRef.current.value));
        }

        Promise.all(promises)
            .then(() => {
                navigate("/user");
                setSuccess("Uporabnik uspešno posodobljen.");
            })
            .catch((err) => {
                console.error(err);
                setError("Napaka pri posodabljanju uporabnika.");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    // Handle email change
    const handleEmailChange = (event) => {
        event.preventDefault();

        setError("");
        setSuccess("");
        setLoading(true);

        if (emailRef.current.value === currentUser.email) {
            setError("Vnesli ste trenutni e-poštni naslov.");
            return setLoading(false);
        }

        updateUserEmail(emailRef.current.value)
            .then(() => setSuccess("E-poštni naslov posodbljen."))
            .catch((err) => {
                console.error(err);
                setError("Napaka pri posodabljanju e-poštnega naslova.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Handle password change
    const handlePasswordChange = (event) => {
        event.preventDefault();

        setError("");
        setSuccess("");
        setLoading(true);

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Gesli se ne ujemata.");
        }

        if (passwordRef.current.value) {
            updateUserPassword(passwordRef.current.value)
                .then(() => setSuccess("Geslo posodobljeno."))
                .catch((err) => {
                    console.error(err);
                    setError("Napaka pri posodabljanju gesla.");
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <>
            {/*<Sidebar/>*/}
            <CenteredContainer>
                <Card className="py-4 px-2" id="container">
                    <Card.Body>
                        <h2 className="text-center mb-4">Posodobitev profila</h2>
                        {error && <Alert variant="danger" dismissible onClose={() => setError("")}
                                         style={{ borderRadius: "20px" }}>{error}</Alert>}
                        {success &&
                            <Alert variant="success" dismissible onClose={() => setSuccess("")}
                                   style={{ borderRadius: "20px" }}>{success}</Alert>}
                        <Form onSubmit={handleEmailChange} className="mb-4">
                            <Form.Group id="email" className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Alert variant="warning" style={{ borderRadius: "20px" }} className="mt-3">
                                    Pred potrditvijo preverite vnešeni e-poštni naslov.
                                    <hr/>
                                    V kolikor e-poštnega nalsova ne želite spremeniti, spodnjega polja ne
                                    spreminjajte.
                                </Alert>
                                <Form.Control type="email" ref={emailRef} defaultValue={currentUser.email}/>
                            </Form.Group>
                            <Button disabled={loading} className="w-100 my-2" type="submit">
                                Posodobi e-poštni naslov
                            </Button>
                        </Form>
                        <Form onSubmit={handlePasswordChange}>
                            <Form.Group id="password" className="mb-3">
                                <Form.Label>Geslo</Form.Label>
                                <Form.Control type="password" ref={passwordRef}
                                              placeholder="Če ne želite sprememb, pustite prazno"/>
                            </Form.Group>
                            <Form.Group id="passwordConfirm" className="mb-3">
                                <Form.Label>Potrditev gesla</Form.Label>
                                <Form.Control type="password" ref={passwordConfirmRef}
                                              placeholder="Če ne želite sprememb, pustite prazno"/>
                            </Form.Group>
                            <Button disabled={loading} className="w-100 mt-4" type="submit">
                                Posodobi geslo
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </CenteredContainer>
        </>
    );
}
