import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./components/App";
import Login from "./components/Authentication/Login";
import ForgotPassword from "./components/Authentication/ForgotPassword";
import UpdateProfile from "./components/Authentication/UpdateProfile";
import ProtectedRoute from "./components/Authentication/ProtectedRoute";
import ProtectedRouteAdmin from "./components/AdminDashboard/ProtectedRouteAdmin";
import Dashboard, { MemoizedDashboard } from "./components/Drive/Dashboard";
import Help from "./components/Help/Help";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import LandingPage from "./pages/LandingPage";
// import LandingPage from "./components/LandingPage/LandingPage";
import Loader from "./components/Loader";
import QuickMail from "./components/QuickMail/QuickMail";
import NotFound from "./components/NotFound/NotFound";
import ForbiddenAccess from "./components/AdminDashboard/ForbiddenAccess";
import StoreFilesOutlet from "./components/Outlets/StoreFilesOutlet";
import SidebarOutlet from "./components/Outlets/SidebarOutlet";
import SearchFilesOutlet from "./components/Outlets/SearchFilesOutlet";
import CompanySelect from "./pages/CompanySelect";

import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Routes>
				<Route path="/" element={<App/>}>
					<Route index element={<LandingPage/>}/>
					<Route element={<SidebarOutlet/>}>
						<Route element={<SearchFilesOutlet/>}>
							<Route element={<ProtectedRoute/>}>
								<Route element={<StoreFilesOutlet/>}>
									<Route path="folder" element={<MemoizedDashboard/>}>
										<Route path=":folderId" element={<Dashboard/>}/>
									</Route>
									<Route path="quickMail" element={<QuickMail/>}/>
								</Route>
								<Route path="help" element={<Help/>}/>
								<Route path="user" element={<UpdateProfile/>}/>
							</Route>
							<Route element={<ProtectedRouteAdmin/>}>
								<Route path="company" element={<CompanySelect/>}/>
								<Route path="admin-dashboard" element={<AdminDashboard/>}/>
							</Route>
						</Route>
					</Route>
					<Route path="login" element={<Login/>}/>
					<Route path="forgot-password" element={<ForgotPassword/>}/>
					<Route path="*" element={<NotFound/>}/>
					<Route path="loader" element={<Loader/>}/>
					<Route path="forbidden" element={<ForbiddenAccess/>}/>
					<Route path="unauthenticated" element={<ForbiddenAccess/>}/>
				</Route>
			</Routes>
		</Router>
	</React.StrictMode>,
	document.getElementById("root"),
);
