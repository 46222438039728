import React, {useEffect, useState} from "react";
import {Outlet, Navigate} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";
import {getDocs, query, where} from "firebase/firestore";
import {usersRef} from "../../firebase";
import ForbiddenAccess from "./ForbiddenAccess";
import Sidebar from "../Sidebar/Sidebar";
import Loader from "../Loader";

export default function ProtectedRouteAdmin() {
    const [loading, setLoading] = useState(/*true*/ false);
    const {currentUser, firebaseUser} = useAuth();

    const fetchPermission = async () => {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify(firebaseUser),
        };

        const response = await fetch("https://localhost:3001/admin/", options);
    };

    useEffect(() => {
        // fetchPermission();
    }, []);

    // useEffect(async () => {
    //     // dodaj samostojno funkcijo namesto async useEffect-a
    //     setLoading(true);
    //     const querySnapshot = query(usersRef, where("uid", "==", currentUser.uid));
    //     const queryDocs = await getDocs(querySnapshot);
    //     queryDocs.forEach((user) => {
    //         // setCurrentUserRole(user.data().role);
    //     });
    //     setLoading(false);
    // }, [currentUser]);

    // Check if user is signed in
    if (!currentUser) return <ForbiddenAccess/>; // Change to <BadAccess />

    // return <Loader/>;

    // Check if user is admin
    if (!loading) return firebaseUser.role === "admin" ? <Outlet/> : <ForbiddenAccess/>;
    else return (
        <>
            <Sidebar/>
            <Loader/>
        </>
    );
}