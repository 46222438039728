import React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../contexts/StoreFilesContext";
import { useMenu } from "../../contexts/MenuContext";
import FileMenu from "./FileMenu";
import FolderMenu from "./FolderMenu";

import "../../style/sass/Drive/ContextMenu/ContextMenu.css";

const ContextMenu = ({ file }) => {
    const navigate = useNavigate();

    const { show, animate, anchorPoint, handleExit, type, types, element } = useMenu();

    return (
        show ?
            <div style={{ top: anchorPoint.y, left: anchorPoint.x }} onMouseLeave={handleExit} id="context-menu"
                 className={animate ? "exit-animation" : null}>
                <ul className="p-1 m-0">
                    <p className="text-black-50 mb-2">Navigacija</p>
                    <li onClick={() => navigate(-1)}>Nazaj</li>
                    <li onClick={() => navigate(1)}>Naprej</li>
                    <li onClick={() => window.location.reload()}>Ponovno naloži</li>
                    <hr/>
                    {type === types.FOLDER ?
                        <FolderMenu folder={element}/> :
                        <FileMenu file={element}/>
                    }
                </ul>
            </div> :
            <></>
    );
};

export default ContextMenu;
