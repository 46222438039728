import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import carousel from "bootstrap/js/src/carousel";

const JokraCloudSection = () => {
	const [loaded, setLoaded] = useState(false);
	const carouselItems = useRef([]);
	const carouselDescriptionItems = useRef([]);
	const jokraCloudRef = useRef(null);

	useEffect(() => {
		setLoaded(true);
	}, []);

	useEffect(() => {
		if (loaded) {
			const element = jokraCloudRef.current;
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: element,
					pin: true,
					pinSpacing: false,
					start: "top center",
					end: "+=55%",
					scrub: 1,
					// markers: true,
				},
				defaults: { duration: 1, ease: "none" },
			});

			tl.to(element.querySelector("div.background-container"),
				{ width: "100%", height: "100%", top: "-40%", left: 0, borderRadius: 0 },
				"+=2",
			);

			gsap.fromTo(element.querySelectorAll("div > div"),
				{ opacity: 0 },
				{
					opacity: 1,
					scrollTrigger: {
						trigger: element.querySelectorAll("div > div"),
						start: "top center",
						// markers: true,
					},
				},
			);

			carouselItems.current = jokraCloudRef.current.querySelectorAll(".carousel div");
			carouselDescriptionItems.current = jokraCloudRef.current.querySelectorAll(".carousel-info-wrapper > div");
		}
	}, [loaded]);

	function navigateCarousel(e) {
		const carouselItemsArray = Array.from(carouselItems.current);
		const carouselDescriptionItemsArray = Array.from(carouselDescriptionItems.current);

		if (e.target.getAttribute("data-direction") === "next") {
			carouselItemsArray.unshift(carouselItemsArray.pop());
			carouselDescriptionItemsArray.unshift(carouselDescriptionItemsArray.pop());
		} else {
			carouselItemsArray.push(carouselItemsArray.shift());
			carouselDescriptionItemsArray.push(carouselDescriptionItemsArray.shift());
		}

		carouselItemsArray.forEach(item => item.className = "");
		carouselItemsArray.slice(0, 3).map((item, i) => item.className = `carousel-item-${i + 1}`);

		carouselDescriptionItemsArray[0].style.display = "block";
		carouselDescriptionItemsArray[1].style.display = "none";
		carouselDescriptionItemsArray[2].style.display = "none";

		carouselItems.current = carouselItemsArray;
		carouselDescriptionItems.current = carouselDescriptionItemsArray;
	}

	return (
		<>
			<section id="jokra-cloud-info-section">
				<h1>Jokra Cloud</h1>
				<h3>Inovativen informacijski sistem na voljo vsem našim strankam. Podrsajte in odkrijte več</h3>
			</section>
			<section id="jokra-cloud-section" ref={jokraCloudRef}>
				<span id="jokra-cloud-section-anchor"/>
				{loaded &&
					<div className="background-container">
						<div>
							<img src="/img/assets/right-arrow.png" data-direction="prev" onClick={navigateCarousel}/>
							<div className="carousel">
								<div className="carousel-item-1"><img src="/img/assets/jokra-cloud-quick-mail.png"/></div>
								<div className="carousel-item-2"><img src="/img/assets/jokra-cloud-folder.png"/></div>
								<div className="carousel-item-3"><img src="/img/assets/jokra-cloud-help.png"/></div>
								{/*<div></div>*/}
								{/*<div></div>*/}
							</div>
							<img src="/img/assets/right-arrow.png" data-direction="next" onClick={navigateCarousel}/>
						</div>
						<div className="carousel-info-wrapper">
							<div>
								<h4>Jokra Cloud</h4>
								<p>
									Organizirano. Urejeno. Enostavno. Jokra Cloud datotečni upravitelj vam omogoča lažje upravljanje z
									datotekami, njihovim deljenjem ter pošiljanjem. Jokra Cloud poenostavlja komunikacijo in deljenje
									datotek med Vami, posrednikom in izbrano zavarovalnico ter tako olajša in preprečuje dodatne skrbi
									glede izmenjave datotek
								</p>
							</div>
							<div>
								<h4>Pomoč</h4>
								<p>
									Tudi v primeru nezgode ali nehotene reakcije, Vam je vedno na voljo naša pomoč. Zavihek za pomoč
									vsebuje vse informacije in napotke za učinkovito upravljaje z aplikacijo. V primeru zahtevnejših težav
									pa Vam je vedno na voljo naša tehnična podpora.
								</p>
							</div>
							<div>
								<h4>Integriran email</h4>
								<p>
									Komunicirajte in delite datoteke s svojimi sodelavci in zavarovalnimi referenti kar iz naše
									aplikacije. Integriran email poskrbi za hitro komunikacijo in olajša delo z deljenjem datotek, saj le
									te lahko pošljete s samo 1 klikom.
								</p>
							</div>
						</div>
					</div>
				}
			</section>
		</>
	);
};

export default JokraCloudSection;
