import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Form, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { collections, companyCollectionNew, foldersRef, usersRef } from "../../firebase";
import { addDoc, getDocs, query, serverTimestamp, where } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { ROOT_FOLDER } from "../../hooks/useFolder";

export default function AddFolderButton({ currentFolder }) {
	const [open, setOpen] = useState(false);
	// const [name, setName] = useState('');
	const [error, setError] = useState("");

	const { currentUser, firebaseUser } = useAuth();

	const name = useRef(null);
	const permissions = useRef(null);

	// const permissions = ["admin"];

	async function handleSubmit(e) {
		e.preventDefault();

		// Check for folder
		if (currentFolder == null || firebaseUser == null) return;

		// Check for other folders names on the same level
		const queryData = query(
			companyCollectionNew(collections.FOLDERS, firebaseUser.companyUid),
			where("name", "==", name),
			where("parentId", "==", currentFolder.id),
		);

		const querySnapshot = await getDocs(queryData);
		if (querySnapshot.docs.length) {
			querySnapshot.forEach((doc) => console.log(doc.data()));
			return setError("Mapa s tem imenom ze obstaja!");
		}

		// Create a folder in database
		const path = [...currentFolder.path];
		if (currentFolder !== ROOT_FOLDER) path.push({ name: currentFolder.name, id: currentFolder.id });

		const permissionArray = ["admin"];
		permissions.current.value === "moderator-only" ?
			permissionArray.push("moderator") :
			permissionArray.push("moderator", "client");

		console.log(permissionArray);

		try {
			await addDoc(companyCollectionNew(collections.FOLDERS, firebaseUser.companyUid), {
				name: name.current,
				parentId: currentFolder.id,
				userId: currentUser.uid,
				companyUid: firebaseUser.companyUid,
				path: path,
				createdAt: serverTimestamp(),
				type: "default",
				permissions: permissionArray,
			});
		} catch (err) {
			console.error(err);
		}

		// setName("");
		name.current = "";
		setOpen(false);
	}

	return (
		<>
			<Modal
				show={error.length > 0}
				onHide={() => {
					setError("");
				}}
			>
				<Modal.Header closeButton>
					<h4>Napaka pri ustvarjanju mape</h4>
				</Modal.Header>
				<Modal.Body>{error}</Modal.Body>
			</Modal>

			<Button onClick={() => setOpen(true)} variant="outline-success" size="md" className="d-flex align-items-center">
				<FontAwesomeIcon icon={faFolderPlus} className="me-2 fs-5"/>
				<p style={{ margin: "0" }}>Dodaj mapo</p>
			</Button>

			<Modal show={open} onHide={() => setOpen(false)} id="add-folder-modal">
				<Form onSubmit={handleSubmit}>
					<Modal.Body>
						<Form.Group>
							<Form.Label>Ime mape</Form.Label>
							{/*<Form.Control type="text" required value={name} onChange={(e) => setName(e.target.value)}/>*/}
							<Form.Control type="text" required value={name.current} onChange={(e) => name.current = e.target.value}/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<select ref={permissions}>
							<option value="moderator-only">Omejen dostop</option>
							<option value="all">Neomejen dostop</option>
						</select>
						<Button variant="secondary" onClick={() => setOpen(false)}>
							Zapri
						</Button>
						<Button variant="success" type="submit">
							Dodaj mapo
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
}
