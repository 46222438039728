export default function getFileType(fileName) {
    const typesArray = ["doc", "xls", "pdf", "zip", "txt", "ppt", "png", "jpg"];

    const fileParts = fileName.split(".");
    let fileFormat = fileParts[fileParts.length - 1].toLowerCase();

    // Format types longer than 3 characters
    if (fileFormat.length > 3) {
        // Check for MS Office format (MS Office after 2010)
        switch (fileFormat) {
            case "docx":
                fileFormat = "doc";
                break;
            case "xlsx":
                fileFormat = "xls";
                break;
            case "pptx":
                fileFormat = "ppt";
                break;
        }
    }

    // Rich text format
    if (fileFormat === "rtf") fileFormat = "txt";

    // Return file format
    return typesArray.includes(fileFormat) ? fileFormat : "file";
}