import React, { useEffect, useState } from "react";
import { Modal, Button, Form, ButtonGroup } from "react-bootstrap";
import { collections, companyCollectionNew, updateFirebaseDoc } from "../../firebase";

import "../../style/sass/AdminDashboard/UsersModal/UsersModal.css";

const UsersModal = ({ show, onHide, user, companies }) => {
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");
	const [userRole, setUserRole] = useState(user.role);
	const [permissions, setPermissions] = useState({ ...user.permission });

	const companyName = companies.filter((company) => {
		if (company.id === user.companyUid)
			return company;
	});
	user.companyName = companyName[0].data().name;

	const updateUser = async (e) => {
		e.preventDefault();
		setError("");
		setSuccess("");

		const updateValue = {
			name: e.target[0].value,
			lastName: e.target[1].value,
			role: userRole,
			permission: {
				...permissions,
			},
		};

		try {
			await updateFirebaseDoc("users", user.id, updateValue);
		} catch (err) {
			console.error(err);
			setError("Napaka pri posodabljanju uporabnika");
		}

		return setSuccess("Uporabnik uspešno posodobljen");
	};

	return (
		<Modal show={show} onHide={onHide} id="users-modal" centered>
			<Modal.Header closeButton><h3>{user.name + " " + user.lastName}</h3></Modal.Header>
			<Modal.Body>
				<Form onSubmit={updateUser}>
					<Form.Group>
						<Form.Label>Ime</Form.Label>
						<Form.Control type="text" defaultValue={user.name} required/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Priimek</Form.Label>
						<Form.Control type="text" defaultValue={user.lastName} required/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Podjetje</Form.Label>
						<Form.Control type="text" defaultValue={user.companyName} disabled/>
					</Form.Group>
					<span className="highlight">Tip uporabnika</span>
					<div key="userRoleRadios" className="mb-3">
						<label className="me-4 no-highlight">
							<h5 className="d-inline">Stranka</h5>
							<input type="radio" name="userRole" onChange={() => setUserRole("client")}
										 className="ms-1" checked={userRole === "client"}/>
						</label>
						<label className="me-4 no-highlight">
							<h5 className="d-inline">Moderator</h5>
							<input type="radio" name="userRole" onChange={() => setUserRole("moderator")}
										 className="ms-1" checked={userRole === "moderator"}/>
						</label>
						<label className="no-highlight">
							<h5 className="d-inline">Administator</h5>
							<input type="radio" name="userRole" onChange={() => setUserRole("admin")}
										 className="ms-1" checked={userRole === "admin"}/>
						</label>
					</div>
					<span className="highlight">Dovoljenja</span>
					<Form.Group id="users-modal-permissions" className="mb-3">
						<div className="d-inline-flex justify-content-start align-items-center">
							<h5 onClick={() => setPermissions(prev => {
								return { ...prev, canView: !prev.canView };
							})}>Branje datotek</h5>
							<input type="checkbox" name="modalCanView" id="modalCanView"
										 checked={permissions.canView}
										 onChange={(e) => setPermissions(prev => {
											 return { ...prev, canView: e.target.checked };
										 })}/>
							<Form.Label htmlFor="modalCanView"></Form.Label>
						</div>
						<div className="d-inline-flex justify-content-start align-items-center">
							<h5 onClick={() => setPermissions(prev => {
								return { ...prev, canCreate: !prev.canCreate };
							})}>Dodajanje datotek</h5>
							<input type="checkbox" name="modalCanCreate" id="modalCanCreate"
										 checked={permissions.canCreate}
										 onChange={(e) => setPermissions(prev => {
											 return { ...prev, canCreate: e.target.checked };
										 })}/>
							<Form.Label htmlFor="modalCanCreate"></Form.Label>
						</div>
						<div className="d-inline-flex justify-content-start align-items-center">
							<h5 onClick={() => setPermissions(prev => {
								return { ...prev, canDelete: !prev.canDelete };
							})}>Brisanje datotek</h5>
							<input type="checkbox" name="modalCanDelete" id="modalCanDelete"
										 checked={permissions.canDelete}
										 onChange={(e) => setPermissions(prev => {
											 return { ...prev, canDelete: e.target.checked };
										 })}/>
							<Form.Label htmlFor="modalCanDelete"></Form.Label>
						</div>
					</Form.Group>
					<Button type="submit" className="w-100">Posodobi uporabnika</Button>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" className="modal-right-bottom-button" onClick={onHide}>Zapri</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default UsersModal;
