import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import StoreFilesProvider from "../../contexts/StoreFilesContext";
import { InitDatabase, getDatabase } from "../../data/LocalDatabse";
import { useAuth } from "../../contexts/AuthContext";
import Loader from "../Loader";

const StoreFilesOutlet = () => {
    const [loading, setLoading] = useState(true);
    //
    // const { firebaseUser } = useAuth();
    //
    // const init = async () => {
    //     InitDatabase(firebaseUser)
    //         // .then(() => console.log(database))
    //         .then(() => console.log("PROBA:", getDatabase()))
    //         .then(() => setLoading(false));
    // };
    //
    // useEffect(() => {
    //     console.log("Logging from StoreFilesOutlet");
    //     // if (loading)
    //     // init();
    // }, []);

    // const proba = {};
    //
    // useEffect(() => {
    //     for (let i = 0; i < 1000000000; i++) {
    //         proba.count = i;
    //     }
    //     setLoading(false);
    //     console.log(proba);
    // }, []);


    return (
        <StoreFilesProvider>
            {/*{loading ? <Loader/> : <Outlet/>}*/}
            <Outlet/>
        </StoreFilesProvider>
    );
};

export default StoreFilesOutlet;
