export default function bubbleSortObj(array, property, direction) {
    const sortingArray = array;

    for (let i = 0; i < sortingArray.length - 1; i++) {
        for (let j = 0; j < sortingArray.length - 1 - i; j++) {
            const value1 = typeof array[j][property] === "string" ? array[j][property].toLowerCase() : array[j][property];
            const value2 = typeof array[j + 1][property] === "string" ? array[j + 1][property].toLowerCase() : array[j + 1][property];

            if (direction)
                if (value1 > value2) {
                    swap(sortingArray, j);
                    continue;
                }
            if (value1 < value2)
                swap(sortingArray, j);
        }
    }

    return sortingArray;
};

const swap = (array, i) => {
    let tmp = array[i];
    array[i] = array[i + 1];
    array[i + 1] = tmp;
};