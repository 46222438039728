import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { v4 as uuid } from "uuid";

const SidebarLink = ({ value }) => {
	const resolvedPath = useResolvedPath(value.link);
	const isActive = useMatch({ path: resolvedPath.pathname, end: false });

	return (
		<li
			key={uuid()}
			className="SidebarRow"
			// id={window.location.pathname.substr(0, value.link.length) === value.link ? "Active" : ""}
			id={isActive ? "Active" : ""}
		>
			<Link to={value.link}>
				<span key={uuid()}/>
				<span key={uuid()}/>
				{/*<div id={window.location.pathname === value.link ? "ActiveFlag" : ""}*/}
				<div id={isActive ? "ActiveFlag" : ""}
						 key={uuid()}/>
				<div className="SidebarIcon" key={uuid()}>
					{value.icon}
				</div>
				<div className="SidebarTitle" key={uuid()}>
					{value.title}
				</div>
			</Link>
		</li>
	);
};

export default SidebarLink;
