import React, { useEffect, useState, useMemo } from "react";
import Folder from "./Folder";
import FolderBreadcrumbs from "./FolderBreadcrumbs";
import File from "./File";
import { Container } from "react-bootstrap";
import { useFolder } from "../../hooks/useFolder";
import { useParams } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Toolbar from "../Drive/Toolbar";
import { SearchFilesProvider } from "../../contexts/SearchFilesContext";
import { useStore } from "../../contexts/StoreFilesContext";
import AddFileButton from "./AddFileButton";
import AddFolderButton from "./AddFolderButton";
import { useDatabase } from "../../hooks/useDatabase";

// Calendar
// import "react-calendar/dist/Calendar.css";
import MenuContext from "../../contexts/MenuContext";
import MenuProvider from "../../contexts/MenuContext";
import ContextMenu from "../ContextMenu/ContextMenu";
// import { database, graph, filesGraph } from "../../data/LocalDatabse";

import "../../style/sass/Drive/Drive.css";
import ListView from "../ListView/ListView";
import Loader from "../Loader";

export default function Dashboard() {
    const [files, setFiles] = useState([]);
    const [folders, setFolders] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [view, setView] = useState(JSON.parse(localStorage.getItem("userPreferredView")));

    const { folderId } = useParams();
    const { folder, childFolders, childFiles } = useFolder(folderId);
    // const { folder, childFolders, childFiles } = useFolder("ioansdfvn");


    // console.log("Dashboard component");
    // console.log("Folders:", graph);
    // console.log("Files:", filesGraph);
    // console.log(JSON.parse(window.sessionStorage.getItem("files")));

    useEffect(() => {
        console.log(view);
    }, [view]);


    useEffect(() => {
        setFiles([...childFiles]);
    }, [childFiles]);

    useEffect(() => {
        setFolders([...childFolders]);
    }, [childFolders]);

    return (
        <>
            <MenuProvider>
                <ContextMenu/>
                <SearchFilesProvider>
                    <div id="main" className="d-flex flex-column p-0 pt-2">
                        {/*Toolbar */}
                        <Toolbar currentFolder={folder} setFiles={setFiles} files={files} setFolders={setFolders}
                                 folders={folders} setView={setView}/>

                        {/* Main content */}
                        <Container fluid id="Container" style={{ paddingRight: 0 }}>
                            <div className="d-flex align-items-center ps-2">
                                <FolderBreadcrumbs currentFolder={folder}/>
                                {/*<AddFileButton currentFolder={folder}/>*/}
                                {/*<AddFolderButton currentFolder={folder}/>*/}
                            </div>
                            {/* Folders */}

                            {view === "grid" ?
                                childFolders.length > 0 && (
                                    <div className="d-flex flex-wrap">
                                        {/*{childFolders.map((childFolder) => (*/}
                                        {folders.map((childFolder) => (
                                            <div key={childFolder.id} style={{ maxWidth: "250px" }} className="p-2">
                                                <Folder folder={childFolder}/>
                                            </div>
                                        ))}
                                    </div>
                                ) :
                                <ListView folder={folder} childFolders={childFolders} childFiles={childFiles}/>
                            }

                            {/*{childFolders.length > 0 && (*/}
                            {/*    <div className="d-flex flex-wrap">*/}
                            {/*        /!*{childFolders.map((childFolder) => (*!/*/}
                            {/*        {folders.map((childFolder) => (*/}
                            {/*            <div key={childFolder.id} style={{ maxWidth: "250px" }} className="p-2">*/}
                            {/*                <Folder folder={childFolder}/>*/}
                            {/*            </div>*/}
                            {/*        ))}*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            {/* Horizontal line */}
                            {childFolders.length > 0 && childFiles.length > 0 && view === "grid" && <hr/>}
                            {/* Files */}
                            {childFiles.length > 0 && view === "grid" && (
                                <div className="d-flex flex-wrap">
                                    {/*{childFiles.map((childFile) => (*/}
                                    {files.map((childFile) => (
                                        <div key={childFile.id} style={{ maxWidth: "250px" }} className="p-2">
                                            <File file={childFile} folder={folder}/>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Container>
                    </div>
                </SearchFilesProvider>
            </MenuProvider>
        </>
    );
}

export const MemoizedDashboard = React.memo(Dashboard);