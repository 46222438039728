import React, { useEffect, useRef, useState } from "react";
import "../../style/sass/Authentication/Login.css";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CloudDataAnalysis } from "../../data/Illustrations/Clouddataanalysis.svg";
import { ReactComponent as LostMan }
	from "../../data/Illustrations/lost-business-man-talking-with-phone-and-asking-for-help.svg";
import { gsap } from "gsap";
import { Alert, Button, Spinner } from "react-bootstrap";

export default function Login() {
	const { login, currentUser, resetPassword } = useAuth();
	const navigate = useNavigate();

	const [message, setMessage] = useState({});
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({ email: "", password: "" });
	// const [email, setEmail] = useState("");
	// const [password, setPassword] = useState("");
	const [resetEmail, setResetEmail] = useState("");
	const [rememberMe, setRememberMe] = useState(false);

	useEffect(() => {
		const element = loginPageRef.current;
		gsap.fromTo(element.querySelector("div:first-of-type > section:first-of-type"),
			{ opacity: 0, x: "-50%" },
			{ opacity: 1, x: 0, delay: 0.3 },
		);

		gsap.fromTo(element.querySelector("div:first-of-type h1"),
			{ opacity: 0, y: -100 },
			{ opacity: 1, y: 0, delay: 0.3 },
		);

		const formElements = element.querySelectorAll("div:first-of-type form > div, form > input[type=\"submit\"]");
		formElements.forEach((formElement, i) => {
			gsap.fromTo((formElement),
				{ opacity: 0, y: -100 },
				{ opacity: 1, y: 0, delay: .3 * i + 0.3 },
			);
		});
	}, []);

	const loginPageRef = useRef(null);

	function handleChange(e) {
		setFormData(prev => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	}

	async function handleLogin(e) {
		e.preventDefault();

		try {
			setMessage({});
			setLoading(true);
			await login(formData.email, formData.password, rememberMe);

			// TODO: popravi da preveri če je novo prijavljeni user admin ali navadni user (admin -> /company)
			navigate("/folder");
		} catch (err) {
			setMessage({ type: "danger", text: "Failed to sign in" });
			console.error(err);
		}

		setLoading(false);
	}

	async function handleEmailReset(e) {
		e.preventDefault();

		try {
			setMessage({});
			setLoading(true);
			await resetPassword(resetEmail);
			setMessage({ type: "success", text: "Na vneseni email naslov smo vam poslali povezavo za zamenjavo gesla." });
		} catch (err) {
			setMessage({ type: "danger", text: "Napaka pri ponastavitvi gesla" });
			console.error(err);
		}
		setLoading(false);
	}

	function toggleForms(value) {
		loginPageRef.current.querySelector(".wrapper").style.left = value;
	}

	return (
		<main id="login-page" ref={loginPageRef}>
			<div className="wrapper">
				<div className="login-container">
					<section className="svg-section">
						<CloudDataAnalysis/>
					</section>
					<section className="form-section">
						<h1>
							Prijavite se v sistem <span className="important-text">Jokra Cloud</span>
						</h1>
						<form onSubmit={handleLogin}>
							{message?.text && <Alert variant={message.type}>{message.text}</Alert>}
							<div>
								<label>Email</label>
								<input type="email" name="email" id="email-input"
											 className={`${formData.email !== "" ? "fulfilled" : ""}`}
											 onChange={handleChange}/>
							</div>
							<div>
								<label>Geslo</label>
								<input type="password" name="password" id="password-input"
											 className={`${formData.password !== "" ? "fulfilled" : ""}`}
											 onChange={handleChange}/>
							</div>
							<div>
								<div className="switch-container">
									<input type="checkbox" name="remember-me" id="remember-me"
												 onChange={e => setRememberMe(e.target.checked)}/>
									<label htmlFor="remember-me"> Zapomni si me? </label>
								</div>
								<Button onClick={() => toggleForms("-100vw")}>Pozabljeno geslo?</Button>
							</div>
							{loading ?
								<div style={{ width: "6.4rem", height: "6.4rem" }}>
									<Spinner animation="border" variant="primary" style={{ width: "4rem", height: "4rem" }}/>
								</div> :
								<input type="submit" className="primary-button" value="Prijavi se"/>
							}
						</form>
					</section>
				</div>

				<div className="reset-password-container">
					<section className="form-section">
						<h1>
							Ponastavitve svoje <span className="important-text">geslo</span>
						</h1>
						<form onSubmit={handleEmailReset}>
							{message?.text && <Alert variant={message.type}>{message.text}</Alert>}
							<div>
								<label>Email</label>
								<input type="email" name="email-input" id="email-input"
											 className={`${resetEmail !== "" ? "fulfilled" : ""}`}
											 onChange={e => setResetEmail(e.target.value)}/>
							</div>
							<div>
								<Button onClick={() => toggleForms("0")}>Nazaj na prijavo</Button>
							</div>
							{loading ?
								<div style={{ width: "6.4rem", height: "6.4rem" }}>
									<Spinner animation="border" variant="primary" style={{ width: "4rem", height: "4rem" }}/>
								</div> :
								<input type="submit" className="primary-button" value="Pošlji zahtevo"/>
							}
						</form>
					</section>
					<section className="svg-section">
						<LostMan/>
					</section>
				</div>
			</div>


			{/*<CenteredContainer>*/}
			{/*	<Card id="form-container">*/}
			{/*		<Card.Body>*/}
			{/*			<h2 className="text-center mb-4">Prijava</h2>*/}
			{/*			{error && <Alert variant="danger">{error}</Alert>}*/}
			{/*			<Form onSubmit={handleSubmit}>*/}
			{/*				<Form.Group id="email" className="mb-3">*/}
			{/*					<Form.Label>Email</Form.Label>*/}
			{/*					<Form.Control type="email" ref={emailRef} required/>*/}
			{/*				</Form.Group>*/}
			{/*				<Form.Group id="password" className="mb-3">*/}
			{/*					<Form.Label>Geslo</Form.Label>*/}
			{/*					<Form.Control type="password" ref={passwordRef} required/>*/}
			{/*				</Form.Group>*/}
			{/*				<Button disabled={loading} className="w-100" type="submit">*/}
			{/*					Prijava*/}
			{/*				</Button>*/}
			{/*			</Form>*/}
			{/*			<div className="w-100 text-center mt-3">*/}
			{/*				<Link to="/forgot-password">Pozabljeno geslo?</Link>*/}
			{/*			</div>*/}
			{/*		</Card.Body>*/}
			{/*	</Card>*/}
			{/*</CenteredContainer>*/}
		</main>
	);
}
