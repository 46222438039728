import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { companiesRef, usersRef } from "../../firebase";
import { addDoc, arrayUnion, doc, getDocs, updateDoc } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { v4 as uuid } from "uuid";
import { Tabs, Tab } from "react-bootstrap";

import "../../style/sass/AdminDashboard/AdminDashboard.css";
import CompaniesTab from "./CompaniesTab";
import UsersTab from "./UsersTab";

export default function AdminDashboard() {
    // Error / loading states
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);

    const [companies, setCompanies] = useState([]);
    const [users, setUsers] = useState([]);
    const [key, setKey] = useState("users");

    useEffect(() => {
        const getCompanies = async () => {
            const companiesArray = [];
            try {
                const companiesSnapshot = await getDocs(companiesRef);
                companiesSnapshot.forEach((snapshot) => {
                    companiesArray.push(snapshot);
                });
                setCompanies([...companiesArray]);
            } catch (err) {
                setError(err);
                console.error(err);
            }
        };

        if (companies.length < 1) {
            getCompanies().then(() => console.log("Got companies"));
        }
    }, []);

    useEffect(() => {
        const getUsers = async () => {
            const usersArray = [];
            try {
                const usersSnapshot = await getDocs(usersRef);
                usersSnapshot.forEach((snapshot) => usersArray.push(snapshot));
                setUsers([...usersArray]);
            } catch (err) {
                setError(err);
                console.error(err);
            }
        };

        if (users.length < 1) {
            console.log("Got users");
            getUsers().then(() => console.log("Got users"));
        }
    }, []);

    return (
        <main id="admin-dashboard-container">
            <div id="admin-tabs-container">
                <Tabs activeKey={key} onSelect={(key) => setKey(key)} className="pb-4">
                    <Tab eventKey="users" title="Uporabniki">
                        <UsersTab users={users} companies={companies}/>
                    </Tab>
                    <Tab eventKey="companies" title="Podjetja">
                        <CompaniesTab companies={companies} users={users}/>
                    </Tab>
                </Tabs>
            </div>
        </main>
    );
}