import React, { useRef } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { doc, arrayRemove, arrayUnion, updateDoc } from "firebase/firestore";
import { collections, companiesRef, firestore } from "../../firebase";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import "../../style/sass/AdminDashboard/CompaniesModal/CompaniesModal.css";

const CompaniesModal = ({ show, onHide, company, users }) => {
	const clerkName = useRef();
	const clerkLastName = useRef();
	const clerkEmail = useRef();

	const companyUsers = users.filter(user => {
		if (user.data().companyUid === company.id)
			return user.data();
	});


	const addClerk = async (event) => {
		event.preventDefault();
		const newClerk = {
			id: uuid(),
			name: clerkName.current.value,
			lastName: clerkLastName.current.value,
			email: clerkEmail.current.value,
		};

		return await updateDoc(doc(firestore, collections.COMPANIES, company.id), { clerks: arrayUnion(newClerk) });
	};

	const deleteClerk = async (clerkToDelete) => {
		const docRef = doc(firestore, collections.COMPANIES, company.id);
		const clerks = company.clerks;
		return await updateDoc(docRef, {
			clerks: clerks.filter(clerk => {
				return clerk.id !== clerkToDelete.id;
			}),
		});
	};

	return (company &&
		<Modal show={show} onHide={onHide} id="companies-modal" centered>
			<Modal.Header closeButton><h3>{company.name}</h3></Modal.Header>
			<Modal.Body>
				<div className="w-100 d-flex">
					<div className="w-50">
						<Form>
							<Form.Group onSubmit={addClerk}>
								<Form.Label>Ime Referenta</Form.Label>
								<Form.Control type="text" ref={clerkName} required/>
								<Form.Label>Priimek Referenta</Form.Label>
								<Form.Control type="text" ref={clerkLastName} required/>
								<Form.Label>Email Referenta</Form.Label>
								<Form.Control type="email" ref={clerkEmail} required/>
								<Button variant="primary" type="submit">Dodaj referenta</Button>
							</Form.Group>
						</Form>
					</div>
					<div className="w-50">
						<h4>Zaposleni</h4>
						<Table striped hover>
							<thead>
							<tr>
								<th>Št.</th>
								<th>Ime</th>
								<th>Priimek</th>
							</tr>
							</thead>
							<tbody>
							{companyUsers.map((user, index) => {
								return <tr key={index}>
									<td>{index + 1}</td>
									<td>{user.data().name}</td>
									<td>{user.data().lastName}</td>
								</tr>;
							})}
							</tbody>
						</Table>
						<h4>Zavarovalni referenti</h4>
						<Table striped hover>
							<thead>
							<tr>
								<th>Št.</th>
								<th>Ime</th>
								<th>Priimek</th>
								<th>Email</th>
							</tr>
							</thead>
							<tbody>
							{company?.clerks && company.clerks.map((clerk, index) => {
								return <tr key={index} style={{ verticalAlign: "middle" }}>
									<td>{index + 1}</td>
									<td>{clerk.name}</td>
									<td>{clerk.lastName}</td>
									<td>{clerk.email}</td>
									<td>
										<Button variant="outline-danger" onClick={() => deleteClerk(clerk)}
														className="table-delete-button">
											<FontAwesomeIcon icon={faXmark}/>
										</Button>
									</td>
								</tr>;
							})}
							</tbody>
						</Table>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onHide} className="modal-right-bottom-button">Zapri</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default CompaniesModal;
