import React, { useEffect } from "react";
import { gsap } from "gsap";

const Client = ({ element }) => {

	useEffect(() => {
		// Background
		gsap.fromTo(element.querySelector("svg > path:first-of-type"),
			{ opacity: 0, y: -50 },
			{
				opacity: 1, y: 0, delay: .6,
				scrollTrigger: {
					trigger: element.querySelector("svg > path:first-of-type"),
					start: "center bottom",
				},
			},
		);

		gsap.to(element.querySelector("svg > path:first-of-type"),
			{
				y: -30,
				scrollTrigger: {
					trigger: element.querySelector("svg > path:first-of-type"),
					start: "center center",
					scrub: true,
					immediateRender: false,
				},
			},
		);

		// Left message
		gsap.fromTo(element.querySelector("svg > g:nth-of-type(2)"),
			{ opacity: 0, y: -50 },
			{
				opacity: 1, y: 0, delay: 1,
				scrollTrigger: {
					trigger: element.querySelector("svg > g:nth-of-type(2)"),
					start: "center bottom",
					immediateRender: false,
				},
			});

		gsap.to(element.querySelector("svg > g:nth-of-type(2)"),
			{
				y: -100,
				scrollTrigger: {
					trigger: element.querySelector("svg > g:nth-of-type(2)"),
					start: "center center",
					scrub: true,
					immediateRender: false,
				},
			},
		);

		// Right message
		gsap.fromTo(element.querySelector("svg > g:nth-of-type(3)"),
			{ opacity: 0, y: -50 },
			{
				opacity: 1, y: 0, delay: 1.2,
				scrollTrigger: {
					trigger: element.querySelector("svg > g:nth-of-type(3)"),
					start: "center bottom",
					immediateRender: false,
					// markers: true,
				},
			});

		gsap.to(element.querySelector("svg > g:nth-of-type(3)"),
			{
				y: -100,
				scrollTrigger: {
					trigger: element.querySelector("svg > g:nth-of-type(3)"),
					start: "center center",
					scrub: true,
					immediateRender: false,
				},
			},
		);

		// Persons
		gsap.fromTo(element.querySelector("svg > g:first-of-type"),
			{ opacity: 0, y: 50 },
			{
				opacity: 1, y: 0, delay: .9,
				scrollTrigger: {
					trigger: element.querySelector("svg > g:first-of-type"),
					start: "center bottom",
				},
			},
		);

		gsap.to(element.querySelector("svg > g:first-of-type"),
			{
				y: -150,
				scrollTrigger: {
					trigger: element.querySelector("svg > g:first-of-type"),
					start: "top center",
					scrub: true,
					immediateRender: false,
				},
			},
		);

	}, []);

	return (
		<svg width="800" height="800" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M600.378 189.844C621.733 197.082 642.963 205.911 657.904 219.778C672.844 233.644 680.326 253.578 671.363 270.259C659.393 292.541 621.467 305.496 621.289 329.637C621.163 346.926 641.741 360.119 662.519 366.407C683.289 372.689 706.289 374.956 725.185 384.141C753.696 398 767.067 424.83 772.541 450.57C778.222 477.296 775.919 507.726 751.526 527.119C721.207 551.222 671.059 548.504 628.133 543.363C570.852 536.504 513.822 528.444 456.807 520.393C331.815 502.733 206.822 485.067 81.8296 467.407C68.4296 465.511 54.4815 463.444 43.5926 457.096C21.9703 444.496 20.1333 418.481 32.7037 399.911C45.2741 381.341 68.5259 368.726 91.6222 357.8C114.719 346.881 139.104 336.556 156.407 320.43C173.704 304.304 182.311 280.437 169.222 262.082C158.133 246.533 134.304 238.222 123.919 222.385C110.23 201.519 125.978 174.867 150.815 161.548C175.652 148.23 487.548 151.593 600.378 189.844Z"
				fill="#68A3FD"/>
			<g>
				<path
					d="M723.015 437.852C751.795 437.852 775.126 414.521 775.126 385.741C775.126 356.961 751.795 333.63 723.015 333.63C694.235 333.63 670.904 356.961 670.904 385.741C670.904 414.521 694.235 437.852 723.015 437.852Z"
					stroke="#C1C1C1" stroke-miterlimit="10" stroke-dasharray="12.28 12.28"/>
				<path
					d="M649.556 421.985L640.111 423.867L638.756 417.074C637.837 412.452 633.556 409.4 629.222 410.259L582.667 419.548C578.333 420.415 575.548 424.874 576.474 429.496L577.83 436.289L568.385 438.17C567.311 438.385 566.622 439.496 566.844 440.637L576.733 490.23C576.963 491.37 578.03 492.133 579.104 491.919L660.289 475.726C661.363 475.511 662.052 474.4 661.83 473.259L651.941 423.667C651.696 422.533 650.63 421.77 649.556 421.985ZM637.859 424.319L580.067 435.844L578.711 429.052C578.052 425.748 580.037 422.563 583.133 421.948L629.689 412.659C632.785 412.044 635.844 414.222 636.504 417.526L637.859 424.319Z"
					fill="#003584"/>
				<path
					d="M618.607 454.6L608.911 456.533L567.793 443.652L568.067 443.259L609.015 456.089L618.341 454.23L651.237 426.667L651.637 426.926L618.607 454.6Z"
					fill="white"/>
				<path
					d="M396.348 344.267C396.348 344.267 387.422 341.326 385.667 342.356C383.911 343.385 374.844 355.578 374.844 355.578L386.985 361.37L400.733 352.452L396.348 344.267ZM610.6 403.696C610.6 403.696 617.874 418.437 616.348 421.067C614.815 423.704 598.348 426.667 598.348 426.667L593.941 411.881L601.8 403.689H610.6V403.696Z"
					fill="#B1CFFC"/>
				<path
					d="M484.319 640.319L445.941 652.77H492.659V640.319H484.319ZM579.437 640.319L544.385 652.77H590.711L588.333 640.319H579.437Z"
					fill="#0A2751"/>
				<path
					d="M503.867 385.163L479.644 645.993H495.267C495.267 645.993 532.407 537.726 520.015 523.4C524 505.659 549.37 435.378 543.511 427.874C537.652 420.37 526.911 381.193 526.911 381.193L503.867 385.163Z"
					fill="#082147"/>
				<path
					d="M509.644 392.193L522.237 409.533L574.163 645.993H590.659C590.659 645.993 592.03 527.37 580.207 512.393C575.482 485.593 573.244 394.43 569.756 384.437C553.993 383.652 512.607 378.793 512.607 378.793L509.644 392.193Z"
					fill="#0A2751"/>
				<path
					d="M597.681 264.444C589.637 251.807 566.326 252.97 548.518 251.296C543.363 250.815 539.059 248.644 535.4 248.148L511.156 255.452C509.763 255.748 490.281 257.178 488.052 258.519C487.785 258.83 466.681 333.082 396.2 342.963C396.941 348.148 396.2 355.556 396.2 355.556C396.2 355.556 468.785 357.659 491.6 315.141C496.607 361.711 503.867 385.163 503.867 385.163L570.533 388.096C570.533 388.096 573.97 317.044 581.378 300.748C619.896 325.193 598.415 405.933 598.415 405.933C598.415 405.933 601.378 407.415 611.007 405.933C633.237 349.63 608.052 280.741 597.681 264.444Z"
					fill="white"/>
				<path
					d="M520.015 262.407L527.778 273.489L543.511 249.541L537.793 245.926L520.015 262.407ZM520.015 262.407L517.6 273.489L509.644 256.467L512.607 251.296L520.015 262.407Z"
					fill="#F2F2F2"/>
				<path
					d="M518.393 270.659L520.015 275.674L512.156 362.378C512.156 362.378 522.193 402.333 522.867 399.889C523.541 397.444 533.378 365.615 533.378 365.615L524.422 275.8L525.207 270.607L520.022 262.4L518.393 270.659Z"
					fill="#5598FF"/>
				<path
					d="M537.822 182.459C545.97 189.126 549.674 204.681 545.97 212.83C542.267 220.978 532.637 225.422 528.933 229.867C521.526 220.978 511.156 215.052 511.156 215.052L516.341 192.83L526.711 187.644L537.822 182.459Z"
					fill="#003584"/>
				<path d="M512.637 234V253.259L520.044 264.37L537.822 247.889L530.785 213.778L512.637 234Z" fill="#B1CFFC"/>
				<path
					d="M489.889 188.881C491.963 211.926 480.941 234.244 493.348 241.267C496.119 244.755 524 248.6 527.459 238.126C530.919 227.652 539.733 197.963 532.807 188.881C525.889 179.8 489.889 188.881 489.889 188.881Z"
					fill="#CADFFF"/>
				<path
					d="M522.267 191.348C520.785 197.274 522.267 208.385 528.193 209.867C528.193 212.696 527.889 217.637 527.674 220.844C527.646 221.265 527.701 221.687 527.837 222.085C527.972 222.484 528.186 222.852 528.465 223.168C528.744 223.483 529.083 223.74 529.462 223.924C529.842 224.107 530.253 224.214 530.674 224.237C532.067 224.311 533.378 224.296 533.378 223.941C533.378 223.2 537.822 191.348 537.822 191.348H522.267Z"
					fill="#003584"/>
				<path
					d="M539.07 215.8C540.236 211.454 539.044 207.357 536.409 206.65C533.773 205.944 530.692 208.894 529.526 213.24C528.36 217.587 529.551 221.683 532.187 222.39C534.822 223.097 537.904 220.147 539.07 215.8Z"
					fill="#CADFFF"/>
				<path
					d="M528.933 209.126L528.193 222.459L531.896 223.2L533.378 204.681L528.933 209.126ZM538.089 192.756C525.801 195.724 513.262 197.534 500.637 198.163C494.126 198.489 486.889 198.237 482.133 193.778C478.215 190.104 476.933 184.089 478.4 178.918C479.867 173.748 483.793 169.481 488.526 166.941C493.259 164.4 498.726 163.467 504.096 163.541C502.059 163.193 500.289 165.548 500.689 167.578C501.089 169.607 503.007 171.052 505.015 171.563C507.022 172.074 509.126 171.837 511.193 171.674C516.37 171.274 521.63 171.37 526.659 172.652C531.689 173.926 536.696 176.652 539.081 181.267C541.467 185.881 540.185 192.541 535.481 194.733"
					fill="#003584"/>
				<path
					d="M50.4593 652.771H749.533C754.837 652.771 759.133 657.067 759.133 662.371V713.356C759.133 718.659 754.837 722.956 749.533 722.956H50.4593C45.1556 722.956 40.8593 718.659 40.8593 713.356V662.371C40.8593 657.067 45.1556 652.771 50.4593 652.771Z"
					fill="#68A3FD"/>
				<path
					d="M374.689 344.267C374.689 344.267 383.622 341.326 385.37 342.356C387.118 343.385 396.193 355.578 396.193 355.578L384.052 361.37L370.304 352.452L374.689 344.267ZM160.437 403.696C160.437 403.696 153.163 425.4 154.689 429.281C156.222 433.163 172.689 437.526 172.689 437.526L171.156 420.615L175.37 427.341L177.289 420.615L169.244 403.704H160.437V403.696Z"
					fill="#B1CFFC"/>
				<path
					d="M286.726 640.319L325.104 652.77H278.378V640.319H286.726ZM191.607 640.319L226.659 652.77H180.333L182.711 640.319H191.607Z"
					fill="#0A2751"/>
				<path
					d="M265.422 388.296L291.393 645.993H275.77C275.77 645.993 238.63 537.726 251.022 523.4C247.037 505.659 221.667 435.378 227.526 427.874C233.385 420.37 244.126 381.193 244.126 381.193L265.422 388.296Z"
					fill="#082147"/>
				<path
					d="M261.4 392.193L248.807 409.533L196.882 645.993H180.385C180.385 645.993 179.015 527.37 190.837 512.393C195.563 485.593 198.511 392.193 207.185 385.889C222.948 385.104 258.437 378.793 258.437 378.793L261.4 392.193Z"
					fill="#0A2751"/>
				<path
					d="M266.696 420L250.4 384.444L217.807 420L202.993 393.333C202.993 393.333 197.067 317.037 189.659 300.741C151.141 325.185 172.622 405.926 172.622 405.926C172.622 405.926 169.659 407.407 160.03 405.926C137.807 349.63 162.993 280.741 173.363 264.444C181.407 251.807 205.037 248.97 222.844 247.296C228 246.815 235.259 250.948 238.919 250.452C238.919 250.452 255.474 249.896 269.548 253.807L269.563 253.815C275.637 255.504 280.763 257.178 282.993 258.519C287.437 263.704 266.696 420 266.696 420Z"
					fill="#003584"/>
				<path
					d="M282.993 258.519C282.993 258.519 295.585 331.852 374.844 342.963C374.104 348.148 374.844 355.556 374.844 355.556C374.844 355.556 298.548 357.778 277.807 311.852C271.141 268.148 282.993 258.519 282.993 258.519Z"
					fill="#003584"/>
				<path
					d="M265.422 252.696L251.363 340.837L222.844 247.296C228 246.815 232.667 246.422 236.326 245.926L265.422 252.696Z"
					fill="white"/>
				<path
					d="M233.244 180.496C225.096 187.163 221.393 202.719 225.096 210.867C228.8 219.015 238.43 223.459 242.133 227.904C249.541 219.015 259.911 213.089 259.911 213.089L254.726 190.867L244.356 185.682L233.244 180.496Z"
					fill="#003584"/>
				<path d="M258.43 232.037V251.296L251.022 262.407L233.244 245.926L240.281 211.815L258.43 232.037Z"
							fill="#B1CFFC"/>
				<path
					d="M284.356 187.163C282.133 211.607 283.615 239.015 280.652 242.719C277.689 246.422 248.059 246.422 244.356 235.311C240.652 224.2 231.022 196.793 238.43 187.163C245.837 177.533 284.356 187.163 284.356 187.163Z"
					fill="#CADFFF"/>
				<path
					d="M248.8 189.385C250.281 195.311 248.8 206.422 242.874 207.904C242.874 210.733 243.178 215.674 243.393 218.881C243.421 219.302 243.366 219.724 243.23 220.122C243.094 220.521 242.881 220.889 242.602 221.205C242.322 221.52 241.983 221.777 241.604 221.961C241.225 222.144 240.813 222.251 240.393 222.274C239 222.348 237.689 222.333 237.689 221.978C237.689 221.237 233.244 189.385 233.244 189.385H248.8Z"
					fill="#003584"/>
				<path
					d="M238.868 220.461C241.503 219.754 242.694 215.658 241.529 211.311C240.363 206.965 237.281 204.014 234.645 204.721C232.01 205.428 230.819 209.525 231.985 213.871C233.15 218.218 236.232 221.168 238.868 220.461Z"
					fill="#CADFFF"/>
				<path
					d="M242.133 207.163L242.874 220.496L239.17 221.237L237.689 202.719L242.133 207.163ZM245.356 190.459C249.607 193.844 255.252 195.43 260.637 194.748C264.904 194.207 268.956 192.333 273.252 192.23C276.541 192.148 279.756 193.119 283.03 193.43C286.807 193.785 290.778 193.215 293.97 191.163C297.163 189.111 299.43 185.422 299.156 181.637C298.778 176.356 293.659 172.407 288.43 171.548C283.2 170.689 277.911 172.185 272.83 173.696C267.756 175.2 262.481 176.748 257.244 175.985C253.578 175.452 250.148 173.8 246.489 173.193C242.526 172.526 238.356 173.133 234.748 174.896C232.022 176.23 229.578 178.252 228.074 180.896C226.57 183.541 226.111 186.822 227.156 189.674C228.096 192.237 230.185 194.289 232.63 195.504C235.074 196.719 237.852 197.148 240.585 197.096L245.356 190.459Z"
					fill="#003584"/>
				<path
					d="M251.022 262.407L243.267 273.489L227.533 249.541L233.244 245.926L251.022 262.407ZM251.022 262.407L253.437 273.489L261.4 256.467L258.43 251.296L251.022 262.407Z"
					fill="#F2F2F2"/>
				<path
					d="M252.644 269.859L251.022 274.385L257.748 300.778C257.748 300.778 252.03 343.045 251.356 340.837C250.681 338.63 238.6 298.985 238.6 298.985L246.622 274.496L245.837 269.815L251.022 262.407L252.644 269.859Z"
					fill="#5598FF"/>
				<path
					d="M376.148 359.104C377.578 357.104 381.874 351.748 381.874 351.748L392.748 359.104L385.311 363.97L376.148 359.104ZM396.496 344.267C396.496 344.267 390.926 341.563 387.185 341.326C383.444 341.089 378.519 346.415 378.519 346.415C378.519 346.415 378.674 347.37 380.667 347.37C382.652 347.37 386.63 345.459 386.63 345.459L396.667 349.667L396.496 344.267Z"
					fill="#CADFFF"/>
				<path
					d="M92.8222 661.941C108.881 661.941 115.659 649.118 118.326 635.511C121.341 620.163 119.126 603.83 119.126 603.83H66.5185C66.5185 603.83 66.437 604.452 66.3185 605.563C65.3407 615.222 62.1704 661.941 92.8222 661.941Z"
					fill="#B6D3FF"/>
				<path
					d="M66.5185 603.83C66.5185 604.978 78.2963 605.911 92.8296 605.911C107.356 605.911 119.141 604.978 119.141 603.83C119.141 602.681 107.363 601.748 92.8296 601.748C78.2963 601.748 66.5185 602.681 66.5185 603.83Z"
					fill="#85B5FF"/>
				<path
					d="M21.5852 457.97C21.5852 457.97 49.3334 500.185 65.9778 525.948C82.6222 551.711 86.9852 557.059 88.963 565.385C90.9482 573.711 92.7259 604.422 92.7259 604.422H93.9556C93.9556 604.422 93.5259 578.348 92.0297 569.578C90.5778 561.096 92.1334 558.052 93.3259 549.133C94.5185 540.215 96.1037 525.948 87.0815 516.037C78.0667 506.126 80.4445 496.415 77.2741 489.882C74.1037 483.341 49.5259 475.415 46.3556 468.674C43.1852 461.933 29.3185 457.77 21.5852 457.97Z"
					fill="#1059C8"/>
				<path
					d="M21.5852 457.97C21.5852 457.97 30.3037 467.881 36.2519 471.252C42.2 474.622 44.9704 471.644 49.5259 479.77C54.0815 487.896 54.2593 491.467 58.4296 495.622C62.6 499.785 67.5556 499.985 67.9556 509.096C68.3556 518.215 65.9778 525.941 65.9778 525.941C65.9778 525.941 23.3704 460.148 21.5852 457.97Z"
					fill="#4189F7"/>
				<path
					d="M90.5333 604.422H89.2889C89.2889 604.422 88.9185 595.985 85.563 591.889C83.4 589.244 74.2148 586.244 65.1407 582.644C60.1407 580.667 55.1778 578.504 51.4296 576.118C40.8741 569.415 30.8148 547.815 29.9556 543.963C29.6519 542.622 29.5185 541.807 29.5259 541.252V541.237C29.5481 540.2 30.0593 540.044 30.9481 539C31.3778 538.496 32.6593 538.667 34.2296 539.148C38.7778 540.533 42.6222 543.652 45 547.77C47.2 551.578 50.7185 556.341 55.0296 557.496C62.4815 559.481 61.8593 560.718 64.6074 568.296C67.3482 575.874 74.2741 577.111 82.963 586.044C91.6519 594.985 90.5333 604.422 90.5333 604.422Z"
					fill="#1059C8"/>
				<path
					d="M65.1407 582.645C60.1407 580.667 55.1778 578.504 51.4296 576.119C40.8741 569.415 30.8148 547.815 29.9556 543.963C29.6519 542.622 29.5185 541.808 29.5259 541.252C29.5556 541.504 30.0444 544.926 35.5481 547.2C41.2519 549.556 42.3704 549.556 43.1185 553.156C43.8667 556.756 44.4889 560.608 47.4667 561.348C50.4444 562.096 52.3111 558.741 54.6593 564.2C57.0222 569.659 58.1481 571.526 60.3111 572.267C62.4815 573.015 64.1259 571.896 64.6074 576.119C64.8963 578.696 65.0593 581.141 65.1407 582.645Z"
					fill="#4189F7"/>
				<path
					d="M99.6593 604.422H101.133C101.133 604.422 104.015 552.163 107.978 547.311C110.533 544.178 118.978 538.57 129.733 534.304C135.652 531.956 141.541 529.4 145.978 526.578C158.481 518.637 170.393 493.052 171.415 488.489C171.778 486.904 171.933 485.941 171.926 485.274V485.259C171.896 484.03 171.289 483.844 170.244 482.615C169.741 482.015 168.215 482.222 166.356 482.793C160.963 484.437 156.415 488.126 153.6 493.007C150.993 497.519 146.822 503.163 141.719 504.526C132.896 506.881 133.63 508.348 130.37 517.319C127.126 526.289 118.919 527.756 108.63 538.341C98.3333 548.926 99.6593 604.422 99.6593 604.422Z"
					fill="#1059C8"/>
				<path
					d="M129.733 534.304C135.652 531.956 141.541 529.4 145.978 526.578C158.481 518.637 170.393 493.052 171.415 488.489C171.778 486.904 171.933 485.941 171.926 485.274C171.889 485.578 171.311 489.622 164.8 492.311C158.044 495.096 156.719 495.096 155.837 499.37C154.956 503.63 154.215 508.193 150.689 509.074C147.163 509.956 144.956 505.985 142.163 512.452C139.363 518.918 138.037 521.126 135.467 522.007C132.896 522.889 130.948 521.563 130.378 526.57C130.022 529.63 129.83 532.526 129.733 534.304Z"
					fill="#4189F7"/>
				<path
					d="M92.8222 661.941C108.881 661.941 115.659 649.119 118.326 635.511C102.585 629.541 82.763 619.978 66.3185 605.563C65.3407 615.222 62.1704 661.941 92.8222 661.941Z"
					fill="#CCE0FF"/>
				<path
					d="M708.474 663.904C719.104 663.904 723.585 655.415 725.356 646.407C727.348 636.252 725.889 625.437 725.889 625.437H691.067C691.067 625.437 691.015 625.852 690.933 626.585C690.281 632.985 688.185 663.904 708.474 663.904Z"
					fill="#B6D3FF"/>
				<path
					d="M691.059 625.444C691.059 626.207 698.852 626.822 708.474 626.822C718.089 626.822 725.889 626.207 725.889 625.444C725.889 624.681 718.096 624.067 708.474 624.067C698.859 624.067 691.059 624.681 691.059 625.444Z"
					fill="#85B5FF"/>
				<path
					d="M651.37 509.8C651.37 509.8 673.356 543.244 686.541 563.659C699.733 584.074 703.185 588.311 704.756 594.904C706.326 601.496 707.741 625.837 707.741 625.837H709.467C709.467 625.837 710.563 605.267 708.526 598.518C706.482 591.77 707.267 589.096 708.215 582.03C709.156 574.963 710.415 563.659 703.267 555.807C696.126 547.956 698.007 540.267 695.496 535.081C692.985 529.896 673.511 523.622 671 518.281C668.489 512.941 657.496 509.644 651.37 509.8Z"
					fill="#1059C8"/>
				<path
					d="M656.178 509.8C656.178 509.8 663.089 517.652 667.8 520.319C672.511 522.985 674.711 520.63 678.319 527.067C681.933 533.504 682.067 536.333 685.378 539.63C688.681 542.926 692.607 543.082 692.926 550.304C693.237 557.526 691.356 563.652 691.356 563.652C691.356 563.652 657.593 511.526 656.178 509.8Z"
					fill="#4189F7"/>
				<path
					d="M715.052 625.444L716.311 625.067C716.311 625.067 714.126 616.415 716.282 611.252C717.667 607.919 726.052 602.096 734.148 595.704C738.607 592.185 742.978 588.489 746.052 584.941C754.704 574.956 758.326 550.045 758.037 545.882C757.941 544.43 757.83 543.57 757.652 543.007L757.644 542.993C757.311 541.948 756.741 541.948 755.526 541.163C754.941 540.778 753.689 541.348 752.252 542.311C748.067 545.096 745.126 549.415 743.963 554.304C742.889 558.83 740.77 564.719 736.763 567.185C729.83 571.459 730.83 572.519 730.341 581.022C729.867 589.519 723.23 592.874 717.148 604.556C711.059 616.23 715.052 625.444 715.052 625.444Z"
					fill="#1059C8"/>
				<path
					d="M734.148 595.696C738.607 592.178 742.978 588.481 746.052 584.933C754.704 574.948 758.326 550.037 758.037 545.874C757.941 544.422 757.83 543.563 757.652 543C757.696 543.267 758.237 546.874 753.363 550.844C748.304 554.956 747.17 555.296 747.511 559.17C747.852 563.037 748.385 567.126 745.593 568.785C742.8 570.444 739.904 567.615 739.178 573.852C738.444 580.096 737.874 582.319 735.904 583.733C733.933 585.141 731.933 584.511 732.719 588.933C733.2 591.622 733.778 594.148 734.148 595.696Z"
					fill="#4189F7"/>
				<path
					d="M708.474 663.904C719.104 663.904 723.585 655.415 725.356 646.407C714.941 642.459 701.815 636.126 690.933 626.585C690.281 632.985 688.185 663.904 708.474 663.904Z"
					fill="#CCE0FF"/>
			</g>
			<g>
				<path
					d="M679.437 121.607H592.911C575.778 121.607 561.763 135.615 561.763 152.733C561.763 164.696 568.607 175.126 578.57 180.333L576.681 189.215C576.681 189.215 590.659 183.859 592.911 183.859H679.437C696.57 183.859 710.585 169.852 710.585 152.733C710.585 135.615 696.57 121.607 679.437 121.607Z"
					fill="#1059C8"/>
				<path
					d="M686.659 129.704H600.133C583 129.704 568.985 143.711 568.985 160.83C568.985 172.793 575.83 183.222 585.793 188.43L583.904 197.311C583.904 197.311 597.881 191.956 600.133 191.956H686.659C703.793 191.956 717.807 177.948 717.807 160.83C717.807 143.711 703.793 129.704 686.659 129.704Z"
					stroke="#2A7AF4" stroke-miterlimit="10"/>
			</g>
			<g>
				<path
					d="M210.215 143.956H115.615C111.681 143.956 108.459 147.178 108.459 151.111V195.563C108.459 199.496 111.681 202.719 115.615 202.719H187.022L200.274 212.593L194.696 202.719H210.222C214.156 202.719 217.378 199.496 217.378 195.563V151.111C217.378 147.178 214.156 143.956 210.215 143.956Z"
					fill="#CCE0FF"/>
				<path
					d="M207.63 148.563H113.022C109.089 148.563 105.867 151.785 105.867 155.719V200.17C105.867 204.104 109.089 207.326 113.022 207.326H184.43L197.681 217.2L192.104 207.326H207.63C211.563 207.326 214.785 204.104 214.785 200.17V155.719C214.785 151.785 211.563 148.563 207.63 148.563Z"
					stroke="#CCE0FF" stroke-miterlimit="10"/>
			</g>
		</svg>

	);
};

export default Client;
