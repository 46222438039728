import React, { useState, useContext, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { collections, companyCollection } from "../firebase";
import { InitDatabase, database } from "../data/LocalDatabse";

const SearchFilesContext = React.createContext();

export const useSearch = () => {
    return useContext(SearchFilesContext);
};

export const SearchFilesProvider = ({ children }) => {
    const [folders, setFolders] = useState([]);

    const [queryText, setQueryText] = useState("");
    const [originalData, setOriginalData] = useState([]);
    const [queriedData, setQueriedData] = useState([]);
    // const [loading, setLoading] = useState(true);
    const [loading, setLoading] = useState(true);

    const { currentUser, firebaseUser } = useAuth();

    // Search for data
    const searchText = () => {
        console.log("Searching");
    };

    const setDataToQuery = (array) => setOriginalData([...array]);

    // const getFolders = async () => {
    //     console.log("Search context initialized");
    //     await InitDatabase(firebaseUser);
    //
    //     const sessionFolders = JSON.parse(sessionStorage.getItem("folders"));
    //     setFolders(sessionFolders);
    //     return sessionFolders;
    // };
    //
    useEffect(() => {
        // if (folders.length === 0)
        // getFolders()
        //     .then((folders) => console.log("Session folders:", folders));


        // if (database.folders.length === 0 && database.files.length === 0)
        //     InitDatabase(firebaseUser)
        //         .then(() => console.log("Database initialied from Search context"))
        //         .then(() => setLoading(false));

        setLoading(false);
    }, []);


    useEffect(() => {
        setQueriedData([]);
    }, [originalData]);

    useEffect(() => {
        // const delayedSearch = setTimeout(() => {
        //     // if (!sessionStorage.hasOwnProperty("search-files"))
        //     //     getFiles();
        //     // else
        //     //     setFiles(JSON.parse(sessionStorage.getItem("search-files")));
        //     //
        //     // if (queryText.length > 0)
        //     //     // console.log(files.filter(file => file.name.includes(queryText)));
        //     //     displayFiles(files.filter(file => file.name.includes(queryText)));
        //
        //     // setData(queryText);
        //     console.log(queryText);
        //
        //
        // }, 1000);
        //
        // return () => clearTimeout(delayedSearch);

        // console.log(originalData);

        const dataArray = [];

        if (queryText.length > 2) {
            originalData.forEach((data) => {
                // QuickMail default and technical support mails
                /*switch (data?.target) {
                    case "default":
                        data.target = "jkranjec@t-2.net"; // process.env.defaultMail
                        break;
                    case "technicalSupport":
                        data.target = "vid.kranjec@gmail.com"; // process.env.technicalSupportMail
                        break;
                }*/

                // Files, Folders and name (exclude QuickMail name, it is sender name)
                if ("name" in data && data.name.toLowerCase().includes(queryText) && (!"target" in data)) {
                    return dataArray.push(data);
                }
                // QuickMail target
                if ("target" in data && data.target.toLowerCase().includes(queryText)) {
                    return dataArray.push(data);
                }
            });
            setQueriedData(dataArray);
        } else setQueriedData([...originalData]);
    }, [queryText]);

    // Reset states
    // const resetSearchContext = () => {
    //     setOriginalData([]);
    //     setQueriedData([]);
    //     setQueryText("");
    // };

    const value = {
        setQueryText,
        setOriginalData,
        queriedData,
        originalData,
        searchText,
        setDataToQuery,
        // resetSearchContext,
    };

    return <SearchFilesContext.Provider value={value}>{!loading && children}</SearchFilesContext.Provider>;
};

