import { useEffect, useCallback } from "react";
import {
  collections,
  formatDoc,
  getFirebaseDocs,
  companyCollectionNew,
  getSnapshotDocs,
} from "../firebase";
import { query, where, onSnapshot } from "firebase/firestore";

/*export*/
const database = {
  loaded: false,
  folders: [],
  files: [],
};

export const graph = new Map([["root", []]]);
export const filesGraph = new Map([["root", []]]);

const addFolder = (folderId) => {
  graph.set(folderId, []);
  filesGraph.set(folderId, []);
};

const addConnection = (parentId, childId) => {
  // Connection from parent to child folder
  graph.get(parentId).push(childId);

  // Connection from child to parent folder
};

const addFile = (folderId, fileId) => {
  filesGraph.get(folderId === null ? "root" : folderId).push(fileId);
};

// Init function to get all folders and files
export const InitDatabase = async (user) => {
  console.log("Initialized database");
  let folders = [], files = [];

  // Return if there is already graph build
  if (database.folders.length !== 0) {
    console.log("returning from database");
    return;
  }

  // Get folder
  const queryFolders = await query(companyCollectionNew(collections.FOLDERS), where("companyUid", "==", user.companyUid));
  const unsubscribeFolders = onSnapshot(queryFolders, snapshot =>
    // snapshot.forEach(doc => database.folders.push(formatDoc(doc)))
    snapshot.forEach(doc => folders.push(formatDoc(doc))),
    // snapshot.forEach(doc => console.log(doc.data())),
  );

  // Get files
  const queryFiles = await query(companyCollectionNew(collections.FILES), where("companyUid", "==", user.companyUid));
  const unsubscribeFiles = onSnapshot(queryFiles, snapshot =>
    snapshot.forEach(doc => database.files.push(formatDoc(doc))),
  );

  // Create connections between vertices
  await database.folders.forEach((folder) => addFolder(folder.id));
  await database.folders.forEach((folder) => {
    addConnection(folder.parentId === null ? "root" : folder.parentId, folder.id);
  });

  await database.files.forEach((file) => addFile(file.folderId, file.id));

  // await console.log(folders);
  // database.folders.forEach((folder) => {
  //     console.log(folder);
  // });
  // console.log(sessionFolders);

  await sessionStorage.setItem("folders", JSON.stringify(database.folders));
  await sessionStorage.setItem("files", JSON.stringify(database.files));
  console.log("Session storage set");

  console.log(JSON.parse(sessionStorage.getItem("folders")));
  // console.log(database.folders);

  // console.log("Folders map:", graph);
  // console.log("Files map:", filesGraph);
  // console.log(database);

  database.loaded = true;

  // return {fodlers, files}
  // uporabi useMemo pri deklaraciji grafa
};

export const getDatabase = () => {
  return { folders: [...database.folders], files: [...database.files] };
};
