import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBars,
	faFolder,
	faCircleQuestion,
	faKeyboard,
	faEnvelope,
	faUserGear,
	faAddressBook,
} from "@fortawesome/free-solid-svg-icons";

export const SidebarData = [
	{ title: "Jokra", icon: <FontAwesomeIcon icon={faBars}/>, link: "", permission: "all" },
	{ title: "Izbira podjetja", icon: <FontAwesomeIcon icon={faAddressBook}/>, link: "/company", permission: "admin" },
	{ title: "Datoteke", icon: <FontAwesomeIcon icon={faFolder}/>, link: "/folder", permission: "all" },
	{ title: "Hitri mail", icon: <FontAwesomeIcon icon={faEnvelope}/>, link: "/quickMail", permission: "all" },
	{ title: "Profil", icon: <FontAwesomeIcon icon={faUserGear}/>, link: "/user", permission: "all" },
	{ title: "Pomoč", icon: <FontAwesomeIcon icon={faCircleQuestion}/>, link: "/help", permission: "all" },
	{
		title: "Nadzorna plošča",
		icon: <FontAwesomeIcon icon={faKeyboard}/>,
		link: "/admin-dashboard",
		permission: "admin",
	},
	// { title: "Odjava", icon: <FontAwesomeIcon icon={faRightFromBracket}/>, link: "/user", permission: "all" },
];