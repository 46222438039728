import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";

import Client from "./Client";
import SloveniaBoard from "./SloveniaBoard";

const MiddleSection = () => {
	const [loaded, setLoaded] = useState(false);
	const clientRef = useRef(null);
	const sloveniaRef = useRef(null);

	// Header
	useEffect(() => {
		setLoaded(true);
	}, []);

	// Client section
	useEffect(() => {
		if (loaded) {
			const element = clientRef.current;
			gsap.fromTo(element.querySelector("div:first-of-type > h3"),
				{ opacity: 0, y: -50 },
				{
					opacity: 1, y: 0, delay: .4,
					scrollTrigger: {
						trigger: element,
						start: "top center",
						end: "bottom top",
						scrub: false,
					},
				},
			);

			gsap.to(element.querySelector("div:first-of-type > h3"),
				{
					y: -50,
					scrollTrigger: {
						trigger: element,
						start: "center center",
						end: "bottom top",
						scrub: true,
						immediateRender: false,
					},
				},
			);

			gsap.fromTo(element.querySelector("div:first-of-type > p"),
				{ opacity: 0, y: -50 },
				{
					opacity: 1, y: 0, delay: .6,
					scrollTrigger: {
						trigger: element,
						start: "top center",
						end: "bottom top",
						scrub: false,
					},
				},
			);

			gsap.to(element.querySelector("div:first-of-type > p"),
				{
					y: -80,
					scrollTrigger: {
						trigger: element,
						start: "center center",
						end: "bottom top",
						scrub: true,
						immediateRender: false,
					},
				},
			);
		}
	}, [loaded]);

	// Slovenia section
	useEffect(() => {
		if (loaded) {
			const element = sloveniaRef.current;
			gsap.fromTo(element.querySelector("div:first-of-type > h3"),
				{ opacity: 0, y: -50 },
				{
					opacity: 1, y: 0, delay: .4,
					scrollTrigger: {
						trigger: element,
						start: "top center",
					},
				},
			);

			gsap.to(element.querySelector("div:first-of-type > h3"),
				{
					y: -50,
					scrollTrigger: {
						trigger: element,
						start: "center center",
						scrub: true,
						immediateRender: false,
					},
				},
			);

			gsap.fromTo(element.querySelector("div:first-of-type > p"),
				{ opacity: 0, y: -50 },
				{
					opacity: 1, y: 0, delay: .6,
					scrollTrigger: {
						trigger: element,
						start: "top center",
					},
				},
			);

			gsap.to(element.querySelector("div:first-of-type > p"),
				{
					y: -80,
					scrollTrigger: {
						trigger: element,
						start: "center center",
						scrub: true,
						immediateRender: false,
					},
				},
			);
		}
	}, [loaded]);

	return (
		<>
			<section id="client-section" ref={clientRef}>
				{loaded &&
					<>
						<Client element={clientRef.current}/>
						<div>
							<h3>Več kot <span className="important-text">60</span> strank uspešno posluje z nami</h3>
							<p>
								Neodvisni od zavarovalnic strankam na osnovi strokovnih in objektivnih argumentov pomagamo pri izbiri
								najprimernejše zavarovalnice. Zavarovalni program prilagodimo potrebam zavarovalca in pri tem v največji
								meri ščitimo njegove interese. Naša naloga je, da lahko zavarovalec svojo odločitev o izbiri kritne
								zavarovalnice sprejme na podlagi naše kakovostne in celovite informacije.
							</p>
						</div>
					</>
				}
			</section>
			<section id="slovenia-section" ref={sloveniaRef}>
				{loaded &&
					<>
						<div>
							<h3>Svojo dejavnost opravljamo po celotni <span className="important-text">Sloveniji</span></h3>
							<p>
								Vaše premoženje in dejavnost podrobno pregledamo, ocenimo vaša zavarovalna tveganja in na podlagi ocene
								pripravimo predlog kvalitenega in optimalnega zavarovalnega programa za vas.
								Na osnovi bogatih izkušenj iz reševanja škod, velik poudarek namenjamo pomoči pri uveljavljanju
								zavarovalnin in odškodnin iz vašega zavarovalnega programa.
								Našim strankam opravimo tudi zakonsko potrebno primerljivo analizo nevarnosti običajnih zavarovarljivih
								tveganj.
							</p>
						</div>
						<SloveniaBoard element={sloveniaRef.current}/>
					</>
				}
			</section>
		</>
	);
};

export default MiddleSection;
