import React, { useEffect, useState } from "react";
import { SidebarData } from "./SidebarData";
import { v4 as uuid } from "uuid";
import { useAuth } from "../../contexts/AuthContext";
import { getDocs, getDoc, query, where } from "firebase/firestore";
import { usersRef } from "../../firebase";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
import SidebarLink from "./SidebarLink";

import "../../style/sass/Sidebar/Sidebar.css";

export default function Sidebar() {
    const [width, setWidth] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const { currentUser } = useAuth();

    const { logout } = useAuth();
    const navigate = useNavigate();

    // currentUser from Firestore
    const [currentUserRole, setCurrentUserRole] = useState("");

    const getUserRole = async () => {
        const querySnapshot = query(usersRef, where("uid", "==", currentUser.uid));
        const queryDocs = await getDocs(querySnapshot);
        queryDocs.forEach((user) => {
            setCurrentUserRole(user.data().role);
        });
    };

    useEffect(() => {
        getUserRole();
    }, [currentUser]);

    async function handleLogout() {
        setError("");

        try {
            await logout();
            navigate("/login");
        } catch (err) {
            setError("Napaka pri odjavljanju");
        }
    }

    return (
        <>
            <Modal centered show={loading} onHide={() => setLoading(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Odjava
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Se res želite odjaviti?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleLogout}>Da</Button>
                </Modal.Footer>
            </Modal>

            <div className={width ? "Sidebar extended" : "Sidebar"}>
                <div id="toggleWidthBtn" className="SidebarRow" onClick={() => setWidth(!width)}>
                    <div className="SidebarIcon" key={uuid()}>
                        {SidebarData[0].icon}
                    </div>
                    <div className="SidebarTitle" key={uuid()}>
                        {SidebarData[0].title}
                    </div>
                </div>
                <ul className="SidebarList">
                    {SidebarData.map((val, index) => {
                        if (index !== 0 && val.permission !== "admin")
                            return <SidebarLink value={val} key={index}/>;

                        else if (val.permission === "admin" && currentUserRole === "admin")
                            // if (currentUserRole === "admin")
                            return <SidebarLink value={val} key={index}/>;

                    })}
                    <li className="SidebarRow" onClick={() => setLoading(true)}>
                        <div className="SidebarIcon">
                            <FontAwesomeIcon icon={faRightFromBracket}/>
                        </div>
                        <div className="SidebarTitle" key={uuid()}>
                            Odjava
                        </div>
                    </li>
                </ul>

            </div>
        </>
    );
}