import {
	createUserWithEmailAndPassword,
	onAuthStateChanged,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signOut,
	updatePassword,
	updateEmail, setPersistence, browserSessionPersistence, inMemoryPersistence, browserLocalPersistence,
} from "firebase/auth";
import React, { useContext, useState, useEffect } from "react";
import { auth, usersRef } from "../firebase";
import { getDocs, query, where } from "firebase/firestore";
import Loader from "../components/Loader";
import { InitDatabase } from "../data/LocalDatabse";

const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState({});
	const [firebaseUser, setFirebaseUser] = useState({});
	const [loading, setLoading] = useState(true);

	function signup(email, password) {
		return createUserWithEmailAndPassword(auth, email, password);
	}

	function login(email, password, rememberMe) {
		// session persistence za avtomatski logout po zaprtju
		// inMemory persistence za "ostani prijavljen" po zaprtju
		rememberMe ?
			setPersistence(auth, browserLocalPersistence) :
			setPersistence(auth, browserSessionPersistence);

		return signInWithEmailAndPassword(auth, email, password);
	}

	function logout() {
		setFirebaseUser(null);
		return signOut(auth);
	}

	function resetPassword(email) {
		return sendPasswordResetEmail(auth, email);
	}

	function updateUserEmail(email) {
		return updateEmail(auth.currentUser, email);
	}

	function updateUserPassword(password) {
		return updatePassword(auth.currentUser, password);
	}

	// Firebase user getter
	const getFirebaseUser = async (user) => {
		// if (window.location.pathname == "/login") return;

		try {
			const querySnapshot = query(usersRef, where("uid", "==", user.uid));
			const firebaseDocs = await getDocs(querySnapshot);

			let firebaseUserData = {};

			firebaseDocs.forEach((firebaseUser) => {
				firebaseUserData = firebaseUser.data();
				sessionStorage.setItem("currentUserFire", JSON.stringify(firebaseUser.data()));
			});

			await setFirebaseUser(firebaseUserData);
			// await InitDatabase(firebaseUserData);
			// setLoading(false);
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			setCurrentUser(user);
			getFirebaseUser(user)
				.then(() => {
					// console.log("Firebase user:", firebaseUser);
					setLoading(false);
				});

			// const firebaseResponse = await getFirebaseUser(user);  //  -> spremeni getFirebaseUser() funkcijo, da samo vrne response in ne nastavi state spremenljivke
			// setFirebaseUser(firebaseResponse ? firebaseResponse?.data() : null);
			// console.log("Firebase user:", firebaseUser);
			// setLoading(false);


		});
		return unsubscribe;
	}, []);

	let value = {
		currentUser,
		firebaseUser,
		setFirebaseUser,
		signup,
		login,
		logout,
		resetPassword,
		updateUserEmail,
		updateUserPassword,
	};


	return !loading &&
		<AuthContext.Provider value={value}>
			{children}
			{/*{loading ? <Loader/> : children}*/}
		</AuthContext.Provider>;

}
