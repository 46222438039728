import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import VirtualAssistant from "./VirtualAssistant";
import { addDoc, serverTimestamp } from "firebase/firestore";
import { collections, companyCollectionNew } from "../../../firebase";
import { Alert } from "react-bootstrap";

const ContactSection = () => {
	const [loaded, setLoaded] = useState(false);
	const [name, setName] = useState("");
	const [lastname, setLastname] = useState("");
	const [email, setEmail] = useState("");
	const [question, setQuestion] = useState("");
	const [message, setMessage] = useState({});


	const contactRef = useRef(null);

	useEffect(() => {
		setLoaded(true);
	}, []);


	useEffect(() => {
		const element = contactRef.current;
		const formElements = element.querySelectorAll("form > div, form > input[type='submit']");

		if (loaded) {
			gsap.fromTo(element.querySelector("div:first-of-type h3"),
				{ opacity: 0, y: -50 },
				{
					opacity: 1, y: 0, delay: .3,
					scrollTrigger: {
						trigger: element,
						start: "top center",
						end: "bottom top",
						scrub: false,
					},
				},
			);

			gsap.fromTo(element.querySelector("div:first-of-type h4"),
				{ opacity: 0, y: -50 },
				{
					opacity: 1, y: 0, delay: .4,
					scrollTrigger: {
						trigger: element,
						start: "top center",
						end: "bottom top",
						scrub: false,
					},
				},
			);

			gsap.fromTo(element.querySelector("svg"),
				{ opacity: 0, y: -50 },
				{
					opacity: 1, y: 0, delay: .6,
					scrollTrigger: {
						trigger: element,
						start: "top center",
						end: "bottom top",
						scrub: false,
					},
				},
			);

			formElements.forEach((formElement, i) =>
				gsap.fromTo(formElement,
					{ opacity: 0, y: -50 },
					{
						opacity: 1, y: 0, delay: .9 + i * .125,
						scrollTrigger: {
							trigger: element,
							start: "top center",
							end: "bottom top",
							scrub: false,
						},
					},
				),
			);
		}
	}, [loaded]);

	async function sendMail(e) {
		e.preventDefault();

		const options = {
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				name: name + " " + lastname,
				email: email,
				text: question,
				target: "jkranjec@t-2.net",
				type: "message",
			}),
		};

		try {
			// Testing URL
			// const response = await fetch("http://localhost:5001/jokra-doo/us-central1/app/quickMail", options);

			const response = await fetch(" https://us-central1-jokra-doo.cloudfunctions.net/app/quickMail", options);
			if (response.ok) {
				setMessage({ text: "Email poslan", type: "success" });
			}
		} catch (err) {
			console.error(err);
			setMessage({ text: "Sporočilo ni bilo poslano", type: "danger" });
		}

	}

	return (
		<section id="contact-section" ref={contactRef}>
			<span id="contact-section-anchor"/>
			{loaded &&
				<div>
					<div>
						<h3>Imate vprašanje?</h3>
						<h4><span className="important-text">Kontaktirajte nas</span></h4>
					</div>
					<VirtualAssistant element={contactRef.current}/>
				</div>
			}
			<form onSubmit={sendMail}>
				{message?.text && <Alert variant={message.type} dismissible>{message.text}</Alert>}
				<div>
					<div>
						<label>Ime</label>
						<input type="text" name="name-input" id="name-input"
									 className={`${name !== "" ? "fulfilled" : ""}`}
									 onChange={e => setName(e.target.value)}/>
					</div>
					<div>
						<label>Priimek</label>
						<input type="text" name="lastname-input" id="lastname-input"
									 className={`${lastname !== "" ? "fulfilled" : ""}`}
									 onChange={e => setLastname(e.target.value)}/>
					</div>
				</div>
				<div>
					<label>Email</label>
					<input type="email" name="email-input" id="email-input"
								 className={`${email !== "" ? "fulfilled" : ""}`}
								 onChange={e => setEmail(e.target.value)}/>
				</div>
				<div>
					<label>Vprašanje</label>
					<textarea rows={10} cols={50} name="question-input" id="question-input"
										className={`${question !== "" ? "fulfilled" : ""}`}
										onChange={e => setQuestion(e.target.value)}/>
				</div>
				<input type="submit" className="primary-button"
							 value="Pošlji vprašanje"/>
			</form>
		</section>
	);
};

export default ContactSection;
