import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Alert, Button, Form } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { getDocs, addDoc, serverTimestamp } from "firebase/firestore";
import { companiesRef, quickMailRef } from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import "../../style/sass/QuickMail/QuickMail.css";
import QuickMailEditor from "./quickMailEditor";

const QuickMail = () => {
    const { currentUser, firebaseUser } = useAuth();

    // const [animate, setAnimate] = useState(0);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [contactCompany, setContactCompany] = useState("");

    const contactEmail = useRef();
    const contactName = useRef();
    const contactText = useRef();
    const contactSubject = useRef();
    const targetEmail = useRef();

    // const sendMail = async (event) => {
    //     event.preventDefault();
    //     setLoading(true);
    //
    //     const options = {
    //         crossDomain: true,
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({
    //                 // email: contactEmail.current.value,
    //                 // name: contactName.current.value,
    //                 // company: contactCompany,
    //                 // subject: contactSubject.current.value,
    //                 // text: contactText.current.value,
    //                 // target: targetEmail.current.value,
    //                 // type: "message",
    //
    //                 email: currentUser.email,
    //                 name: firebaseUser.name + " " + firebaseUser.lastName,
    //                 company: contactCompany,
    //                 subject: contactSubject.current.value,
    //             },
    //         ),
    //     };
    //
    //     try {
    //         // Testing URL
    //         // const response = await fetch("http://localhost:5001/jokra-doo/us-central1/app/quickMail", options);
    //
    //         const response = await fetch(" https://us-central1-jokra-doo.cloudfunctions.net/app/quickMail", options);
    //         if (response.ok) {
    //             setSuccess("Email poslan");
    //
    //             // save to Firestore
    //             const newQuickMailDoc = await addDoc(quickMailRef, {
    //                 sender: currentUser.email,
    //                 name: firebaseUser.name + " " + firebaseUser.lastName,
    //                 company: contactCompany,
    //                 subject: contactSubject.current.value,
    //                 text: contactText.current.value,
    //                 target: targetEmail.current.value,
    //                 timestamp: serverTimestamp(),
    //             });
    //         }
    //     } catch (err) {
    //         console.error(err);
    //         setError(err.message);
    //     }
    //
    //     setLoading(false);
    // };

    const submitButton = useRef();
    const submitAnimation = (event) => {
        console.log(submitButton);
    };

    const getCompanies = async () => {
        const data = await getDocs(companiesRef);
        await data.forEach((company) => {
            if (company.id === firebaseUser.companyUid)
                setContactCompany(company.data().name);
        });
    };

    useEffect(() => {
        setError("");
        setSuccess("");

        if (firebaseUser) {
            getCompanies().catch(err => {
                setError("Failed to send mail.");
                console.error(err);
            });
        }
    }, []);

    return (
        <>
            {/*<Sidebar/>*/}
            <QuickMailEditor/>
            {/* QuickMailHistory */}

            {/*<main className="w-100 d-flex flex-column justify-content-center align-items-center"*/}
            {/*      style={{ height: "100vh" }}>*/}
            {/*    <div id="form-container" className="p-4">*/}
            {/*        <h1 className="text-center">Hitri mail</h1>*/}
            {/*        {loading && <Alert variant="warning" style={{ borderRadius: "20px" }}>Pošiljanje emaila, prosimo*/}
            {/*            počakajte</Alert>}*/}
            {/*        {error && <Alert variant="danger" style={{ borderRadius: "20px" }}>{error}</Alert>}*/}
            {/*        {success && <Alert variant="success" style={{ borderRadius: "20px" }}>{success}</Alert>}*/}
            {/*        <Form onSubmit={sendMail}>*/}
            {/*            <Form.Group>*/}
            {/*                <Form.Label>Ciljni email</Form.Label>*/}
            {/*                <Form.Select id="target-email" ref={targetEmail} className="mb-3"*/}
            {/*                             style={{ borderRadius: "20px" }}>*/}
            {/*                    <option value="default">jkranjec@t-2.net</option>*/}
            {/*                    <option value="technicalSupport">Tehnična podpora</option>*/}
            {/*                </Form.Select>*/}
            {/*            </Form.Group>*/}
            {/*            <Form.Group id="contact-email" className="mb-3">*/}
            {/*                <Form.Label>Email</Form.Label>*/}
            {/*                <Form.Control type="email" ref={contactEmail} value={currentUser.email} required disabled/>*/}
            {/*            </Form.Group>*/}
            {/*            <Form.Group id="contact-name" className="mb-3">*/}
            {/*                <Form.Label>Ime</Form.Label>*/}
            {/*                <Form.Control type="text" ref={contactName} required*/}
            {/*                              value={`${firebaseUser.name} ${firebaseUser.lastName}`} disabled/>*/}
            {/*            </Form.Group>*/}
            {/*            <Form.Group id="contact-company" className="mb-3">*/}
            {/*                <Form.Label>Podjetje</Form.Label>*/}
            {/*                <Form.Control type="text" value={contactCompany} required*/}
            {/*                              disabled/>*/}
            {/*            </Form.Group>*/}
            {/*            <Form.Group id="contact-subject" className="mb-3">*/}
            {/*                <Form.Label>Zadeva</Form.Label>*/}
            {/*                <Form.Control type="text" ref={contactSubject} required/>*/}
            {/*            </Form.Group>*/}
            {/*            <Form.Group id="contact-text" className="mb-3">*/}
            {/*                <Form.Label>Sporočilo</Form.Label>*/}
            {/*                <Form.Control as="textarea" required ref={contactText}/>*/}
            {/*            </Form.Group>*/}
            {/*            <Button disabled={loading} type="submit" className="mt-2">Pošlji*/}
            {/*                <FontAwesomeIcon icon={faPaperPlane} id="sendIcon" className="ms-2"/>*/}
            {/*            </Button>*/}
            {/*        </Form>*/}
            {/*    </div>*/}
            {/*</main>*/}
        </>
    );
};

export default QuickMail;
