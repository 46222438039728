import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";

const SidebarOutlet = () => {
    return (
        <>
            <Sidebar/>
            <Outlet/>
        </>
    );
};

export default SidebarOutlet;
