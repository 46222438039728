import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../style/sass/NotFound/NotFound.css";

export default function ForbiddenAccess() {
    const navigate = useNavigate();

    return (
        <div id="forbidden-access-container">
            <div>403</div>
            <div>Ups... Nimate dovoljenja za dostop do tega naslova</div>
            <Button onClick={() => navigate(-1)}>Pojdi nazaj</Button>
        </div>
    );
}