import React, { useRef, useEffect, useState } from "react";
import { Table, Modal, Form, Button } from "react-bootstrap";
import { addDoc, query, where, getDocs, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import { v4 as uuid } from "uuid";
import {
    companyCollectionNew,
    getFirebaseDocs,
    companiesRef,
    deleteFirebaseDoc,
    collections,
    firestore,
} from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faXmark } from "@fortawesome/free-solid-svg-icons";

import "../../style/sass/AdminDashboard/CompaniesTab/ComapniesTab.css";
import CompaniesModal from "./CompaniesModal";

const CompaniesTab = ({ companies, users }) => {
    const companyNameRef = useRef();
    const clerkName = useRef();
    const clerkLastname = useRef();
    const clerkEmail = useRef();

    const [show, setShow] = useState(false);
    const [activeCompany, setActiveCompany] = useState({});
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const handleClick = (company) => {
        setActiveCompany({ id: company.id, ...company.data() });
        setShow(true);
    };

    async function handleNewCompany(e) {
        e.preventDefault();
        try {
            await addDoc(companiesRef, {
                uid: uuid(),
                name: companyNameRef.current.value,
                employees: [],
                clerks: [{
                    name: clerkName.current.value,
                    lastName: clerkLastname.current.value,
                    email: clerkEmail.current.value,
                }],
            });
            setSuccess(`${companyNameRef.current.value} uspešno ustvarjeno`);
        } catch (err) {
            setError("Napaka pri ustvarjanju novega podjetja");
            console.error(err);
        }
    }

    const handleDelete = async (company) => {
        const options = (type, users = []) => {
            return {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    companyId: company.id,
                    type: type,
                    users: users,
                }),
            };
        };

        // Delete all files associated with company
        // Delete files collection in files/companyUid
        // options.body.type = collections.FILES;
        const filesOptions = options(collections.FILES);
        const filesResponse = await fetch(process.env.REACT_APP_SERVER_URL + "/admin/deleteSubCollection", filesOptions);
        if (!filesResponse.ok)
            return setError("Napaka pri brisanju datotek (collection)");

        try {
            await deleteFirebaseDoc(doc(firestore, collections.FILES, company.id));
        } catch (err) {
            setError("Napaka pri brisanju datotek (document)");
            console.error(err);
        }

        // Delete all folders associated with company
        // Delete files collection in files/companyUid
        // options.body.type = collections.FOLDERS;
        const foldersOptions = options(collections.FOLDERS);
        const foldersResponse = await fetch("/admin/deleteSubCollection", foldersOptions);
        if (!foldersResponse.ok)
            return setError("Napaka pri brisanju map (collection)");

        try {
            await deleteDoc(doc(firestore, collections.FOLDERS, company.id));
        } catch (err) {
            setError("Napaka pri brisanju map (document)");
            console.error(err);
        }

        // Delete all QuickMail messages where sender is company's employee
        // options.body.type = collections.QUICK_MAIL;
        // options.body.users = users.filter(user => user.companyUid === company.id);
        const filteredUsers = users.filter(user => {
            return user.companyUid === company.id;
        });
        const quickMailOptions = options(collections.QUICK_MAIL, filteredUsers);
        const quickMailResponse = await fetch(process.env.REACT_APP_SERVER_URL + "/admin/deleteQuickMails", quickMailOptions);
        if (!quickMailResponse.ok)
            return setError("Napaka pri brisanju e-pošte (collection)");

        try {
            await deleteDoc(doc(firestore, collections.QUICK_MAIL));
        } catch (err) {
            setError("Napaka pri brisanju e-pošte (document)");
            console.error(err);
        }

        // Delete users from company employees list
        const usersResponse = await fetch(process.env.REACT_APP_SERVER_URL + "/admin/deleteUsers");
        if (!usersResponse.ok)
            return setError("Napaka pri brisanju uporabnikov");

        // Delete the company itself
        try {
            await deleteDoc(doc(firestore, collections.COMPANIES, company.id));
        } catch (err) {
            setError("Napaka pri brisanju podjetja");
            console.error(err);
        }

        setSuccess(`${company.name} uspešno izbrisano`);
    };

    return (
        <>
            <div className="d-flex main-container">
                <div className="formContainer">
                    <Form onSubmit={handleNewCompany}>
                        <Form.Group id="companyName" className="mb-3">
                            <Form.Label>Naziv podjetja</Form.Label>
                            <Form.Control type="text" ref={companyNameRef} required/>
                        </Form.Group>
                        <Form.Group id="companyName" className="mb-3">
                            <Form.Label>Ime referenta</Form.Label>
                            <Form.Control type="text" ref={clerkName} required/>
                        </Form.Group>
                        <Form.Group id="companyName" className="mb-3">
                            <Form.Label>Priimek referenta</Form.Label>
                            <Form.Control type="text" ref={clerkLastname} required/>
                        </Form.Group>
                        <Form.Group id="companyName" className="mb-3">
                            <Form.Label>Email referenta</Form.Label>
                            <Form.Control type="email" ref={clerkEmail} required/>
                        </Form.Group>
                        <Button className="btn-primary" type="submit">Dodaj podjetje</Button>
                    </Form>
                </div>

                <div className="table-container">
                    <Table striped hover>
                        <thead>
                        <tr className="text-center">
                            <th>Št.</th>
                            <th>Naziv</th>
                            <th>Št. zaposlenih</th>
                            <th>Uredi</th>
                            <th>Izbriši</th>
                        </tr>
                        </thead>
                        <tbody>
                        {companies.map((company, index) => {
                            return (
                                <tr key={index} className="text-center">
                                    <td>{index + 1}</td>
                                    <td>{company.data().name}</td>
                                    <td>{company.data().employees.length}</td>
                                    <td>
                                        <Button variant="outline-dark" onClick={() => handleClick(company)}>
                                            {<FontAwesomeIcon icon={faPenToSquare}/>}
                                        </Button>
                                    </td>
                                    <td>
                                        <Button variant="outline-danger" onClick={() => handleDelete(company)}>
                                            {<FontAwesomeIcon icon={faXmark}/>}
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                </div>
            </div>

            <CompaniesModal show={show} onHide={() => setShow(false)} company={activeCompany} users={users}/>
        </>
    );
};

export default CompaniesTab;
