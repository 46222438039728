import React from "react";
import { collections, firestore } from "../../firebase";
import { updateDoc, doc } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";

export const types = {
    DEFAULT: "default",
    REVIEWED: "reviewed",
    SENT: "sent",
    FINISHED: "finished",
};

const FolderMenu = ({ folder }) => {
    const { firebaseUser } = useAuth();

    const handleTypeChange = async (type) => {
        const docRef = doc(firestore, collections.FOLDERS, firebaseUser.companyUid, collections.FOLDERS, folder.id);
        await updateDoc(docRef, { "type": type });
    };

    return (
        <>
            <p className="text-black-50 mb-2">Nastavi mapo na:</p>
            <li onClick={() => handleTypeChange(types.DEFAULT)}>Privzeto</li>
            <li onClick={() => handleTypeChange(types.REVIEWED)}>Pregledano</li>
            <li onClick={() => handleTypeChange(types.SENT)}>Poslano</li>
            <li onClick={() => handleTypeChange(types.FINISHED)}>Rešeno</li>
        </>
    );
};

export default FolderMenu;
