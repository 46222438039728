import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faCheck } from "@fortawesome/free-solid-svg-icons";
import {
    filesRef,
    firestore,
    getFirebaseDocs,
    companyCollection,
    companyCollectionNew,
    collections,
} from "../../firebase";
import { doc, getDoc, getDocs, query, where } from "firebase/firestore";
import getFileType from "../../utilities/pureFunctions/getFileType";
import { useStore } from "../../contexts/StoreFilesContext";
import bubbleSortObj from "../../utilities/pureFunctions/bubbleSort";
import getDateFromTimestamp from "../../utilities/pureFunctions/getDateFromTimestamp";
import SearchBar from "../SearchBar/SearchBar";
import { useSearch } from "../../contexts/SearchFilesContext";

import "../../style/sass/QuickMail/QuickMailAttachments/QuickMailAttachments.css";
import { useAuth } from "../../contexts/AuthContext";
import Loader from "../Loader";

const QuickMailAttachments = ({ show, hide, user, setAttachments }) => {
    // console.log("Attachments rerendered");
    const [files, setFiles] = useState([]);
    const [checkedFiles, setCheckedFiles] = useState([]);

    const [dateAsc, setDateAsc] = useState(false);
    const [nameAsc, setNameAsc] = useState(false);
    const [typeAsc, setTypeAsc] = useState(false);
    const [dirAsc, setDirAsc] = useState(false);
    const [loading, setLoading] = useState(true);

    const { storedFiles } = useStore();
    const { setDataToQuery, queriedData, originalData, resetSearchContext } = useSearch();
    const { firebaseUser } = useAuth();

    // getFirebaseDocs(() => console.log(files.length));

    // Get files from company
    const getFiles = async () => {
        const filesArray = [];
        const queryData = await query(companyCollectionNew(collections.FILES, firebaseUser.companyUid), where("companyUid", "==", firebaseUser.companyUid));
        const querySnapshot = await getDocs(queryData);
        querySnapshot.forEach((doc) => {
            filesArray.push(doc.data());
        });

        // Get folder name
        filesArray.map(async (file, index) => {
            // File directory
            if (file.folderId !== null) {
                const docRef = doc(firestore, collections.FOLDERS, file.companyUid, collections.FOLDERS, file.folderId);
                const docSnapshot = await getDoc(docRef);
                if (docSnapshot.exists())
                    filesArray[index].directoryName = docSnapshot.data().name;
            } else filesArray[index].directoryName = "Korenska mapa";
        });

        setFiles([...filesArray]);
        return setLoading(false);
    };

    useEffect(() => {
        // resetSearchContext();
        if (files.length === 0 && show)
            getFiles();

        return (() => {
            console.log("Returned from attachments modal");
        });
    }, [show]);

    // Add files to email body
    const handleConfirm = () => {
        setAttachments([...checkedFiles]);
        hide();
    };

    const handleClick = (event) => {
        const fileIndex = parseInt(event.target.id);
        const checked = event.target.checked;
        console.log(checked);

        // Add to checked array
        if (checked) {
            return setCheckedFiles([...checkedFiles, files[fileIndex]]);
        }

        // Remove from checked array
        return setCheckedFiles(checkedFiles.filter(file => file.name !== files[fileIndex].name));
    };

    // Get files selected in context menu
    useEffect(() => {
        if (storedFiles.length > 0) {
            setCheckedFiles(prevState => [...storedFiles]);
            handleConfirm();
        }
    }, [show]);

    // Get searched files
    // useEffect(() => {
    //     queriedData.length > 0 ? setFiles([...queriedData]) : setFiles([...originalData]);
    // }, [queriedData]);

    // Handle sort function
    const handleSort = (property, direction) => {
        switch (property) {
            case "createdAt":
                setDateAsc(prevState => !prevState);
                break;
            case "name":
                setNameAsc(prevState => !prevState);
                break;
            case "directoryName":
                setDirAsc(prevState => !prevState);
                break;
        }

        const unorderedFiles = [...files];
        const orderedFiles = bubbleSortObj(unorderedFiles, property, direction);
        setFiles([...orderedFiles]);
    };

    // Sort by type
    const sortByType = () => {
        setTypeAsc(!typeAsc);
        const unorderedFiles = [...files];

        let tmp = unorderedFiles.map((file, index) => {
            const typeParts = file.name.split(".");
            const type = typeParts[typeParts.length - 1];
            return ({ index: index, type: type, file: unorderedFiles[index] });
        });

        tmp = bubbleSortObj(tmp, "type", typeAsc);

        const orderedFiles = [];
        for (const file of tmp) {
            orderedFiles.push(file.file);
        }

        setFiles([...orderedFiles]);
    };


    return (
        <Modal show={show} onHide={hide} id="quick-mail-attachments-modal">
            <Modal.Header closeButton>
                <h4>Izbira datotek za prilogo</h4>
                <Button
                    variant="outline-dark" className="mx-4"
                    onClick={() => handleSort("createdAt", dateAsc)}
                >
                    Datum <FontAwesomeIcon icon={!dateAsc ? faArrowUp : faArrowDown}/>
                </Button>
                <Button
                    variant="outline-dark" className="me-4"
                    onClick={() => handleSort("name", nameAsc)}
                >
                    Ime <FontAwesomeIcon icon={nameAsc ? faArrowUp : faArrowDown}/>
                </Button>
                <Button
                    variant="outline-dark" className="me-4"
                    onClick={() => handleSort("directoryName", dirAsc)}
                >
                    Mapa <FontAwesomeIcon icon={dirAsc ? faArrowUp : faArrowDown}/>
                </Button>
                <Button
                    variant="outline-dark" className="me-4"
                    onClick={sortByType}
                >
                    Tip datoteke <FontAwesomeIcon icon={typeAsc ? faArrowUp : faArrowDown}/>
                </Button>
                <SearchBar stretchable={false}/>
            </Modal.Header>
            {loading ?
                <Loader/> :
                <Modal.Body className="p-0">
                    <Table bordered hover id="quick-mail-attachments-table" className="m-0">
                        <thead>
                        <tr>
                            <th>Št.</th>
                            <th>Ikona</th>
                            <th>Ime datoteke</th>
                            <th>Mapa</th>
                            {/*<th>Avtor</th>*/}
                            <th>Datum</th>
                            <th>Vključi</th>
                        </tr>
                        </thead>
                        <tbody>
                        {files.map((file, index) => {
                            // File data setup
                            const fileDate = getDateFromTimestamp(file.createdAt);

                            // File Author
                            const fileAuthor = {};

                            // File icon
                            const fileType = getFileType(file.name);

                            return (
                                <tr key={index}>
                                    <td>{index + 1}.</td>
                                    <td><img src={`/img/files/64/${fileType}.png`} style={{ width: "32px" }}
                                             alt={fileType + " icon"}/></td>
                                    <td>{file.name}</td>
                                    <td>{file.directoryName}</td>
                                    {/*<td>{fileAuthor}</td>*/}
                                    <td>{fileDate}</td>
                                    <td>
                                        <input type="checkbox" id={index.toString()} onChange={handleClick}
                                               checked={checkedFiles.includes(files[index])}/>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                </Modal.Body>}
            <Modal.Footer className="d-flex justify-content-between">
                <h5 className="text-black-50"><b>Trenutne datoteke:</b> {
                    checkedFiles.map((file) => {
                        return (file.name + ", ");
                    })
                }</h5>
                <Button variant="success" onClick={handleConfirm}>
                    Potrdi
                    <FontAwesomeIcon icon={faCheck} className="ms-2"/>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default QuickMailAttachments;