import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { addDoc, serverTimestamp, getDocs, where, query, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { storage, companyCollectionNew, collections } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { ROOT_FOLDER } from "../../hooks/useFolder";
import { v4 as uuid } from "uuid";
import { Toast, ProgressBar, Modal, Button, Form, Table } from "react-bootstrap";
import AddFileModal from "./AddFileModal";

export default function AddFileButton({ currentFolder }) {
	const [uploadingFiles, setUploadingFiles] = useState([]);
	const [show, setShow] = useState(false);
	const [filesToUpload, setFilesToUpload] = useState([]);

	const { currentUser, firebaseUser } = useAuth();

	const handleSelectFile = (event) => {
		setFilesToUpload([...event.target.files]);
	};

	// const handleUpload = (e, fileParameter = null) => {
	const handleUpload = (e, filesToUpload) => {
		e.preventDefault();

		for (const file of filesToUpload) {
			console.log("upload handler");
			// const file = fileParameter || e.target.files[0];
			if (currentFolder == null || file == null || firebaseUser == null) return;

			const id = uuid();
			setUploadingFiles((prevUploadingFiles) => [
				...prevUploadingFiles,
				{
					id: id,
					name: file.name,
					progress: 0,
					error: false,
				},
			]);

			/*        const filePath =
                    currentFolder === ROOT_FOLDER
                        ? `${currentFolder.path.map((x) => x.name).join("/")}/${file.name}`
                        : `${currentFolder.path.map((x) => x.name).join("/")}/${currentFolder.name}/${file.name}`;*/

			// Change for folderId instead of folder name
			const filePath =
				currentFolder === ROOT_FOLDER
					? `${currentFolder.path.map((x) => x.id).join("/")}/${file.name}`
					: `${currentFolder.path.map((x) => x.id).join("/")}/${currentFolder.id}/${file.name}`;

			// console.log(currentFolder.map((x) => x.id));
			console.log(`files/${firebaseUser.companyUid}/${filePath}`);

			// ref za storage bo ref(storage, `files/${currentUser.companyUid}/${filePath}`)
			const storageRef = ref(storage, `files/${firebaseUser.companyUid}/${filePath}`);
			// const storageRef = ref(storage, `files/${currentUser.uid}/${filePath}`);
			const uploadTask = uploadBytesResumable(storageRef, file);

			uploadTask.on(
				"state_changed",
				(snapshot) => {
					const progress = snapshot.bytesTransferred / snapshot.totalBytes;
					setUploadingFiles((prevUploadingFiles) => {
						return prevUploadingFiles.map((uploadFile) => {
							if (uploadFile.id === id) {
								return { ...uploadFile, progress: progress };
							}

							return uploadFile;
						});
					});
				},
				(err) => {
					console.error(err);
					setUploadingFiles((prevUploadingFiles) => {
						return prevUploadingFiles.map((uploadFile) => {
							if (uploadFile.id === id) {
								return { ...uploadFile, error: true };
							}
							return uploadFile;
						});
					});
				},
				() => {
					setUploadingFiles((prevUploadingFiles) => {
						return prevUploadingFiles.filter((uploadFile) => {
							return uploadFile.id !== id;
						});
					});

					getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
						const queryData = await query(
							companyCollectionNew(collections.FILES, firebaseUser.companyUid),
							where("name", "==", file.name),
							where("companyUid", "==", firebaseUser.companyUid),
							where("folderId", "==", currentFolder.id),
						);

						const querySnapshot = await getDocs(queryData);
						const existingFile = querySnapshot.docs[0];
						console.log("Existing file:", existingFile);

						if (existingFile)
							try {
								console.log("Updating file:", existingFile.id);
								await updateDoc(doc(companyCollectionNew(collections.FILES, firebaseUser.companyUid), existingFile.id), {
									url: downloadURL,
									lastChange: serverTimestamp(),
								});
							} catch (err) {
								console.info("There is no file with that name yet");
							}
						else
							try {
								console.log("Creating new file");
								await addDoc(companyCollectionNew(collections.FILES, firebaseUser.companyUid), {
									url: downloadURL,
									name: file.name,
									createdAt: serverTimestamp(),
									lastChange: serverTimestamp(),
									folderId: currentFolder.id,
									userId: currentUser.uid,
									companyUid: firebaseUser.companyUid,
								});
							} catch (err) {
								console.error(err);
							}
					});
				},
			);
		}

		setShow(false);
		setFilesToUpload([]);
	};

	const onFileChange = (files) => {
		console.log(files);
	};

	return (
		<>
			{/*<label className="btn btn-outline-success btn-md me-2 d-flex justify-content-center align-items-center">*/}
			{/*    <FontAwesomeIcon icon={faFileUpload} className="me-2 fs-5"/>*/}
			{/*    <p style={{ margin: "0" }}>Dodaj datoteko</p>*/}
			{/*    <input type="file" onChange={handleUpload} multiple="multiple"*/}
			{/*           style={{ opacity: 0, position: "absolute", left: "-9999px" }}/>*/}
			{/*</label>*/}

			<Button variant="outline-success" onClick={() => setShow(true)}
							className="me-2 d-flex justify-content-center align-items-center"
							disabled={!firebaseUser.permission.canCreate}>
				<FontAwesomeIcon icon={faFileUpload} className="me-2 fs-5"/>
				Dodaj datoteko
			</Button>

			{/*<Modal show={show} onHide={() => setShow(false)}>*/}
			{/*    <Modal.Header><h3>Dodajanje datotek</h3></Modal.Header>*/}
			{/*    <Form onSubmit={handleUpload}>*/}
			{/*        <Modal.Body>*/}
			{/*            /!* Manual upload *!/*/}
			{/*            <label*/}
			{/*                className="btn btn-outline-success btn-md me-2 d-flex justify-content-center align-items-center">*/}
			{/*                <FontAwesomeIcon icon={faFileUpload} className="me-2 fs-5"/>*/}
			{/*                <p style={{ margin: "0" }}>Izberi datoteko</p>*/}
			{/*                /!* onChange should update array of files to upload *!/*/}
			{/*                /!*<input type="file" onChange={handleUpload}*!/*/}
			{/*                <input type="file" onChange={handleSelectFile}*/}
			{/*                       style={{ opacity: 0, position: "absolute", left: "-9999px" }}/>*/}
			{/*            </label>*/}

			{/*            /!*  Drag 'n' drop upload  *!/*/}

			{/*            /!* Table of files to upload *!/*/}
			{/*            <Table hover>*/}
			{/*                <tbody>*/}
			{/*                {filesToUpload.map((file, index) => {*/}
			{/*                    return <tr key={index}>*/}
			{/*                        <td>{file.name}</td>*/}
			{/*                    </tr>;*/}
			{/*                })}*/}
			{/*                </tbody>*/}
			{/*            </Table>*/}
			{/*        </Modal.Body>*/}
			{/*        <Modal.Footer>*/}
			{/*            <Button variant="secondary" onClick={() => setShow(false)}>*/}
			{/*                Zapri*/}
			{/*            </Button>*/}
			{/*            <Button variant="success" type="submit">*/}
			{/*                Dodaj datoteko*/}
			{/*            </Button>*/}
			{/*        </Modal.Footer>*/}
			{/*    </Form>*/}
			{/*</Modal>*/}

			<AddFileModal
				show={show}
				onHide={() => setShow(false)}
				handleUpload={handleUpload}
				filesToUpload={filesToUpload}
				setFilesToUpload={setFilesToUpload}
				onFileChange={(files) => onFileChange(files)}
			/>

			{uploadingFiles.length > 0 &&
				ReactDOM.createPortal(
					<div style={{ position: "absolute", bottom: "1rem", right: "1rem", maxWidth: "250px" }}>
						{uploadingFiles.map((file) => (
							<Toast
								key={file.id}
								onClose={() => {
									setUploadingFiles((prevUploadingFiles) => {
										return prevUploadingFiles.filter((uploadFile) => {
											return uploadFile.id !== file.id;
										});
									});
								}}
							>
								<Toast.Header closeButton={file.error} className="text-truncate w-100 d-block">
									{file.name}
								</Toast.Header>
								<Toast.Body>
									<ProgressBar
										animated={!file.error}
										variant={file.error ? "danger" : "primary"}
										now={file.error ? 100 : file.progress * 100}
										label={file.error ? "Error" : `${Math.round(file.progress * 100)}%`}
									/>
								</Toast.Body>
							</Toast>
						))}
					</div>,
					document.body,
				)}
		</>
	);
}
