import React, { useState, useEffect } from 'react';
import { Modal, Accordion, Button } from 'react-bootstrap';
import Loader from '../Loader';
import { query, where, getDocs } from 'firebase/firestore';
import { collections, companyCollectionNew, quickMailRef } from '../../firebase';
import parser from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import bubbleSortObj from '../../utilities/pureFunctions/bubbleSort';
import SearchBar from '../SearchBar/SearchBar';
import getDateFromTimestamp from '../../utilities/pureFunctions/getDateFromTimestamp';
import { useSearch } from '../../contexts/SearchFilesContext';

import '../../style/sass/QuickMail/QuickMailHistory/QuickMailHistory.css';
import { useAuth } from '../../contexts/AuthContext';

const QuickMailHistory = ({ show, hide, userUid }) => {
	const [mails, setMails] = useState([]);
	const [loading, setLoading] = useState(true);
	const [toggleView, setToggleView] = useState(true);
	const [dateAsc, setDateAsc] = useState(false);
	const [targetAsc, setTargetAsc] = useState(false);

	const { setDataToQuery, queriedData, originalData, resetSearchContext } = useSearch();
	const { currentUser } = useAuth();

	// Load mails
	const loadMails = async () => {
		const mailsArray = [];
		const queryData = await query(companyCollectionNew(collections.QUICK_MAIL, currentUser.uid), where('uid', '==', currentUser.uid));
		const querySnapshot = await getDocs(queryData);
		querySnapshot.forEach((doc) => {
			// setMails(prevState => [...prevState, doc.data()]);
			mailsArray.push(doc.data());
		});

		setDataToQuery(mailsArray);
		setMails([...mailsArray]);
		setLoading(false);
	};

	useEffect(() => {
		// Later check if it is already stored in sessionStorage and if not load them and save them
		/*if (show) */
		loadMails();

		// return () => resetSearchContext();
	}, []);

	// Get searched mails
	useEffect(() => {
		queriedData.length > 0 ? setMails([...queriedData]) : setMails([...originalData]);
	}, [queriedData]);

	// Sort mails by date
	const sortDate = () => {
		setDateAsc((prevState) => !prevState);
		const unorderedMails = [...mails];
		const orderedMails = bubbleSortObj(unorderedMails, 'timestamp', dateAsc);
		setMails(orderedMails);
	};

	// Sort mails by target (receiver)
	const sortTarget = () => {
		setTargetAsc((prevState) => !prevState);
		const unorderedMails = [...mails];
		const orderedMails = bubbleSortObj(unorderedMails, 'target', targetAsc);
		setMails(orderedMails);
	};

	return (
		<>
			<Modal centered show={show} onHide={hide} className='modal-history'>
				{loading && <Loader />}
				<Modal.Header closeButton>
					<h4>Zgodovina e-pošte</h4>
					<div className='d-flex justify-content-evenly align-items-center'>
						<Button onClick={() => setToggleView((prevState) => !prevState)} className='ms-4' style={{ borderRadius: '20px' }}>
							{toggleView ? 'HTML pogled' : 'Navadni pogled'}
						</Button>
						<Button onClick={sortDate} className='ms-4' style={{ borderRadius: '20px' }} variant='outline-dark'>
							Datum
							<FontAwesomeIcon icon={dateAsc ? faArrowUp : faArrowDown} className='ms-2' />
						</Button>
						<Button onClick={sortTarget} className='mx-4' style={{ borderRadius: '20px' }} variant='outline-dark'>
							Naslovnik
							<FontAwesomeIcon icon={targetAsc ? faArrowUp : faArrowDown} className='ms-2' />
						</Button>
						<SearchBar stretchable={true} placeholder='Išči po naslovniku' />
					</div>
				</Modal.Header>
				<Modal.Body className='p-0'>
					<Accordion>
						{mails.length < 1 ? (
							<h3>Poslali še niste nobene e-pošte.</h3>
						) : (
							mails.map((mail, index) => {
								const mailDate = getDateFromTimestamp(mail.timestamp);

								switch (mail.target) {
									case 'default':
										mail.target = 'jkranjec@t-2.net';
										break;
									case 'technicalSupport':
										mail.target = 'Tehnična podpora';
										break;
								}

								return (
									<Accordion.Item eventKey={index.toString()} key={index}>
										<Accordion.Header>
											<h4>
												{index + 1}. &nbsp;Poslano: <b>{mail.target}</b>&nbsp;&nbsp;Datum:&nbsp;
												<b>{mailDate}</b>
											</h4>
										</Accordion.Header>
										<Accordion.Body>
											<b>Za:</b>&nbsp;{mail.target}
											<br />
											<b>Zadeva:</b>&nbsp;{mail.subject}
											<br />
											<br />
											{toggleView && parser(mail.text)}
											{!toggleView && mail.text}
										</Accordion.Body>
									</Accordion.Item>
								);
							})
						)}
					</Accordion>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default QuickMailHistory;
