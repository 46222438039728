import React, { useEffect } from "react";
import { gsap } from "gsap";

const BusinessLeader = ({ element }) => {
	useEffect(() => {
		// Rocket
		gsap.to(element.querySelector("div:nth-of-type(3) > svg g:first-of-type"),
			{
				y: -100,
				scrollTrigger: {
					trigger: element,
					start: "center center",
					end: "bottom top",
					scrub: true,
				},
			},
		);
	}, []);

	return (
		<svg width="400" height="317" viewBox="0 0 400 317" fill="none" xmlns="http://www.w3.org/2000/svg"
				 id="business-leader">
			<path opacity="0.1"
						d="M342.66 303.427C344.7 303.19 419.342 249.546 383.74 193.579C348.137 137.612 270.561 146.637 282.306 122.254C294.05 97.8707 270.44 -19.1454 177.491 2.70388C84.5428 24.5532 74.922 89.7993 115.295 131.095C155.668 172.39 96.1806 219.318 61.2642 178.807C26.3478 138.296 -22.2649 200.905 11.2852 229.212C44.8352 257.52 16.0763 341.111 342.66 303.427Z"
						fill="#1059C8"/>
			<g>
				<path
					d="M295.526 234.907C295.526 234.907 289.256 251.446 287.887 257.908C286.517 264.369 286.55 270.691 286.55 270.691L274.82 268.092C274.82 268.092 277.556 233.3 280.156 230.849C282.752 228.398 295.369 229.425 295.369 229.425L295.526 234.907Z"
					fill="#0E418C"/>
				<path
					d="M308.994 226.187C308.994 226.187 311.165 253.314 312.534 257.908C313.904 262.501 326.322 280.402 326.322 280.402L313.058 277.01C313.058 277.01 299.022 262.78 293.041 232.794C288.824 211.628 303.399 217.127 308.994 226.187Z"
					fill="#0E418C"/>
				<path
					d="M282.368 188.331C282.368 188.331 267.418 186.452 260.145 183.211C252.873 179.967 230.946 166.512 230.946 166.512L234.509 162.193C234.509 162.193 255.026 172.787 259.063 174.912C263.1 177.037 282.918 180.204 282.918 180.204L282.368 188.331Z"
					fill="#CADFFF"/>
				<path
					d="M306.56 193.624C307.09 206.819 308.447 225.708 308.994 226.187C308.994 226.187 302.435 231.468 296.919 232.359C291.406 233.25 279.135 233.321 279.135 233.321C279.135 233.321 282.88 207.872 283.004 203.717C283.128 199.561 281.534 184.214 281.534 184.214C308.994 168.063 306.543 193.174 306.56 193.624Z"
					fill="#5598FF"/>
				<path
					d="M291.663 176.3L291.699 178.934C291.725 180.87 293.352 182.397 295.286 182.299L295.833 182.273C297.703 182.178 299.149 180.592 299.069 178.718L298.957 176.022C298.868 173.941 296.957 172.423 294.911 172.81L294.44 172.899C292.814 173.207 291.643 174.642 291.663 176.3Z"
					fill="#B1CFFC"/>
				<path
					d="M286.006 160.568C286.006 160.568 282.814 169.622 285.627 174.734C288.44 179.846 298.815 177.951 300.586 170.845C304.484 155.199 290.483 155.907 286.006 160.568Z"
					fill="#CADFFF"/>
				<path
					d="M293.006 190.22C293.006 190.22 283.161 192.499 272.144 187.849L274.3 178.25L291.699 178.937C291.699 178.934 292.29 184.087 293.006 190.22Z"
					fill="#5598FF"/>
				<path
					d="M304.632 181.379C304.153 181.867 310.651 203.045 302.251 210.406C297.62 214.464 275.832 220.238 275.832 220.238C275.64 220.496 274.3 220.883 272.948 221.215C270.854 221.73 268.645 221.067 267.175 219.489C267.066 219.371 266.965 219.261 266.876 219.164C266.741 219.013 266.64 218.835 266.58 218.641C266.52 218.447 266.503 218.243 266.53 218.042C266.593 217.574 266.8 217.138 267.123 216.794C267.446 216.451 267.869 216.217 268.332 216.127C268.737 216.047 269.151 215.985 269.485 215.973C271.502 215.908 273.096 216.731 275.089 216.66L276.343 216.615C276.343 216.615 293.923 209.399 296.271 207.582C303.396 202.065 299.995 192.466 300.619 188.571L304.632 181.379Z"
					fill="#B1CFFC"/>
				<path
					d="M279.978 217.382C279.978 217.382 276.172 215.076 273.77 214.949C272.682 214.893 270.857 214.262 270.286 213.694C269.716 213.123 269.26 215.334 270.23 215.677C271.2 216.02 273.605 216.562 273.058 217.782L279.978 217.382Z"
					fill="#B1CFFC"/>
				<path
					d="M300.314 179.417C300.314 179.417 308.994 178.807 309.882 198.336L298.826 201.384C298.826 201.384 290.489 189.874 300.314 179.417Z"
					fill="#5598FF"/>
				<path
					d="M231.306 167.305C231.306 167.305 233.25 167.527 236.281 167.228C239.312 166.926 237.692 165.772 237.692 165.772L235.885 163.351C235.885 163.351 234.296 159.447 232.3 158.218C231.833 157.931 231.655 157.689 231.312 157.283C231.002 156.916 227.326 156.522 230.585 159.047C231.44 159.71 231.321 160.261 230.815 160.267C230.31 160.272 226.832 157.304 225.593 158.026C222.162 160.033 224.291 164.381 226.066 165.254C227.79 166.097 231.306 167.305 231.306 167.305Z"
					fill="#CADFFF"/>
				<path
					d="M302.485 158.133C302.39 158.349 302.092 158.372 301.976 158.168C301.048 156.511 297.868 151.754 292.548 152.971C286.976 154.246 284.207 158.239 282.738 157.47C282.51 157.348 282.247 157.547 282.321 157.792C282.584 158.671 283.258 159.757 284.994 159.506C285.154 159.482 285.302 159.607 285.311 159.769C285.349 160.456 285.222 162.013 283.288 161.9C283.054 161.886 282.897 162.137 283.021 162.335C283.376 162.901 284.128 163.673 285.39 163.114C285.464 163.081 285.521 163.016 285.547 162.939C285.731 162.395 286.665 160.145 289.43 160.352C289.534 160.361 289.626 160.432 289.67 160.527C289.847 160.924 290.581 161.971 293.464 162.51C296.839 163.141 297.806 165.645 296.31 167.367C296.165 167.533 296.25 167.796 296.466 167.835C297.176 167.953 298.477 167.927 299.208 166.423C300.246 164.283 301.834 166.361 301.104 167.586C300.435 168.702 300.631 172.212 298.954 174.024C298.782 174.207 298.933 174.509 299.184 174.494C300.069 174.435 301.5 174.16 302.269 173.029C303.431 171.316 306.67 168.933 305.629 164.224C304.671 159.876 303.795 158.186 305.383 157.088C305.576 156.955 305.552 156.662 305.333 156.582C304.623 156.327 303.334 156.2 302.485 158.133Z"
					fill="#003584"/>
				<path
					d="M182.034 127.096C182.034 127.096 193.421 142.922 199.291 148.303C204.384 152.974 217.714 159.287 222.141 161.634L223.2 162.193L225.057 158.538C225.057 158.538 207.173 146 203.615 143.138C200.06 140.279 189.795 124.699 189.795 124.699L182.034 127.096ZM173.457 122.073C172.875 121.715 147.748 125.755 142.081 123.352C136.415 120.948 123.872 102.512 123.872 102.512L119.992 100.156C118.447 99.2181 117.2 97.8613 116.395 96.2429L115.573 94.5913C114.701 92.8361 115.981 90.7761 117.942 90.785L121.541 91.8861C122.922 92.3081 124.173 93.0747 125.176 94.1139C126.18 95.1531 126.902 96.4307 127.276 97.8264L127.601 99.0399C127.601 99.0399 142.522 114.887 145.054 116.438C147.582 117.989 159.945 114.327 163.875 114.061L173.457 122.073Z"
					fill="#5980B9"/>
				<path
					d="M137.778 177.872C137.778 177.872 163.964 175.551 175.818 153.178L160.311 154.261C160.311 154.261 130.301 170.931 128.018 174.124C125.735 177.318 132.126 217.35 132.126 217.35H138.402L137.778 177.872Z"
					fill="#0E418C"/>
				<path
					d="M160.311 154.261C160.311 154.261 163.736 194.281 171.494 209.583C179.254 224.885 189.067 246.356 189.067 246.356L196.695 244.299L179.71 199.531C179.71 199.531 177.681 178.061 178.113 175.32C178.544 172.579 182.611 161.625 178.503 153.175L160.311 154.261Z"
					fill="#0E418C"/>
				<path
					d="M152.039 116.035L152.923 125.575C152.923 125.575 160.172 126.581 160.084 127.022C157.404 139.953 152.983 158.366 152.364 158.748C152.364 158.748 159.356 159.382 164.65 161.163C169.944 162.945 180.056 168.066 180.056 168.066C180.056 168.066 181.117 144.953 181.676 140.835C182.232 136.715 186.311 121.836 186.311 121.836C172.351 108.118 152.039 116.035 152.039 116.035Z"
					fill="#5598FF"/>
				<path
					d="M177.607 112.365L177.142 114.958C176.799 116.864 174.945 118.104 173.055 117.69L172.52 117.571C170.689 117.172 169.524 115.369 169.908 113.534L171.269 107.026L175.424 108.55C176.98 109.127 177.9 110.734 177.607 112.365Z"
					fill="#5275A8"/>
				<path
					d="M186.728 99.4957C186.728 99.4957 185.728 110.169 181.378 114.055C177.027 117.941 168.258 111.382 168.941 104.092C170.456 88.0354 184.048 93.6087 186.728 99.4957Z"
					fill="#5980B9"/>
				<path
					d="M179 116.503C179 116.503 188.266 120.54 194.343 130.843L186.896 137.266L178.5 129.215C178.503 129.215 175.924 121.851 179 116.503Z"
					fill="#5598FF"/>
				<path
					d="M24.1474 316.249C24.1474 316.249 67.8506 222.073 119.314 222.141C178.92 222.218 136.089 254.687 195.997 256.401C255.904 258.115 374.326 293.674 374.326 293.674L400 316.246H24.1474V316.249Z"
					fill="white"/>
				<path
					d="M133.324 217.347L132.883 217.604C130.399 219.054 127.699 220.081 124.907 220.765C119.134 222.174 118.578 225.001 118.578 225.001C118.362 225.391 120.16 226.492 120.604 226.457L130.035 224.403C131.649 224.051 133.306 223.947 134.951 224.095L137.607 224.332C138.84 224.308 139.739 223.157 139.461 221.952L138.402 217.347H133.324ZM189.067 246.353C189.067 246.353 186.899 254.261 180.907 253.749C180.907 253.749 177.604 259.033 186.556 257.689C189.981 257.174 190.703 252.722 197.854 253.835C199.83 254.143 200.936 251.781 200.936 251.781C201.681 249.221 199.702 247.738 197.946 245.731L196.695 244.299L189.067 246.353ZM108.546 223.675L105.541 223.453L124.315 45.6953L127.317 45.9172L108.546 223.675Z"
					fill="#003584"/>
				<path
					d="M124.28 47.4652C124.28 47.4652 100.502 29.3423 90.934 41.4213C78.3054 57.3628 69.77 51.3337 65.3663 46.4796C65.3663 46.4796 69.2347 56.1523 52.9625 71.5078C52.9625 71.5078 74.4813 87.251 86.4888 77.5428C98.4934 67.8347 118.726 78.7031 121.186 86.221L124.28 47.4652Z"
					fill="#5598FF"/>
				<path
					d="M223.448 163.078C223.448 163.078 221.899 161.886 219.929 159.556C217.962 157.23 219.929 157.526 219.929 157.526L222.91 157.043C222.91 157.043 226.764 155.344 229.053 155.853C229.588 155.972 229.884 155.922 230.413 155.868C230.889 155.821 233.806 158.097 229.709 157.641C228.636 157.523 228.337 157.999 228.695 158.357C229.053 158.716 233.616 159.006 234.004 160.388C235.071 164.218 230.517 165.858 228.636 165.248C226.811 164.659 223.448 163.078 223.448 163.078Z"
					fill="#5980B9"/>
				<path
					d="M172.887 96.4442C172.887 96.4442 174.575 101.636 180.567 100.952C186.559 100.265 185.826 104.741 185.826 104.741C185.826 104.741 190.868 100.218 186.84 96.4472C182.809 92.6734 181.28 87.766 172.887 91.9334C164.493 96.1009 166.496 103.927 168.773 107.064L169.488 107.783C169.935 108.23 170.68 108.144 171.009 107.606L171.012 107.6C171.207 107.28 171.201 106.88 171 106.564C170.414 105.637 169.37 103.634 170.361 102.461C170.967 101.745 171.437 101.896 171.772 102.269C172.319 102.885 173.171 103.124 173.931 102.805L173.99 102.781C173.99 102.778 176.829 99.8746 172.887 96.4442Z"
					fill="#003584"/>
			</g>
		</svg>
	);
};

export default BusinessLeader;
