import React, { useMemo, useEffect, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import getDateFromTimestamp from "../../utilities/pureFunctions/getDateFromTimestamp";
import { collections, firestore, getFirebaseDocs, usersRef } from "../../firebase";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import "../../style/sass/Drive/ListView/ListFolder.css";
import { types } from "../ContextMenu/FolderMenu";
import { doc, updateDoc } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";

const ListFolder = ({ folder }) => {
    const [author, setAuthor] = useState("");
    const { firebaseUser } = useAuth();

    useCallback(async () => {
        await getFirebaseDocs(usersRef, "userId", folder.userId, (user) => {
            console.log(user);
            setAuthor(user.name + " " + user.lastName);
        });
    }, [folder]);

    const handleTypeChange = async (type) => {
        if (type !== folder.type) {
            const docRef = doc(firestore, collections.FOLDERS, firebaseUser.companyUid, collections.FOLDERS, folder.id);
            await updateDoc(docRef, { "type": type });
        }
    };

    folder.creationDate = getDateFromTimestamp(folder.createdAt);
    if ("lastChange" in folder)
        folder.lastChangeDate = getDateFromTimestamp(folder.lastChangeDate);

    return (
        <div className="folder-row px-4 py-1 mb-2 d-flex">
            <Link to={{ pathname: `/folder/${folder.id}`, state: { folder: folder } }}
                  className="w-100 d-flex justify-content-between align-items-center">
                <img src={`/img/folders/64/${folder.type}Folder.png`} alt="file icon"
                     style={{ width: "32px", marginRight: "1rem" }}/>
                <div className="w-100 d-flex justify-content-between align-items-center">
                    <h5>{folder.name}</h5>
                    <h5>{folder.creationDate}</h5>
                    <h5>{author}</h5>
                    {"lastChange" in folder && <h5>{folder.lastChangeDate}</h5>}
                </div>

            </Link>
            <Form>
                <Form.Group>
                    <Form.Select onChange={(event) => handleTypeChange(event.target.value)}>
                        <option value={types.DEFAULT}>Privzeto</option>
                        <option value={types.REVIEWED}>Pregledano</option>
                        <option value={types.SENT}>Poslano</option>
                        <option value={types.FINISHED}>Rešeno</option>
                    </Form.Select>
                </Form.Group>
            </Form>
        </div>
    );
};

export default ListFolder;
