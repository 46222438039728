import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import Leader from "./Leader";

const Header = () => {
	const [loaded, setLoaded] = useState(false);
	const heroRef = useRef(null);

	useEffect(() => {
		setLoaded(true);
	}, []);


	useEffect(() => {
		const element = heroRef.current;

		gsap.fromTo(element.querySelector("div > h1"),
			{ opacity: 0, y: -50 },
			{ opacity: 1, y: 0, delay: .5 },
		);
		gsap.to(element.querySelector("div > h1"),
			{
				y: -50,
				scrollTrigger: {
					trigger: element.querySelector("div > h1"),
					start: "center 25%",
					end: "bottom top",
					scrub: true,
					immediateRender: false,
				},
			},
		);

		gsap.fromTo(element.querySelector("div > h4"),
			{ opacity: 0, y: -50 },
			{ opacity: 1, y: 0, delay: .7 },
		);
		gsap.to(element.querySelector("div > h4"),
			{
				y: -60,
				scrollTrigger: {
					trigger: element.querySelector("div > h1"),
					start: "center 25%",
					end: "bottom top",
					scrub: true,
					immediateRender: false,
				},
			},
		);

		gsap.fromTo(element.querySelector("div > a"),
			{ opacity: 0, y: -50 },
			{ opacity: 1, y: 0, delay: .8 },
		);
		gsap.to(element.querySelector("div > a"),
			{
				y: -70,
				scrollTrigger: {
					trigger: element.querySelector("div > h1"),
					start: "center 25%",
					end: "bottom top",
					scrub: true,
					immediateRender: false,
				},
			},
		);

	}, []);

	return (
		<section id="hero-section" ref={heroRef}>
			<div className="text-section">
				<h1>Vaša izbira za zavarovanja</h1>
				<h4>
					Znanje, strokovnost in zaupanje naj bodo merilo za izbiro neodvisnega posrednika, ki bo urejal vsa vaša
					zavarovanja. Neodvisni od zavarovalnic vam na osnovi strokovnih in objektivnih argumentov pomagamo pri izbiri
					najprimernejše zavarovalnice za vaša zavarovalna tveganja.
				</h4>
				<a href={"#scroll-section-anchor"} className="primary-button">Razišči</a>
			</div>
			{loaded && <Leader element={heroRef.current}/>}
		</section>
	);
};

export default Header;
