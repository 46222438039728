import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
	collection,
	getFirestore,
	doc,
	getDoc,
	query,
	getDocs,
	setDoc,
	collectionGroup,
	where,
	deleteDoc,
	updateDoc, onSnapshot,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

export const firebaseConfig = {
	// apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	// authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	// projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	// storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	// messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	// appId: process.env.REACT_APP_FIREBASE_APP_ID,

	apiKey: "AIzaSyCttAaM2Kgdho3o_shUwMLWPS2qn3dnfKU",
	authDomain: "jokra-doo.firebaseapp.com",
	projectId: "jokra-doo",
	storageBucket: "jokra-doo.appspot.com",
	messagingSenderId: "214152749067",
	appId: "1:214152749067:web:0fc404797b9fa1ca74670e",
};

// Initialize app
export const firebaseApp = initializeApp(firebaseConfig);

// Authentication
export const auth = getAuth();

// Firebase static references
export const firestore = getFirestore();
export const foldersRef = collection(firestore, "folders");
export const filesRef = collection(firestore, "files");
export const companiesRef = collection(firestore, "companies");
export const usersRef = collection(firestore, "users");
export const quickMailRef = collection(firestore, "quickMail");
export const testRef = collection(firestore, "files", "AIzZh7m908k6U9O8J940", "files");
export const testRef2 = collection(firestore, "files", "O0KwSgBVrgT4KHm8Kus1", "files");

// Firebase dynamic references
export const collections = {
	FILES: "files",
	FOLDERS: "folders",
	USERS: "users",
	QUICK_MAIL: "quickMail",
	COMPANIES: "companies",
};

export const companyCollectionNew = (firebaseCollection, uid) => {
	return collection(firestore, firebaseCollection, uid, firebaseCollection);
};

// Storage
export const storage = getStorage();

// Functions
export const functions = getFunctions(firebaseApp);

// Analytics
export const analytics = getAnalytics(firebaseApp);

// Formatting
export const formatDoc = (doc) => {
	return { id: doc.id, ...doc.data() };
};

// Getting single doc
export const getFirebaseDoc = async (collection, key) => {
	const docRef = doc(firestore, collection, key);
	const docSnapshot = await getDoc(docRef);
	return docSnapshot.exists() ? formatDoc(docSnapshot) : null;
};

// Getting multiple docs
export const getFirebaseDocs = async (firebaseCollection, key, value, callback) => {
	const queryData = await query(firebaseCollection, where(key, "==", value));
	const querySnapshot = await getDocs(queryData);
	querySnapshot.forEach((doc) => callback(doc));

	// return querySnapshot.map(doc => formatDoc(doc));
};

// Get whole collection
export const getFirebaseCollection = async (firebaseCollection) => {
	return await getDocs(collection(firestore, firebaseCollection));
};

// Get whole collection snapshot
export const getSnapshotCollection = async (firebaseCollection, callback) => {
	const queryData = await query(firebaseCollection);
	return onSnapshot(queryData, snapshot => callback(snapshot));
};

// Get docs with Snapshot
export const getSnapshotDocs = async (firebaseCollection, key, value, callback) => {
	const queryData = await query(firebaseCollection, where(key, "==", value));
	return onSnapshot(queryData, (snapshot) => callback(snapshot));
};

// Update single doc
export const updateFirebaseDoc = async (firebaseCollection, docUid, value) => {
	try {
		const docRef = doc(firestore, firebaseCollection, docUid);
		return await updateDoc(docRef, value);
	} catch (err) {
		console.error(err);
	}
};

// Update multiple docs
export const updateDocs = (firebaseCollection, key, value) => {

};

// Delete single doc
export const deleteFirebaseDoc = async (firebaseCollection, id) => {
	await deleteDoc(doc(firestore, firebaseCollection, id));
};

// Delete multiple docs
export const deleteFirebaseDocs = async (firebaseCollection, doc) => {
};


const moveFiles = async () => {
	const queryData = await query(quickMailRef, where("uid", "==", "F0k6k6ALZROSQArnAMgP51HtbyW2"));
	const querySnapshot = await getDocs(queryData);

	let counter = 0;
	// querySnapshot.forEach((doc) => console.log(doc.data()));
	// querySnapshot.forEach((doc) => counter++);
	querySnapshot.forEach(async (document) => {
		await setDoc(doc(firestore, "quickMail", "F0k6k6ALZROSQArnAMgP51HtbyW2", "quickMail", document.id), document.data());
	});
};

// moveFiles();

const assignPermissions = async () => {
	const queryData = await query(collection(firestore, "folders", "AIzZh7m908k6U9O8J940", "folders"));
	const querySnapshot = await getDocs(queryData);

	console.log(querySnapshot);

	let counter = 0;
	// querySnapshot.forEach((doc) => console.log(doc.data()));
	// querySnapshot.forEach((doc) => counter++);
	querySnapshot.forEach(async (document) => {
		console.log(document.data());
		await setDoc(doc(firestore, "folders", `${document.data().companyUid}`, "folders", document.id), {
			...document.data(),
			permissions: ["admin", "moderator", "client"],
		});
	});
};

// assignPermissions();