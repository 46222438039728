import React, { useEffect, useReducer, useState, useCallback } from "react";
import {
	collections,
	companyCollectionNew,
	filesRef,
	firestore,
	foldersRef,
	formatDoc,
	getFirebaseCollection,
	testRef2,
} from "../firebase";
import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { /*database,*/ graph, filesGraph, InitDatabase } from "../data/LocalDatabse";
import { getDatabase } from "../data/LocalDatabse";
import { useDatabase } from "./useDatabase";
import Loader from "../components/Loader";

const ACTIONS = {
	SELECT_FOLDER: "select-folder",
	UPDATE_FOLDER: "update-folder",
	SET_CHILD_FOLDERS: "set-child-folders",
	SET_CHILD_FILES: "set-child-files",
};

export const ROOT_FOLDER = {
	name: "Root",
	id: null,
	path: [],
};

function reducer(state, { type, payload }) {
	switch (type) {
		case ACTIONS.SELECT_FOLDER:
			return {
				folderId: payload.folderId,
				folder: payload.folder,
				childFolders: [],
				childFiles: [],
			};
		case ACTIONS.UPDATE_FOLDER:
			return {
				...state,
				folder: payload.folder,
			};
		case ACTIONS.SET_CHILD_FOLDERS:
			return {
				...state,
				childFolders: payload.childFolders,
			};
		case ACTIONS.SET_CHILD_FILES:
			return {
				...state,
				childFiles: payload.childFiles,
			};
		default:
			return state;
	}
}

export const useFolder = (folderId = null, folder = null) => {
	const [state, dispatch] = useReducer(reducer, { folderId, folder, childFolders: [], childFiles: [] });

	const [loaded, setLoaded] = useState(false);
	// let database = useDatabase("/folder", () => setLoaded(true));

	// const proba = async () => {
	// for (let i = 0; i < 10000; i++) {
	// }
	//
	// console.log("Loading done");
	// setLoaded(true);
	// };
	// if (!loaded) proba();

	// const [loading, setLoading] = useState(true);
	console.log("useFolder hook");
	// console.log(database);
	// console.log("Starting state:", state);

	// currentUser state
	const { currentUser, firebaseUser } = useAuth();

	// const getData = async () => {
	//     // const queryData = query(collection(firestore, "files", "AIzZh7m908k6U9O8J940 ", "files"));
	//     const data = await getDocs(collection(firestore, "files", "AIzZh7m908k6U9O8J940", "files"));
	//     data.forEach(doc => console.log(doc.data()));
	// };
	//
	// useEffect(() => {
	//     getData();
	//     //     // console.log(database.loaded);
	//     //     // if (!database.loaded)
	//     //     //     InitDatabase(firebaseUser)
	//     //     //         .then(() => console.log("Database ready"));
	//     //
	//     //     setDatabase(getDatabase());
	// }, []);

	useEffect(() => {
		console.log("useFolder SELECT_FOLDER");
		// if (database.loaded)
		dispatch({ type: ACTIONS.SELECT_FOLDER, payload: { folderId, folder } });
	}, [folderId, folder, loaded]);

	useEffect(async () => {
		console.log("useFolder UPDATE_FOLDER");
		// if (database.loaded) {
		if (folderId == null) {
			return dispatch({ type: ACTIONS.UPDATE_FOLDER, payload: { folder: ROOT_FOLDER } });
		}

		// const docSnap = await getDoc(doc(foldersRef, folderId));
		const docSnap = await getDoc(doc(companyCollectionNew(collections.FOLDERS, firebaseUser.companyUid), folderId));
		// const docSnap = await database.folders.find(folder => folder.id === folderId);
		// const docSnap = await folders.find(folder => folder.id === folderId);

		try {
			if (docSnap.exists()) {
				// console.log(formatDoc(docSnap));
				dispatch({ type: ACTIONS.UPDATE_FOLDER, payload: { folder: formatDoc(docSnap) } });
			}
			// dispatch({ type: ACTIONS.UPDATE_FOLDER, payload: { folder: docSnap } });
		} catch (e) {
			dispatch({ type: ACTIONS.UPDATE_FOLDER, payload: { folder: ROOT_FOLDER } });
		}
		// }
	}, [folderId /*database*/, loaded]);

	useEffect(() => {
		console.log("useFolder SET_FOLDERS");
		console.log("Logged:", firebaseUser.selectedCompany);
		// if (database.loaded)
		try {
			// const queryData = query(foldersRef, where("parentId", "==", folderId), where("companyUid", "==", firebaseUser.companyUid), orderBy("createdAt"));
			const queryData = query(
				companyCollectionNew(collections.FOLDERS, firebaseUser.selectedCompany || firebaseUser.companyUid),
				where("parentId", "==", folderId),
				where("companyUid", "==", firebaseUser.selectedCompany || firebaseUser.companyUid),
				where("permissions", "array-contains", firebaseUser.role),
				orderBy("createdAt"),
			);
			return onSnapshot(queryData, (snapshot) => {
				// console.log(snapshot.docs.map((doc) => formatDoc(doc)));
				dispatch({
					type: ACTIONS.SET_CHILD_FOLDERS,
					payload: { childFolders: snapshot.docs.map((doc) => formatDoc(doc)) },
				});
			});

			// const data = database.folders.filter(folder => folder.parentId === folderId && folder.companyUid === firebaseUser.companyUid);
			// // const data = folders.filter(folder => folder.parentId === folderId && folder.companyUid === firebaseUser.companyUid);
			// console.log("Folders:", data);
			// return dispatch({
			//     type: ACTIONS.SET_CHILD_FOLDERS,
			//     payload: { childFolders: [...data] },
			// });
		} catch (err) {
			console.error(err);
		}
	}, [folderId, firebaseUser /*database*/, loaded]);

	useEffect(() => {
		console.log("useFolder SET_FILES");
		// console.log(firebaseUser.companyUid);
		// if (database.loaded)

		try {
			// const queryData = query(filesRef, where("folderId", "==", folderId), where("companyUid", "==", firebaseUser.companyUid), orderBy("createdAt"));
			const queryData = query(
				companyCollectionNew(collections.FILES, firebaseUser.selectedCompany || firebaseUser.companyUid),
				where("folderId", "==", folderId),
				where("companyUid", "==", firebaseUser.selectedCompany || firebaseUser.companyUid),
				orderBy("createdAt"),
			);
			return onSnapshot(queryData, (snapshot) => {
				// console.log(snapshot.docs.map((doc) => formatDoc(doc)));
				dispatch({
					type: ACTIONS.SET_CHILD_FILES,
					payload: { childFiles: snapshot.docs.map((doc) => formatDoc(doc)) },
				});
			});

			// const data = database.files.filter(file => file.folderId === folderId && file.companyUid === firebaseUser.companyUid);
			// // const data = files.filter(file => file.folderId === folderId && file.companyUid === firebaseUser.companyUid);
			// console.log("Files:", data);
			// return dispatch({
			//     type: ACTIONS.SET_CHILD_FILES,
			//     payload: { childFiles: [...data] },
			// });
		} catch (err) {
			console.error(err);
		}
	}, [folderId, firebaseUser /*database*/, loaded]);

	// console.log("State:", state);
	return state;
};
