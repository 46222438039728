import React, { useState, useCallback } from "react";
import AddFolderButton from "./AddFolderButton";
import AddFileButton from "./AddFileButton";
import SearchBar from "../SearchBar/SearchBar";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowUp,
	faArrowDown,
	faPen,
	faList,
	faTableCellsLarge,
	faBorderAll,
	faArrowLeft, faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import "../../style/sass/Toolbar/Toolbar.css";
import bubbleSortObj from "../../utilities/pureFunctions/bubbleSort";
import { doc, query, where, updateDoc, getDocs, deleteDoc, collection, getDoc, setDoc } from "firebase/firestore";
import {
	collections,
	companyCollectionNew,
	firestore, foldersRef,
	formatDoc,
	getFirebaseDocs,
	quickMailRef,
	storage,
} from "../../firebase";
import { ROOT_FOLDER } from "../../hooks/useFolder";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteObject, ref } from "firebase/storage";

const Toolbar = ({ currentFolder, setFiles, files, setFolders, folders, setView }) => {
	const [dateAsc, setDateAsc] = useState(false);
	const [nameAsc, setNameAsc] = useState(false);
	const [typeAsc, setTypeAsc] = useState(false);
	const [showFolder, setShowFolder] = useState(false);
	const [showFile, setShowFile] = useState(false);

	const [active, setActive] = useState("");
	const [newFolderName, setNewFolderName] = useState("");
	const [error, setError] = useState("");


	const { firebaseUser } = useAuth();
	const navigate = useNavigate();

	// Handle sort function
	const handleSort = (property, direction) => {
		setActive(property);
		property === "createdAt" ? setDateAsc(prevState => !prevState) : setNameAsc(prevState => !prevState);

		const unorderedFolders = [...folders];
		const unorderedFiles = [...files];

		const orderedFolders = bubbleSortObj(unorderedFolders, property, direction);
		const orderedFiles = bubbleSortObj(unorderedFiles, property, direction);

		setFolders([...orderedFolders]);
		setFiles([...orderedFiles]);
	};

	// Sort by type
	const sortByType = () => {
		setTypeAsc(!typeAsc);
		setActive("type");
		const unorderedFiles = [...files];

		let tmp = unorderedFiles.map((file, index) => {
			const typeParts = file.name.split(".");
			const type = typeParts[typeParts.length - 1];
			return ({ index: index, type: type, file: unorderedFiles[index] });
		});

		tmp = bubbleSortObj(tmp, "type", typeAsc);

		const orderedFiles = [];
		for (const file of tmp) {
			orderedFiles.push(file.file);
		}

		setFiles([...orderedFiles]);
	};

	const handleFolderRename = async (event) => {
		event.preventDefault();
		const docRef = doc(firestore, "folders", currentFolder.id);
		try {
			await updateDoc(docRef, { name: newFolderName });
		} catch (err) {
			console.error(err);
		}

		setShowFolder(false);
	};

	const handleViewChange = (view) => {
		setView(view);
		localStorage.setItem("userPreferredView", JSON.stringify(view));
	};


	const searchFolder = async (folder) => {
		try {
			await getFirebaseDocs(companyCollectionNew(collections.FILES, firebaseUser.companyUid), "folderId", folder.id, async (file) => {
				// let fileRef = folder.name === "Root" ?
				//     `files/${firebaseUser.companyUid}/${folder.data().path.map((x) => x.id).join("/")}/${file.data().name}` :
				//     `files/${firebaseUser.companyUid}/${folder.data().path.map((x) => x.id).join("/")}/${folder.id}/${file.data().name}`;

				console.log(folder);

				let fileRef = `files/${firebaseUser.companyUid}/${folder.path.map((x) => x.id).join("/")}/`;
				fileRef += folder.name === "Root" ? `${file.data().name}` : `${folder.id}/${file.data().name}`;

				console.log(fileRef);
				await deleteObject(ref(storage, fileRef));
				await deleteDoc(doc(companyCollectionNew(collections.FILES, firebaseUser.companyUid), file.id));

			});
		} catch (err) {
			console.error(err);
			setError(err);
			return;
		}

		try {
			await getFirebaseDocs(companyCollectionNew(collections.FOLDERS, firebaseUser.companyUid), "parentId", folder.id, async (childFolder) => {
				console.log("Proba ce dela");
				console.log(childFolder);
				await searchFolder(formatDoc(childFolder));
			});
		} catch (err) {
			console.error(err);
			setError(err);
			return;
		}

		try {
			await deleteDoc(doc(companyCollectionNew(collections.FOLDERS, firebaseUser.companyUid), folder.id));
		} catch (err) {
			console.error(err);
			setError(err);
		}
	};

	const handleDeleteFolder = async (event) => {
		await searchFolder(currentFolder);
		const urlParts = window.location.href.split("/");
		urlParts.pop();
		window.location.replace(urlParts.join("/"));
	};

	const changeFolderPermissions = async (e) => {
		// const queryData = await query(collection(firestore, "folders", currentFolder.companyUid, "folders"), where("uid", "==", "F0k6k6ALZROSQArnAMgP51HtbyW2"));
		// const querySnapshot = await getDocs(queryData);

		const docRef = doc(collection(firestore, "folders", currentFolder.companyUid, "folders"), currentFolder.id);
		const docSnapshot = await getDoc(docRef);

		if (docSnapshot.exists()) {
			const newPermissions = e.target.value === "moderator-only" ?
				["admin", "moderator"] :
				["admin", "moderator", "client"];

			// console.log(docSnapshot.data());
			setDoc(doc(collection(firestore, "folders", currentFolder.companyUid, "folders"), currentFolder.id), {
					...docSnapshot.data(),
					permissions: newPermissions,
				},
			);
		}
	};

	return (currentFolder &&
		<>
			<div className=" px-4 pb-3 mb-3 d-flex flex-row justify-content-between w-100" id="toolbar-container">

				<div className="d-flex flex-column align-items-center ">
					<h6 style={{ display: "inline-block" }} className="text-black-50">Navigacija</h6>
					<div className="d-flex flex-row">
						<Button variant="outline-dark" className="me-2 text-center" onClick={() => navigate(-1)}>
							<FontAwesomeIcon icon={faArrowLeft} className="me-2 fs-5"/>
							Nazaj
						</Button>
						<Button variant="outline-dark" onClick={() => navigate(1)}>
							Naprej
							<FontAwesomeIcon icon={faArrowRight} className="ms-2 fs-5"/>
						</Button>
					</div>
				</div>

				{/*<div className="d-flex flex-column align-items-center ">*/}
				{/*    <h6 style={{ display: "inline-block" }} className="text-black-50">Iskalnik</h6>*/}
				{/*    <SearchBar stretchable={true} placeholder={"Trenutno ne deluje"}/>*/}
				{/*</div>*/}

				<div className="d-flex flex-column align-items-center">
					<h6 style={{ display: "inline-block" }} className="text-black-50">Sortiranje</h6>
					<div className="d-flex flex-row">
						<Button
							variant={active === "createdAt" ? "outline-primary" : "outline-dark"} className="me-2"
							onClick={() => handleSort("createdAt", dateAsc)}
						>
							Datum <FontAwesomeIcon icon={dateAsc ? faArrowDown : faArrowUp}/>
						</Button>
						<Button
							variant={active === "name" ? "outline-primary" : "outline-dark"} className="me-2"
							onClick={() => handleSort("name", nameAsc)}
						>
							Ime <FontAwesomeIcon icon={nameAsc ? faArrowDown : faArrowUp}/>
						</Button>
						<Button
							variant={active === "type" ? "outline-primary" : "outline-dark"}
							onClick={sortByType}
						>
							Tip datoteke <FontAwesomeIcon icon={typeAsc ? faArrowDown : faArrowUp}/>
						</Button>
					</div>
				</div>

				<div className="d-flex flex-column align-items-center">
					<h6 style={{ display: "inline-block" }} className="text-black-50">Pogled</h6>
					<div className="d-flex flex-row">
						<Button variant="outline-dark" className="me-2" onClick={() => handleViewChange("grid")}>
							<FontAwesomeIcon icon={faTableCellsLarge} className="fs-5"/>
						</Button>
						<Button variant="outline-dark" onClick={() => handleViewChange("list")}>
							<FontAwesomeIcon icon={faList} className="fs-5"/>
						</Button>
					</div>
				</div>

				{
					// const isSelected = currentFolder.permissions.includes(firebaseUser.role)
					firebaseUser?.role !== "client" &&
					currentFolder?.name !== "Root" &&
					<div>
						<h6>Pravice dostopa</h6>
						<Form.Select onChange={changeFolderPermissions}>
							<option selected disabled>
								{currentFolder.permissions.includes("client") ? "Vidno vsem" : "Omejen dostop"}
							</option>
							<option value="moderator-only">Omejen dostop</option>
							<option value="all">Vidno vsem</option>
						</Form.Select>
					</div>
				}

				{firebaseUser?.permission.canCreate &&
					<div className="d-flex flex-column align-items-center">
						<h6 style={{ display: "inline-block" }} className="text-black-50">Dodajanje</h6>
						<div className="d-flex flex-row">
							{(currentFolder.id !== null && firebaseUser.role === "admin") &&
								<>
									<Button variant="outline-danger" onClick={handleDeleteFolder} className="me-2">
										<FontAwesomeIcon icon={faPen} className="me-2 fs-5"/>
										Izbriši mapo
									</Button>
									<Button variant="outline-success" onClick={() => setShowFolder(true)} className="me-2">
										<FontAwesomeIcon icon={faPen} className="me-2 fs-5"/>
										Preimenuj mapo
									</Button>
								</>
							}
							<AddFileButton currentFolder={currentFolder}/>
							{firebaseUser.role === "admin" && <AddFolderButton currentFolder={currentFolder}/>}
						</div>
					</div>}
			</div>

			{currentFolder.id !== null &&
				<>
					{/*<Modal show={showFolder} onHide={() => setShowFolder(false)}>*/}
					{/*    <Form onSubmit={handleFolderRename}>*/}
					{/*        <Modal.Body>*/}
					{/*            <Form.Group>*/}
					{/*                <Form.Label>Novo ime mape</Form.Label>*/}
					{/*                <Form.Control type="text" required value={newFolderName}*/}
					{/*                              onChange={(e) => setNewFolderName(e.target.value)}/>*/}
					{/*            </Form.Group>*/}
					{/*        </Modal.Body>*/}
					{/*        <Modal.Footer>*/}
					{/*            <Button variant="secondary" onClick={() => setShowFolder(false)}>*/}
					{/*                Zapri*/}
					{/*            </Button>*/}
					{/*            <Button variant="success" type="submit">*/}
					{/*                Preimenuj mapo*/}
					{/*            </Button>*/}
					{/*        </Modal.Footer>*/}
					{/*    </Form>*/}
					{/*</Modal>*/}

					{/*<Modal show={showFile} onHide={() => setShowFile(false)}>*/}
					{/*    <Modal.Body></Modal.Body>*/}
					{/*    <Modal.Footer>*/}
					{/*        <Button variant="secondary" onClick={() => setShowFolder(false)}>*/}
					{/*            Zapri*/}
					{/*        </Button>*/}
					{/*        <Button variant="success" type="submit">*/}
					{/*            Dodaj datoteko*/}
					{/*        </Button>*/}
					{/*    </Modal.Footer>*/}
					{/*</Modal>*/}
				</>
			}

			<Modal show={error.length > 0} onHide={() => {
				setError("");
			}}>
				<Modal.Header closeButton>
					<h4>Napaka pri brisanju mape</h4>
				</Modal.Header>
				<Modal.Body>
					{error}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Toolbar;
