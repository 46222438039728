import React, { useCallback, useContext, useState } from "react";
import { useStore } from "./StoreFilesContext";

const MenuContext = React.createContext();

export const useMenu = () => {
    return useContext(MenuContext);
};

const MenuProvider = ({ children }) => {
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [show, setShow] = useState(false);
    const [animate, setAnimate] = useState(false);
    const [type, setType] = useState("");
    const [element, setElement] = useState(null);

    const types = {
        FOLDER: "folder",
        FILE: "file",
    };

    const handleContextMenu = useCallback((event, element, type) => {
        event.preventDefault();
        setType(type);
        setElement(element);
        setAnchorPoint({ x: event.pageX - 10, y: event.pageY - 10 });
        setShow(true);
    }, [setAnchorPoint, setShow]);

    const handleExit = useCallback(() => {
        setAnimate(true);
        setTimeout(() => {
            setAnimate(false);
            setShow(false);
            setElement(null);
            setType("");
        }, 300);
    }, [setAnchorPoint, setShow]);

    const value = {
        show,
        animate,
        anchorPoint,
        handleContextMenu,
        handleExit,
        types,
        type,
        element,
    };

    return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export default MenuProvider;
