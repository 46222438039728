import React from "react";
import { Outlet } from "react-router-dom";
import { SearchFilesProvider } from "../../contexts/SearchFilesContext";

const SearchFilesOutlet = () => {
    return (
        <SearchFilesProvider>
            <Outlet/>
        </SearchFilesProvider>
    );
};

export default SearchFilesOutlet;
