import React from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useSearch } from "../../contexts/SearchFilesContext";

import "../../style/sass/SearchBar/SearchBar.css";

const SearchBar = ({ stretchable, placeholder }) => {
    const { setQueryText } = useSearch();
    return (
        <div className="" id="searchbar-container">
            <Form.Group>
                <div className="icon-container">
                    <FontAwesomeIcon icon={faMagnifyingGlass}/>
                </div>
                <Form.Control type="text" onChange={(e) => setQueryText(e.target.value.toLowerCase())}
                              placeholder={placeholder} className={stretchable ? "stretchable" : ""}/>
            </Form.Group>
        </div>
    );
};

export default SearchBar;
