import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useMenu } from "../../contexts/MenuContext";
import { types } from "../ContextMenu/FolderMenu";

import "../../style/sass/FileFolder/Folder.css";

export default function Folder({ folder }) {
	const { handleContextMenu, types } = useMenu();
	const folderButton = useRef();

	let folderType;
	switch (folder.type) {
		case "reviewed":
			folderType = "Pregledano";
			break;
		case "sent":
			folderType = "Poslano";
			break;
		case "finished":
			folderType = "Rešeno";
			break;
	}

	return (
		<>
			<Link to={{ pathname: `/folder/${folder.id}`, state: { folder: folder } }} className="text-truncate folder"
						ref={folderButton} onContextMenu={(event) => handleContextMenu(event, folder, types.FOLDER)}>
				{/*<img src="/img/folder.png"/>*/}
				<img src={"/img/folders/128/" + folder.type + "Folder.png"}/>
				{folder.type !== "default" &&
					<div className="img-text text-light fw-bold">{folderType}</div>}
				{!folder.permissions.includes("client") &&
					<img className="locked-folders" src="/img/assets/padlock.png" alt="lock image"/>}
				{folder.name}
			</Link>
		</>
	);
}
