import React from "react";
import { Table } from "react-bootstrap";
import ListFile from "./ListFile";
import ListFolder from "./ListFolder";

const ListView = ({ folder, childFolders = null, childFiles = null }) => {

    return (
        <>
            {childFolders.length > 0 && childFolders.map((folder, index) => {
                return <div key={`folder-${index}`}>
                    <ListFolder folder={folder} index={index}/>
                </div>;
            })}
            {childFolders.length > 0 && childFiles.length > 0 && <hr/>}
            {/*<div className="file-row px-4 py-1 w-100 d-flex justify-content-start align-items-center mb-2 pe-none">*/}
            {/*    <div style={{ flexShrink: "0", width: "32px", height: "1px", marginRight: "1rem" }}/>*/}
            {/*    <h5 style={{ width: "100%", maxWidth: "50%" }}>Ime</h5>*/}
            {/*    <h5 style={{ width: "100%", maxWidth: "15%", textAlign: "center" }}>Avtor</h5>*/}
            {/*    <h5 style={{ width: "100%", maxWidth: "15%", textAlign: "center" }}>Datum objave</h5>*/}
            {/*    <h5 style={{ width: "100%", maxWidth: "15%", textAlign: "center" }}>Nazadnje spremenjeno</h5>*/}
            {/*    <h5>Funkcije</h5>*/}
            {/*</div>*/}
            {childFiles.length > 0 && childFiles.map((file, index) => {
                return <div key={`file-${index}`}>
                    <ListFile file={file} folder={folder} index={index}/>
                </div>;
            })}
        </>
    );
};

export default ListView;
