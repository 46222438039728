import React, { useCallback, useRef } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faXmark, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';

import '../../style/sass/Drive/AddFileModal/AddFileModal.css';

// onMouseEnter={showButton}
// onMouseLeave={hideButton}

const AddFileModal = ({ show, onHide, handleUpload, filesToUpload, setFilesToUpload }) => {
	const dropzoneRef = useRef();
	const buttonRef = useRef();

	const addDragClass = () => dropzoneRef.current.classList.add('dragover');
	const removeDragClass = () => dropzoneRef.current.classList.remove('dragover');

	const showButton = () => buttonRef.current.classList.add('show');
	const hideButton = () => buttonRef.current.classList.remove('show');

	const handleSelectFile = (acceptedFiles = []) => {
		// setFilesToUpload([...event.target.files]);

		const targetFiles = [...acceptedFiles];
		for (const targetFile of targetFiles) {
			// O(n^2) => BAD
			for (const fileToUpload of filesToUpload) {
				if (fileToUpload.name === targetFile.name) {
					const tmpFile = targetFile;
					handleDelete(targetFile.name);
					return setFilesToUpload((prevFiles) => [...prevFiles, tmpFile]);
				}
			}
		}

		setFilesToUpload((prevFiles) => [...prevFiles, ...targetFiles]);
	};

	const handleDelete = (fileName) => {
		setFilesToUpload(
			filesToUpload.filter((file) => {
				return file.name !== fileName;
			})
		);
	};

	const formatSize = (size) => {
		if (size / 1000 < 1) return { value: size + ' B', color: 'green' };
		else if (size / 1000000 < 1) return { value: Math.round(size / 1000) + ' kB', color: 'green' };
		else if (size / 1000000000 < 1) return { value: Math.round(size / 1000000) + ' MB', color: 'yellow' };
		else return { value: Math.round(size / 1000000000) + ' GB', color: 'red' };
	};

	const onDrop = useCallback((acceptedFiles) => {
		return handleSelectFile(acceptedFiles);
	}, []);

	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	return (
		<Modal show={show} onHide={onHide} id='add-file-modal'>
			<Modal.Header>
				<h3>Dodajanje datotek</h3>
			</Modal.Header>
			{/* <Form onSubmit={handleUpload}> */}
			<Form onSubmit={(event) => handleUpload(event, filesToUpload)}>
				<Modal.Body>
					<div className='d-flex'>
						<div
							style={
								filesToUpload.length > 0
									? { width: '65%', marginRight: '.5rem' }
									: {
											width: '100%',
											marginRight: '0',
									  }
							}
							{...getRootProps({
								className: 'dropzone d-flex flex-column justify-content-center align-items-center text-success',
								onDragEnter: addDragClass,
								onDragLeave: removeDragClass,
								onDrop: removeDragClass,
								onMouseEnter: addDragClass,
								onMouseLeave: removeDragClass,
							})}
						>
							<div ref={dropzoneRef}>
								<FontAwesomeIcon icon={faCloudArrowUp} alt='Cloud arrow down icon' className='fs-1' />
							</div>
							<h5>Za dodajanje datotek kliknite na polje ali pa povlecite datoteke vanj</h5>
							<input {...getInputProps()} />
						</div>

						{filesToUpload.length > 0 ? (
							<div className=' ms-2 mt-3' style={{ width: '35%' }}>
								<Table hover>
									<thead>
										<tr>
											<th>Ime</th>
											<th>Velikost</th>
										</tr>
									</thead>
									<tbody>
										{filesToUpload.map((file, index) => {
											file.formatedSize = formatSize(file.size);

											return (
												<tr key={index} style={{ verticalAlign: 'middle' }}>
													<td>{file.name}</td>
													<td style={{ color: file.formatedSize.color }}>{file.formatedSize.value}</td>
													<td>
														<Button variant='outline-danger' ref={buttonRef} className='delete-file' onClick={() => handleDelete(file.name)}>
															<FontAwesomeIcon icon={faXmark} alt='X icon' />
														</Button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</Table>
							</div>
						) : null}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={onHide}>
						Zapri
					</Button>
					<Button variant='success' type='submit' disabled={filesToUpload.length === 0}>
						Dodaj datoteko
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default AddFileModal;
