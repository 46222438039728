import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Table } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { addDoc, setDoc, arrayUnion, doc, getDocs, arrayRemove, updateDoc } from "firebase/firestore";
import {
	companiesRef,
	usersRef,
	collections,
	deleteFirebaseDoc,
	updateFirebaseDoc, firestore,
} from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faXmark } from "@fortawesome/free-solid-svg-icons";
import UsersModal from "./UsersModal";

const UsersTab = ({ users, companies }) => {
	// New user references
	const nameRef = useRef();
	const lastNameRef = useRef();
	const emailRef = useRef();
	const companyRef = useRef();
	const roleRef = useRef();

	// New company references
	const companyNameRef = useRef();

	// Signup form
	const { signup } = useAuth();

	// Error / loading states
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);

	const [selectedCompany, setSelectedCompany] = useState("");
	const [activeUser, setActiveUser] = useState({});

	// Permission states
	// MOVE OUT OF STATES INTO ORDINARY VARIABLE
	const [viewPermission, setViewPermission] = useState(true);
	const [createPermission, setCreatePermission] = useState(false);
	const [deletePermission, setDeletePermission] = useState(false);
	const [newUserRole, setNewUserRole] = useState("client");


	async function handleNewUser(e) {
		e.preventDefault();
		console.log("New user is being created");

		try {
			setError("");
			// setLoading(true);

			// Test URL
			// const response = await fetch("http://localhost:5001/jokra-doo/us-central1/app/newPassword", {
			//     method: "POST",
			//     headers: {
			//         "Content-Type": "application/json",
			//     },
			// });

			const response = await fetch("https://us-central1-jokra-doo.cloudfunctions.net/app/newPassword", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			});
			const generatedPassword = await response.json();

			// Add to Firebase authentication database
			await signup(emailRef.current.value, generatedPassword).then(async (userCredential) => {
				// Add to Firestore database
				const newUser = setDoc(doc(firestore, "users", userCredential.user.uid), {
					uid: userCredential.user.uid,
					name: nameRef.current.value,
					lastName: lastNameRef.current.value,
					permission: {
						canView: viewPermission,
						canCreate: createPermission,
						canDelete: deletePermission,
					},
					role: newUserRole,
					companyUid: companyRef.current.value,
				});

				// Send mail to new user with theirs credentials
				const options = {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						email: emailRef.current.value,
						password: generatedPassword,
						target: emailRef.current.value,
						subject: "Nov profil za aplikacijo",
						type: "newUser",
					}),
				};

				// Test URL
				// await fetch("http://localhost:5001/jokra-doo/us-central1/app/quickMail", options);

				console.log("Current target:", emailRef.current.value);
				await fetch("https://us-central1-jokra-doo.cloudfunctions.net/app/quickMail", options);

				// Add user to company's employees
				const companyDoc = doc(companiesRef, companyRef.current.value);
				await updateDoc(companyDoc, {
					employees: arrayUnion(userCredential.user.uid),
				});
			});
		} catch (err) {
			setError("Napaka pri ustvarjanju uporabnika");
			console.error(err);
		}
	}

	const handleClick = (user) => {
		setActiveUser({ id: user.id, ...user.data() });
		setShow(true);
	};

	const handleDelete = async (user) => {
		setError("");

		try {
			// Delete from Company employees array
			await updateFirebaseDoc(collections.COMPANIES, user.companyUid, { employees: arrayRemove(user.uid) });

			// Delete from Firestore
			await deleteFirebaseDoc(collections.USERS, user.id);

			// Delete from Authentication
			const options = {
				method: "DELETE",
				Header: { "Content-Type": "application/json" },
				body: JSON.stringify({ userUid: user.uid }),
			};

			// const response = await fetch(process.env.REACT_APP_SERVER_URL + "admin//deleteUser", options);
			const response = await fetch("https://us-central1-jokra-doo.cloudfunctions.net/app/admin/deleteUser", options);

			if (response.statusCode === 200)
				setSuccess(`Uporabnik ${user.data().name} ${user.data().lastName} uspešno odstranjen.`);

		} catch (err) {
			setError(err);
			console.error(err);
		}

		setSuccess("Uspešno ste izbrisali uporabnika");

	};

	return (
		<>
			<div className="d-flex main-container h-100">
				<div className="formContainer">
					{error && <Alert variant="danger" style={{ borderRadius: "20px" }}>{error}</Alert>}
					{success && <Alert variant="success" style={{ borderRadius: "20px" }}>{success}</Alert>}
					<Form onSubmit={handleNewUser}>
						{/* Name Form */}
						<Form.Group id="nameForm" className="mb-3">
							<Form.Label>Ime</Form.Label>
							<Form.Control type="text" ref={nameRef} required/>
						</Form.Group>
						{/* LastName Form */}
						<Form.Group id="lastNameForm" className="mb-3">
							<Form.Label>Priimek</Form.Label>
							<Form.Control type="text" ref={lastNameRef} required/>
						</Form.Group>
						{/* Email Form */}
						<Form.Group id="emailForm" className="mb-3">
							<Form.Label>Email</Form.Label>
							<Form.Control type="email" ref={emailRef} required/>
						</Form.Group>
						{/* Company Form */}
						<Form.Group id="companyForm" className="mb-3">
							<Form.Label>Podjetje</Form.Label>
							<Form.Select
								aria-label="Default select example"
								ref={companyRef}
								className="mb-3"
								value={selectedCompany}
								onChange={(e) => setSelectedCompany(e.target.value)}
							>
								{companies &&
									companies.map((company, index) => {
										return (
											<option key={uuid()} value={company.id}>
												{company.data().name}
											</option>
										);
									})}
							</Form.Select>
						</Form.Group>
						{/* Role Form */}
						<Form.Label>Tip uporabnika</Form.Label>
						<Form.Select
							aria-label="Default select example"
							ref={roleRef}
							id="roleSelect"
							className="mb-3"
							onChange={(e) => setNewUserRole(e.target.value)}
						>
							<option value="client">Stranka</option>
							<option value="moderator">Moderator</option>
							<option value="admin">Admin</option>
						</Form.Select>
						{/* Permission From */}
						<Form.Group id="permissionSelection" className="mb-3">
							<div className="d-inline-flex justify-content-start align-items-center me-2">
								<h5>Ogled datotek</h5>
								<input type="checkbox" name="canView" id="canView" checked={viewPermission}
											 onChange={(e) => setViewPermission(e.target.checked)}/>
								<Form.Label htmlFor="canView"></Form.Label>
							</div>
							<div className="d-inline-flex justify-content-start align-items-center me-2">
								<h5>Ustvarjanje datotek</h5>
								<input type="checkbox" name="canCreate" id="canCreate"
											 checked={createPermission}
											 onChange={(e) => setCreatePermission(e.target.checked)}/>
								<Form.Label htmlFor="canCreate"></Form.Label>
							</div>
							<div className="d-inline-flex justify-content-start align-items-center">
								<h5>Brisanje datotek</h5>
								<input type="checkbox" name="canDelete" id="canDelete"
											 checked={deletePermission}
											 onChange={(e) => setDeletePermission(e.target.checked)}/>
								<Form.Label htmlFor="canDelete"></Form.Label>
							</div>
						</Form.Group>
						<Button className="btn-primary" type="submit">Dodaj uporabnika</Button>
					</Form>
				</div>

				<div className="table-container">
					<Table striped hover>
						<thead>
						<tr className="text-center">
							<th>Št.</th>
							<th>Ime</th>
							<th>Priimek</th>
							<th>Podjetje</th>
							<th>Tip</th>
							<th>Branje</th>
							<th>Pisanje</th>
							<th>Brisanje</th>
							<th>Uredi</th>
							<th>Izbriši</th>
						</tr>
						</thead>
						<tbody>
						{users.map((user, index) => {
							const companyName = companies.filter((company) => {
								if (company.id === user.data().companyUid)
									return company;
							});
							user.companyName = companyName[0].data().name;

							return (
								<tr key={index} className="text-center">
									<td>{index + 1}</td>
									<td>{user.data().name}</td>
									<td>{user.data().lastName}</td>
									{/*<td>{companyName[0].data().name}</td>*/}
									<td>{user.companyName}</td>
									<td>{user.data().role}</td>
									<td>{user.data().permission.canView ? "DA" : "NE"}</td>
									<td>{user.data().permission.canCreate ? "DA" : "NE"}</td>
									<td>{user.data().permission.canDelete ? "DA" : "NE"}</td>
									<td>
										<Button variant="outline-dark" onClick={() => handleClick(user)}>
											{<FontAwesomeIcon icon={faPenToSquare}/>}
										</Button>
									</td>
									<td>
										<Button variant="outline-danger" onClick={() => handleDelete(user)}
														disabled={user.data().role === "admin"}>
											{<FontAwesomeIcon icon={faXmark}/>}
										</Button>
									</td>
								</tr>
							);
						})}
						</tbody>
					</Table>

				</div>
			</div>

			{"id" in activeUser &&
				<UsersModal show={show} onHide={() => setShow(false)} user={activeUser} companies={companies}/>}
		</>
	);
};

export default UsersTab;
