import React from "react";
import { Outlet } from "react-router-dom";
import Login from "./Authentication/Login";
import ForgotPassword from "./Authentication/ForgotPassword";
import UpdateProfile from "./Authentication/UpdateProfile";
import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./Authentication/ProtectedRoute";
import ProtectedRouteAdmin from "./AdminDashboard/ProtectedRouteAdmin";
import Dashboard from "./Drive/Dashboard";
import Help from "./Help/Help";
import AdminDashboard from "./AdminDashboard/AdminDashboard";
import LandingPage from "./LandingPage/LandingPage";
import Loader from "./Loader";
import QuickMail from "./QuickMail/QuickMail";
import NotFound from "./NotFound/NotFound";

function App() {
    return (
        // <Router>
        // <AuthProvider>
        //     <Routes>
        //         <Route exact path="/" element={<LandingPage/>}/>
        //         <Route path="/loader" element={<Loader/>}/>
        //     </Routes>
        //     <Routes>
        //         <Route element={<ProtectedRoute/>}>
        //             <Route exact path="/folder" element={<Dashboard/>}/>
        //             <Route exact path="/folder/:folderId" element={<Dashboard/>}/>
        //             <Route path="/help" element={<Help/>}/>
        //             <Route path="/settings" element={<Settings/>}/>
        //             <Route path="/quickMail" element={<QuickMail/>}/>
        //         </Route>
        //     </Routes>
        //     <Routes>
        //         <Route element={<ProtectedRouteAdmin/>}>
        //             <Route path="/admin-dashboard" element={<AdminDashboard/>}/>
        //         </Route>
        //
        //         <Route element={<ProtectedRoute/>}>
        //             <Route path="/user" element={<UpdateProfile/>}/>
        //         </Route>
        //
        //         <Route path="/login" element={<Login/>}/>
        //         <Route path="/forgot-password" element={<ForgotPassword/>}/>
        //         {/*<Route path="/*" element={<NotFound/>}/>*/}
        //     </Routes>
        <AuthProvider>
            {/* Add SearchFilesContext */}
            <Outlet/>
        </AuthProvider>
        // </Router>
    );
}

export default App;
