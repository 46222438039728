import React from 'react';
import { Modal } from 'react-bootstrap';
import parser from 'html-react-parser';

const QuickMailPreviewModal = ({ show, hide, content }) => {
	return (
		<Modal show={show} onHide={hide}>
			<Modal.Header closeButton>
				<h3>Predogled e-pošte</h3>
			</Modal.Header>
			<Modal.Body>
				<h5 className='text-dark'>
					Email: <b>{content.sender.email}</b>
				</h5>
				<h5 className='text-dark'>
					Ime: <b>{content.sender.name}</b>
				</h5>
				<h5 className='text-dark'>
					Podjetje: <b>{content.sender.company}</b>
				</h5>

				{content.subject}

				<br />
				{parser(content.body)}
				<br />
				<p>Ta email je računalniško generiran.</p>
				<br />
			</Modal.Body>
		</Modal>
	);
};

export default QuickMailPreviewModal;
