import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "../style/sass/LandingPage/LandingPage.css";
import Header from "../components/LandingPage/Header";
import Hero from "../components/LandingPage/Hero";
import ScrollSection from "../components/LandingPage/ScrollSection";
import MiddleSection from "../components/LandingPage/MiddleSection";
import ContactSection from "../components/LandingPage/ContactSection";
import JokraCloudSection from "../components/LandingPage/JokraCloudSection";
import Footer from "../components/LandingPage/Footer";

const LandingPage = () => {
	gsap.registerPlugin(ScrollTrigger);

	return (
		<main id="landing-page-container">
			<Header/>
			<Hero/>
			<ScrollSection/>
			<MiddleSection/>
			<JokraCloudSection/>
			<ContactSection/>
			<Footer/>
		</main>
	);
};

export default LandingPage;
