import React, { useState, useRef, useEffect } from "react";
// import "./Header.scss";
import { Link } from "react-router-dom";
import { gsap } from "gsap";

const Header = () => {
	return (
		<footer>
			<div>
				<h3>Zavarovalno posredovanje in svetovanje</h3>
				<h5>Jožef Kranjec s.p.</h5>
			</div>
			<div>
				<ul>
					<li><a href="#scroll-section-anchor">O podjetju</a></li>
					<li><a href="#jokra-cloud-section-anchor">Jokra Cloud</a></li>
					<li><a href="#contact-section-anchor">Kontakt</a></li>
				</ul>
			</div>
			<span/>
			<div>
				<p>
					Vse ilustracije in slike so delo oblikovalca
					<a href="https://iconscout.com/contributors/manypixels-gallery" target="_blank" rel="noreferrer">
						Manypixels Gallery</a>
					iz spletne apliakcije
					<a href="https://iconscout.com" target="_blank" rel="noreferrer">IconScout</a>.
					Ilustracije so uporabljene glede na licenco Creative Commons 4 Attribution.
				</p>
			</div>
		</footer>
	);
};

export default Header;
