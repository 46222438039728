import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { onSnapshot, orderBy, query, where } from "firebase/firestore";
import { collections, companyCollectionNew, formatDoc, getFirebaseCollection } from "../firebase";

import "../style/sass/CompanySelection/CompanySelection.css";
import { Button } from "react-bootstrap";

const CompanySelect = () => {
	const [companies, setCompanies] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const { firebaseUser, setFirebaseUser } = useAuth();

	const navigate = useNavigate();

	useEffect(() => {
		getCompanies();
	}, []);

	async function getCompanies() {
		const companiesQuery = await getFirebaseCollection(collections.COMPANIES);
		setCompanies(companiesQuery.docs.map(doc => formatDoc(doc)));
		setLoaded(true);
	}

	async function handleCompanySelection(e) {
		e.stopPropagation();

		// Set selected company to the firebaseUser (admin)
		await setFirebaseUser(prev => {
			return { ...prev, selectedCompany: e.currentTarget.getAttribute("data-name") };
		});

		// Navigate to "/folder
		navigate("/folder");
	}

	function handleImgError(e) {

		e.target.style.display = "none";
		e.target.nextSibling.style.display = "flex";
	}

	return (
		<ul className="company-selection-container mt-4">
			{loaded && companies.map(company => (
				<button key={company.uid} data-name={company.id} onClick={handleCompanySelection}
								className="company-card file">
					<img src={`/img/companies/${company.id}.png`} alt="" className="mb-2" onError={handleImgError}/>
					<div className="mb-2">
						<p>No image found</p>
					</div>
					<p>{company.name}</p>
				</button>
			))}
		</ul>
	);
};

export default CompanySelect;
