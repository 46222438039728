import React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../contexts/StoreFilesContext";

const FileMenu = ({ file }) => {
    const { storedFiles, storeFile, removeFile } = useStore();

    const navigate = useNavigate();

    const isSelected = () => {
        let match = false;
        storedFiles.forEach((storedFile) => {
                if (storedFile.name === file.name) match = true;
            },
        );
        return match;
    };

    const handleSelectFile = () => {
        storeFile(file);
    };

    const handleDeselectFile = () => {
        removeFile(file);
    };

    const handleQuickMail = () => {
        navigate("../quickMail");
    };

    return (
        <>
            <p className="text-black-50 mb-2">Quick Mail</p>
            {isSelected() ?
                <li onClick={handleDeselectFile}>Odstrani datoteko</li> :
                <li onClick={handleSelectFile}>Dodaj datoteko</li>
            }
            {storedFiles.length > 0 && <li onClick={handleQuickMail}>Pošlji z QuickMail</li>}
            {storedFiles.length > 0 &&
                <p className="text-black-50 mb-2">Število datotek:&nbsp;{storedFiles.length}</p>}
        </>
    );
};

export default FileMenu;
