import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { doc, deleteDoc, getDocs, query, where } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import {
    storage,
    filesRef,
    usersRef,
    getFirebaseDoc,
    collections,
    testRef2,
    companyCollectionNew,
} from "../../firebase";
import { Button, Modal } from "react-bootstrap";
import { useStore } from "../../contexts/StoreFilesContext";
import { useMenu } from "../../contexts/MenuContext";
import getFileType from "../../utilities/pureFunctions/getFileType";
import { useAuth } from "../../contexts/AuthContext";

import "../../style/sass/FileFolder/File.css";
import getDateFromTimestamp from "../../utilities/pureFunctions/getDateFromTimestamp";

export default function File({ file, folder }) {
    const [show, setShow] = useState(false);
    const [error, setError] = useState("");

    const { storedFiles } = useStore();
    const { handleContextMenu, types } = useMenu();
    const { firebaseUser } = useAuth();

    const fileType = getFileType(file.name);

    // useEffect to get files author
    const getAuthor = async () => {
        const queryData = await query(usersRef, where("uid", "==", file?.userId));
        const user = await getDocs(queryData);
        file.author = user.docs[0].data().name + " " + user.docs[0].data().lastName;

        // const snapshot = await getFirebaseDoc(collections.USERS, file?.userId);
        // file.author = snapshot.data().name + " " + snapshot.data().lastName;
        // console.log(file.userId);
    };

    useEffect(() => {
        if (!file.author)
            getAuthor().catch((err) => {
                console.error(err);
            });
    }, []);

    const handleDelete = async (e) => {
        setError("");
        try {
            const fileRef =
                folder.name === "Root"
                    ? `files/${file.userId}/${folder.path.map((x) => x.name).join("/")}/${file.name}`
                    : `files/${file.userId}/${folder.path.map((x) => x.name).join("/")}/${folder.name}/${file.name}`;

            console.log(ref(storage, fileRef));

            // const fileRef = folder.name === "Root" ?
            //     `files/${firebaseUser.companyUid}/${folder.path.map((x) => x.id).join("/")}/${file.name}` :
            //     `files/${firebaseUser.companyUid}/${folder.path.map((x) => x.id).join("/")}/${folder.id}/${file.name}`;

            await deleteObject(ref(storage, fileRef));
            // // Delete doc from Firestore
            await deleteDoc(doc(companyCollectionNew(collections.FILES, firebaseUser.companyUid), file.id));
        } catch (err) {
            console.error("Unable to delete file: ", err);
            setError(err.message);
        }
    };

    // File info
    file.creationDate = getDateFromTimestamp(file.createdAt);
    file.lastChangeDate = getDateFromTimestamp(file.lastChange);

    const isSelected = () => {
        for (const storedFile of storedFiles) {
            if (storedFile.name === file.name) return true;
        }
        return false;
    };

    return (
        <>
            <button
                className="text-truncate file"
                id={isSelected() ? "selected" : ""}
                onClick={() => setShow(true)}
                onContextMenu={(event) => handleContextMenu(event, file, types.FILE)}
            >
                <img src={`/img/files/64/${fileType}.png`} className="mb-2"/>
                <p>{file.name}</p>
            </button>

            <Modal
                centered
                show={show}
                onHide={() => {
                    setShow(false);
                }}
                id="file-modal"
            >
                <Modal.Header closeButton style={{ border: "none" }}/>
                <Modal.Body
                    className="file-modal-body d-flex justify-content-between align-items-center px-4 file-modal">
                    <div className="d-flex flex-column justify-content-center align-items-center pe-5">
                        <img src={`/img/files/128/${fileType}.png`} className="mb-4"/>
                    </div>
                    <div>
                        {/*<h5>Ime datoteke:</h5><h4 className="fw-bold mb-4">{file?.name}</h4>*/}
                        <h5>Ime datoteke:</h5>
                        <h4 className="fw-bold mb-4">{file?.name.split(".")[0]}</h4>
                        <h6>Avtor:</h6>
                        <h5 className="fw-bold mb-4">{file.author}</h5>
                        <h6>Datum ustvaritve:</h6>
                        <h5 className="fw-bold mb-4">{file.creationDate}</h5>
                        {file.lastChange && (
                            <>
                                <h6>Zadnja sprememba:</h6>
                                <h5 className="fw-bold mb-4">{file.lastChangeDate}</h5>
                            </>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between file-modal">
                    <Button variant="outline-danger" onClick={handleDelete}>
                        Izbriši datoteko
                        <FontAwesomeIcon className="ms-2 fs-5" icon={faTrashCan}/>
                    </Button>
                    <Button variant="outline-success">
                        <a href={file.url} target="_blank">
                            Prenesi datoteko <FontAwesomeIcon className="ms-1 fs-5" icon={faCloudArrowDown}/>
                        </a>
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={error.length > 0}
                onHide={() => {
                    setError("");
                }}
            >
                <Modal.Header closeButton>
                    <h4>Napaka pri brisanju datoteke</h4>
                </Modal.Header>
                <Modal.Body>{error}</Modal.Body>
            </Modal>
        </>
    );
}
