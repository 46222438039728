import React, { useState, useContext, useEffect } from 'react';

const StoreFilesContext = React.createContext();

export const useStore = () => {
	return useContext(StoreFilesContext);
};

const StoreFilesProvider = ({ children }) => {
	const [storedFiles, setStoredFiles] = useState([]);

	const storeFile = (file) => setStoredFiles((prevState) => [...prevState, file]);
	const removeFile = (removeFile) => setStoredFiles((prevState) => prevState.filter((file) => file.name !== removeFile.name));

	// useEffect(() => {
	//     console.log(storedFiles);
	// }, [storedFiles]);

	const exports = {
		storedFiles,
		storeFile,
		removeFile,
	};

	return <StoreFilesContext.Provider value={exports}>{children}</StoreFilesContext.Provider>;
};

export default StoreFilesProvider;
